import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "state";

export interface Parameter {
  id: string;
  name: string;
  expression: string;
  value: string;
  description: string;
}

interface Parameters {
  parameters: Array<Parameter>;
  parameterSweepCases: Array<any>;
  parameterSweepParams: Array<any>;
}

const initialState: Parameters = {
  parameters: [],
  parameterSweepCases: [],
  parameterSweepParams: [],
};

const parametersSlice = createSlice({
  name: "parameters",
  initialState,
  reducers: {
    updateParameters(state, action) {
      state.parameters = action.payload;
    },
    addParameter(state, action) {
      state.parameters.push(action.payload);
    },
    editParameter(state, action) {
      let param = state.parameters.findIndex(
        (param) => param.id === action.payload.id
      );

      state.parameters[param] = {
        ...state.parameters[param],
        name: action.payload.name,
        expression: action.payload.expression,
        value: action.payload.value,
        description: action.payload.description,
      };
    },
    deleteParameter(state, action) {
      for (let i = 0; i < state.parameters.length; i++) {
        if (state.parameters[i].id === action.payload) {
          state.parameters.splice(i, 1);
          return;
        }
      }
    },
    updateParameterSweepCases(state, action) {
      state.parameterSweepCases = [];
      Object.assign(state.parameterSweepCases, action.payload);
    },
    updateParameterSweepParams(state, action) {
      state.parameterSweepParams = [];
      Object.assign(state.parameterSweepParams, action.payload);
    },
  },
});
export const selectParameters = (state: RootState) =>
  state.parameters.parameters;

export const selectParameterSweep = (state: RootState) =>
  state.parameters.parameters;

export const {
  addParameter,
  editParameter,
  deleteParameter,
  updateParameters,
  updateParameterSweepCases,
  updateParameterSweepParams,
} = parametersSlice.actions;
export default parametersSlice.reducer;
