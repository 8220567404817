import * as BABYLON from "babylonjs";
import { GizmoManager } from "babylonjs";
import DraggableModal from "components/DraggableModal";
import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { selectUsername } from "state/reducers/authSlice";
import { setPopupOpen } from "state/reducers/generalSlice";
import { addHistory } from "state/reducers/historySlice";
import {
  Model,
  modelAdded,
  modelAltered,
  modelRemoved,
  selectFirstSelected,
  selectModels,
} from "state/reducers/modelSlice";
import { selectParameters } from "state/reducers/parametersSlice";
import {
  calculate,
  centralPos,
  replaceParametersToIds,
  round,
  wait,
} from "utilities";
import { v4 as uuid } from "uuid";

export interface RotateProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  mainScene: BABYLON.Scene | any;
}

let gizmoManager: GizmoManager | null = null;

function RotateMenu({ visible, setVisible, mainScene }: RotateProps) {
  const [xAxis, setXAxis] = useState("0");
  const [yAxis, setYAxis] = useState("0");
  const [zAxis, setZAxis] = useState("0");
  const [factor, setFactor] = useState("1");
  const [xOrigin, setXOrigin] = useState("0");
  const [yOrigin, setYOrigin] = useState("0");
  const [zOrigin, setZOrigin] = useState("0");
  const [isShapeCenter, setIsShapeCenter] = useState(true);
  const [isCanCopy, setIsCanCopy] = useState(false);

  const models = useAppSelector(selectModels);
  const selectedModels = models.filter((m) => m.selected);
  const modelsToDraw = Object.values(models);
  const arrayModel = modelsToDraw.flat();
  const dispatch = useAppDispatch();
  const firstSelected: any = useAppSelector(selectFirstSelected);
  const parameters = useAppSelector(selectParameters);
  const username = useAppSelector(selectUsername);
  const { projectId } = useParams<{ projectId: string }>();
  const [previewModels, setPreviewModels] = useState<any[]>([]);
  const [tempPreviewModels, setTempPreviewModels] = useState<any[]>([]);

  const [isValid, setIsValid] = useState(true);
  const saving = useAppSelector((state) => state.histories.saving);

  useEffect(() => {
    if (visible) {
      dispatch(setPopupOpen(true));
    } else if (!visible) {
      dispatch(setPopupOpen(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, dispatch]);

  const validateFields = () => {
    try {
      if (
        isNaN(
          parseFloat(
            calculate(
              replaceParametersToIds(xAxis, parameters),
              parameters
            ).toString()
          )
        ) ||
        isNaN(
          parseFloat(
            calculate(
              replaceParametersToIds(yAxis, parameters),
              parameters
            ).toString()
          )
        ) ||
        isNaN(
          parseFloat(
            calculate(
              replaceParametersToIds(zAxis, parameters),
              parameters
            ).toString()
          )
        ) ||
        (!isShapeCenter &&
          isNaN(
            parseFloat(
              calculate(
                replaceParametersToIds(xOrigin, parameters),
                parameters
              ).toString()
            )
          )) ||
        isNaN(
          parseFloat(
            calculate(
              replaceParametersToIds(yOrigin, parameters),
              parameters
            ).toString()
          )
        ) ||
        isNaN(
          parseFloat(
            calculate(
              replaceParametersToIds(zOrigin, parameters),
              parameters
            ).toString()
          )
        ) ||
        (isCanCopy &&
          (isNaN(
            parseFloat(
              calculate(
                replaceParametersToIds(factor, parameters),
                parameters
              ).toString()
            )
          ) ||
            !Number.isInteger(
              parseFloat(
                calculate(
                  replaceParametersToIds(factor, parameters),
                  parameters
                ).toString()
              )
            ) ||
            parseFloat(
              calculate(
                replaceParametersToIds(factor, parameters),
                parameters
              ).toString()
            ) < 1))
      ) {
        setIsValid(false);
        return;
      }

      setIsValid(true);
    } catch (error) {
      setIsValid(false);
    }
  };

  useEffect(() => {
    validateFields();
  }, [
    xAxis,
    yAxis,
    zAxis,
    isShapeCenter,
    xOrigin,
    yOrigin,
    zOrigin,
    isCanCopy,
    factor,
    parameters,
  ]);

  useEffect(() => {
    // Initialize GizmoManager here
    if (mainScene && !gizmoManager && visible) {
      gizmoManager = new GizmoManager(mainScene);
    }
  }, [mainScene, visible]);

  useEffect(() => {
    models.forEach((model) => {
      if (
        model.category.toLowerCase() === "preview" &&
        tempPreviewModels.find((m) => m.id === model.id)
      ) {
        dispatch(modelRemoved(model.id));
      }
    });

    const factorValue = calculate(
      replaceParametersToIds(factor, parameters),
      parameters
    );

    if (!isCanCopy || tempPreviewModels.length === factorValue || !visible)
      return;

    let previewModelsArray: Model[] = [];

    selectedModels.forEach((model) => {
      for (let i = 0; i < factorValue; i++) {
        const newModel: any = {
          ...model,
          category: "Preview",
          name: model.name,
          id: "PreviewTemp-" + uuid(),
          selected: false,
          status: "Added",
          position: {
            x: model.position.x,
            y: model.position.y,
            z: model.position.z,
          },
        };

        dispatch(modelAdded(newModel));
        previewModelsArray.push(newModel);
      }
    });

    console.log("saving", previewModelsArray);
    setTempPreviewModels(previewModelsArray);
  }, [visible, factor, isCanCopy]);

  useEffect(() => {
    if (visible) {
      const keyDownFunc = (event: any) => {
        if (event.key === "Escape") {
          document.getElementById("rotate-cancel-btn")?.click();
          document.removeEventListener("keydown", keyDownFunc);
        } else if (event.key === "Enter") {
          document.getElementById("rotate-ok-btn")?.click();
          document.removeEventListener("keydown", keyDownFunc);
        }
      };
      document.addEventListener("keydown", keyDownFunc);
      if (gizmoManager) {
        gizmoManager.usePointerToAttachGizmos = false;
        gizmoManager.rotationGizmoEnabled = true;
      }

      let selectedMesh = mainScene.getMeshById(firstSelected);
      let tSelectedModels = arrayModel.filter(
        (model) => model.selected && model.type !== "folder"
      );

      if (!previewModels.length) {
        removePreviews();

        let previewModelsArray = selectedModels.map((model) => {
          const newModel: any = {
            ...model,
            category: "Preview",
            name: model.name,
            id: "Preview-" + uuid(),
            selected: false,
            status: "Added",
            position: {
              x: model.position.x,
              y: model.position.y,
              z: model.position.z,
            },
          };

          dispatch(modelAdded(newModel));

          return newModel;
        });

        setPreviewModels(previewModelsArray);
      }

      if (selectedMesh && gizmoManager) {
        // gizmoManager.attachToMesh(selectedMesh);
        // [
        //   gizmoManager.gizmos.rotationGizmo?.xGizmo,
        //   gizmoManager.gizmos.rotationGizmo?.yGizmo,
        //   gizmoManager.gizmos.rotationGizmo?.zGizmo,
        // ].map((gizmo: any) => {
        //   if (gizmo) gizmo.updateGizmoRotationToMatchAttachedMesh = false;
        // });

        const xAxisValue = calculate(
          replaceParametersToIds(xAxis, parameters),
          parameters
        );
        const yAxisValue = calculate(
          replaceParametersToIds(yAxis, parameters),
          parameters
        );
        const zAxisValue = calculate(
          replaceParametersToIds(zAxis, parameters),
          parameters
        );

        const xOriginValue = calculate(
          replaceParametersToIds(xOrigin, parameters),
          parameters
        );
        const yOriginValue = calculate(
          replaceParametersToIds(yOrigin, parameters),
          parameters
        );
        const zOriginValue = calculate(
          replaceParametersToIds(zOrigin, parameters),
          parameters
        );

        const parent = new BABYLON.Mesh("gizmoParent", mainScene);
        parent.setBoundingInfo(
          new BABYLON.BoundingInfo(
            new BABYLON.Vector3(0, 0, 0),
            new BABYLON.Vector3(0, 0, 0)
          )
        );

        if (isShapeCenter) {
          parent.position = new BABYLON.Vector3(
            centralPos(selectedModels, mainScene).x,
            centralPos(selectedModels, mainScene).y,
            centralPos(selectedModels, mainScene).z
          );
        } else {
          parent.position = new BABYLON.Vector3(
            xOriginValue,
            yOriginValue,
            zOriginValue
          );
        }

        parent.rotation = new BABYLON.Vector3(
          (xAxisValue * Math.PI) / 180,
          (yAxisValue * Math.PI) / 180,
          (zAxisValue * Math.PI) / 180
        );

        gizmoManager.attachToMesh(parent);

        const basePosition = parent.position.clone();

        gizmoManager.gizmos.rotationGizmo?.xGizmo.dragBehavior.onDragObservable.add(
          (e) => {
            let mesh: any = gizmoManager?.gizmos.rotationGizmo?.attachedMesh;
            setXAxis(round((mesh.rotation.x * 180) / Math.PI).toString());
            setYAxis(round((mesh.rotation.y * 180) / Math.PI).toString());
            setZAxis(round((mesh.rotation.z * 180) / Math.PI).toString());
          }
        );
        gizmoManager.gizmos.rotationGizmo?.yGizmo.dragBehavior.onDragObservable.add(
          (e) => {
            let mesh: any = gizmoManager?.gizmos.rotationGizmo?.attachedMesh;
            setXAxis(round((mesh.rotation.x * 180) / Math.PI).toString());
            setYAxis(round((mesh.rotation.y * 180) / Math.PI).toString());
            setZAxis(round((mesh.rotation.z * 180) / Math.PI).toString());
          }
        );
        gizmoManager.gizmos.rotationGizmo?.zGizmo.dragBehavior.onDragObservable.add(
          (e) => {
            let mesh: any = gizmoManager?.gizmos.rotationGizmo?.attachedMesh;
            setXAxis(round((mesh.rotation.x * 180) / Math.PI).toString());
            setYAxis(round((mesh.rotation.y * 180) / Math.PI).toString());
            setZAxis(round((mesh.rotation.z * 180) / Math.PI).toString());
          }
        );
      }
    } else {
      if (gizmoManager) {
        gizmoManager.positionGizmoEnabled = false;
        gizmoManager.rotationGizmoEnabled = false;
        gizmoManager.scaleGizmoEnabled = false;
        gizmoManager.attachedMesh?.dispose();
        gizmoManager.attachToMesh(null);
      }

      setPreviewModels([]);
    }
  }, [visible, mainScene, isShapeCenter, xOrigin, yOrigin, zOrigin]);

  useEffect(() => {
    if (!gizmoManager || !gizmoManager.attachedMesh) return;

    gizmoManager.attachedMesh.rotation.set(0, 0, 0);
  }, [visible]);

  const removePreviews = () => {
    console.log("remove called");
    const previewModels = models.filter(
      (m) => m.category.toLowerCase() === "preview"
    );
    const previewMeshes = mainScene.meshes.filter((mesh: BABYLON.Mesh) =>
      mesh?.id?.startsWith("Preview-")
    );
    previewMeshes.forEach((mesh: BABYLON.Mesh) => {
      if (!previewModels.find((m) => m.id === mesh.id)) {
        mesh.dispose();
      }
    });
    previewModels.forEach((model) => {
      dispatch(modelRemoved(model.id));
    });
  };

  const handlePreview = () => {
    if (!visible) return;

    const parent = gizmoManager?.attachedMesh;

    if (!parent) return;

    let parentPos = isShapeCenter
      ? new BABYLON.Vector3(
          centralPos(selectedModels, mainScene).x,
          centralPos(selectedModels, mainScene).y,
          centralPos(selectedModels, mainScene).z
        )
      : new BABYLON.Vector3(
          calculate(replaceParametersToIds(xOrigin, parameters), parameters),
          calculate(replaceParametersToIds(yOrigin, parameters), parameters),
          calculate(replaceParametersToIds(zOrigin, parameters), parameters)
        );

    const xAxisValue = calculate(
      replaceParametersToIds(xAxis, parameters),
      parameters
    );
    const yAxisValue = calculate(
      replaceParametersToIds(yAxis, parameters),
      parameters
    );
    const zAxisValue = calculate(
      replaceParametersToIds(zAxis, parameters),
      parameters
    );

    const factorValue = calculate(
      replaceParametersToIds(factor, parameters),
      parameters
    );

    tempPreviewModels.forEach((model, index) => {
      const mesh: BABYLON.Mesh = mainScene.getMeshById(model.id);

      console.log("temp", mesh?.id);

      if (!mesh) return;

      mesh.rotation = new BABYLON.Vector3(
        model.rotation.x,
        model.rotation.y,
        model.rotation.z
      );
      mesh.position = new BABYLON.Vector3(
        model.position.x,
        model.position.y,
        model.position.z
      );

      mesh.rotateAround(
        parentPos,
        new BABYLON.Vector3(1, 0, 0),
        (xAxisValue * (index + 1) * Math.PI) / 180
      );
      mesh.rotateAround(
        parentPos,
        new BABYLON.Vector3(0, 1, 0),
        (yAxisValue * (index + 1) * Math.PI) / 180
      );
      mesh.rotateAround(
        parentPos,
        new BABYLON.Vector3(0, 0, 1),
        (zAxisValue * (index + 1) * Math.PI) / 180
      );
    });

    previewModels.forEach((model, index) => {
      const originalId = selectedModels[index].id.replace("Preview-", "");
      const originalMesh = mainScene.getMeshById(
        models.find((m) => m.id === originalId)?.id
      );
      const mesh: BABYLON.Mesh = mainScene.getMeshById(model.id);

      if (!mesh || !originalMesh) return;

      mesh.rotation = originalMesh.rotation.clone();
      mesh.position = originalMesh.position.clone();

      mesh.rotateAround(
        parentPos,
        new BABYLON.Vector3(1, 0, 0),
        (xAxisValue * Math.PI) / 180
      );
      mesh.rotateAround(
        parentPos,
        new BABYLON.Vector3(0, 1, 0),
        (yAxisValue * Math.PI) / 180
      );
      mesh.rotateAround(
        parentPos,
        new BABYLON.Vector3(0, 0, 1),
        (zAxisValue * Math.PI) / 180
      );
    });
  };

  useEffect(() => {
    handlePreview();
  }, [
    xAxis,
    yAxis,
    zAxis,
    factor,
    xOrigin,
    yOrigin,
    zOrigin,
    isCanCopy,
    tempPreviewModels.length,
    isShapeCenter,
  ]);
  const [updateGizmoPosition, setUpdateGizmoPosition] = useState(0);

  const handleAxisChange = (value: string, setAxis: (val: string) => void) => {
    setAxis(value);
    setUpdateGizmoPosition(updateGizmoPosition + 1);
  };

  useEffect(() => {
    const parsedXAxis = calculate(
      replaceParametersToIds(xAxis, parameters),
      parameters
    );
    const parsedYAxis = calculate(
      replaceParametersToIds(yAxis, parameters),
      parameters
    );
    const parsedZAxis = calculate(
      replaceParametersToIds(zAxis, parameters),
      parameters
    );
    const origins = {
      xOrigin: isShapeCenter
        ? centralPos(selectedModels, mainScene).x
        : calculate(replaceParametersToIds(xOrigin, parameters), parameters),
      yOrigin: isShapeCenter
        ? centralPos(selectedModels, mainScene).y
        : calculate(replaceParametersToIds(yOrigin, parameters), parameters),
      zOrigin: isShapeCenter
        ? centralPos(selectedModels, mainScene).z
        : calculate(replaceParametersToIds(zOrigin, parameters), parameters),
    };

    gizmoManager?.attachedMesh?.position.set(
      origins.xOrigin,
      origins.yOrigin,
      origins.zOrigin
    );

    gizmoManager?.attachedMesh?.rotation.set(
      parsedXAxis * (Math.PI / 180),
      parsedYAxis * (Math.PI / 180),
      parsedZAxis * (Math.PI / 180)
    );
  }, [updateGizmoPosition]);

  const handleFactorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFactor(e.target.value);
  };

  const handleOriginChange = (
    value: string,
    setOrigin: (val: string) => void
  ) => {
    setOrigin(value);
    setUpdateGizmoPosition(updateGizmoPosition + 1);
  };

  const handleIsCanCopyChanges = (e: any) => {
    setIsCanCopy(e.target.checked);
  };

  const handleIsShapeCenterChanges = (e: any) => {
    setIsShapeCenter(e.target.checked);
  };

  const handleCancel = () => {
    removePreviews();
    setTempPreviewModels([]);
    setVisible(false);
  };

  const handleOk = () => {
    let idArray: string[] = [];
    const origins = {
      xOrigin: isShapeCenter
        ? centralPos(selectedModels, mainScene).x
        : replaceParametersToIds(xOrigin, parameters),
      yOrigin: isShapeCenter
        ? centralPos(selectedModels, mainScene).y
        : replaceParametersToIds(yOrigin, parameters),
      zOrigin: isShapeCenter
        ? centralPos(selectedModels, mainScene).z
        : replaceParametersToIds(zOrigin, parameters),
    };

    console.log(
      "is can copy",
      isCanCopy,
      tempPreviewModels.length,
      tempPreviewModels
    );

    if (isCanCopy) {
      tempPreviewModels.forEach(async (model, index) => {
        const mesh: BABYLON.Mesh = mainScene.getMeshById(model.id);
        console.log("mesh", mesh, model.id);
        if (!mesh) return;
        let position = mesh.absolutePosition;
        let rotation = mesh.absoluteRotationQuaternion.toEulerAngles();
        const newModel = {
          ...model,
          name: model.name,
          id: uuid(),
          selected: false,
          status: "Added",
          rotation: {
            x: rotation.x,
            y: rotation.y,
            z: rotation.z,
          },
          position: {
            x: position.x,
            y: position.y,
            z: position.z,
          },
          // origin: {
          //   x: origins.xOrigin,
          //   y: origins.yOrigin,
          //   z: origins.zOrigin,
          // },
          // mergedMeshId: model.type === "mergedMesh" ? model.id : undefined,
        };

        console.log("added", {
          id: newModel.id,
          rotation: {
            x: rotation.x,
            y: rotation.y,
            z: rotation.z,
          },
          position: {
            x: position.x,
            y: position.y,
            z: position.z,
          },
        });
        dispatch(modelAdded(newModel));
        idArray.push(newModel.id);
      });
    }

    selectedModels.forEach(async (model, index) => {
      const mesh = mainScene.getMeshById(previewModels[index].id);

      await wait(100);

      let position = mesh.absolutePosition;
      let rotation = mesh.absoluteRotationQuaternion.toEulerAngles();
      await dispatch(
        modelAltered({
          ...model,
          status: "Updated",
          position: {
            x: position.x,
            y: position.y,
            z: position.z,
          },
          rotation: {
            x: rotation.x,
            y: rotation.y,
            z: rotation.z,
          },
          selected: false,
        })
      );

      await dispatch(
        addHistory({
          payloadData: {
            rotate: {
              name: model.name,
              id: model.id,
              xAxis: replaceParametersToIds(xAxis, parameters),
              yAxis: replaceParametersToIds(yAxis, parameters),
              zAxis: replaceParametersToIds(zAxis, parameters),
              xOrigin: origins.xOrigin,
              yOrigin: origins.yOrigin,
              zOrigin: origins.zOrigin,
              isShapeCenter: isShapeCenter,
              isCanCopy: isCanCopy,
              factor: replaceParametersToIds(factor, parameters),
              idArray: isCanCopy ? idArray : undefined,
            },
          },
          currentUsername: username,
          projectId: projectId || "",
        })
      );
    });

    setVisible(false);
  };

  useEffect(() => {
    if (visible) {
      setXAxis("0");
      setYAxis("0");
      setZAxis("0");
      setFactor("1");
      setIsCanCopy(false);
      setIsShapeCenter(true);
      setXOrigin("0");
      setYOrigin("0");
      setZOrigin("0");
    }
  }, [visible]);

  return (
    <DraggableModal
      title={
        <div className="pointer-events-auto cursor-pointer bg-red-300 w-full text-xl font-semibold rounded-t-md py-2 text-center border-b-2 border-gray-800">
          Rotate
        </div>
      }
      visible={visible}
      buttons={
        <div className="flex flex-row gap-1 w-full justify-between">
          <div />
          <div className="flex gap-2">
            <button
              onClick={handleCancel}
              id="rotate-cancel-btn"
              disabled={saving}
              className="bg-red-300 enabled:hover:bg-red-400 enabled:active:bg-red-500 rounded text-center px-4 py-1 disable-drag disabled:opacity-50"
            >
              Cancel
            </button>
            <button
              onClick={handleOk}
              id="rotate-ok-btn"
              disabled={!isValid || saving}
              className={`rounded text-center px-4 py-1 disable-drag bg-green-300 enabled:hover:bg-green-400 enabled:active:bg-green-500 disabled:opacity-50`}
            >
              {saving ? (
                <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-900 animate-spin dark:text-gray-600 fill-white"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "OK"
              )}
            </button>
          </div>
        </div>
      }
    >
      <form>
        <div className="mt-4 grid grid-cols-1 gap-x-3 gap-y-3">
          <div className="col-span-full flex items-center">
            <label
              htmlFor="xAxis"
              className="flex text-sm font-large leading-6 text-gray-900 mr-2"
            >
              X
            </label>
            <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
              <input
                type="text"
                name="xAxis"
                value={xAxis}
                onChange={(e) => handleAxisChange(e.target.value, setXAxis)}
                id="xAxis"
                autoComplete="off"
                className="flex flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm rounded-md shadow-sm ring-1 ring-inset ring-primary-600"
              />
            </div>
            <label className="flex text-sm font-large leading-6 text-gray-900 ml-2">
              deg
            </label>
          </div>
          <div className="col-span-full flex items-center">
            <label
              htmlFor="yAxis"
              className="block text-sm font-medium leading-6 text-gray-900 mr-2"
            >
              Y
            </label>
            <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
              <input
                type="text"
                name="yAxis"
                value={yAxis}
                onChange={(e) => handleAxisChange(e.target.value, setYAxis)}
                id="yAxis"
                autoComplete="off"
                className="flex flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm rounded-md shadow-sm ring-1 ring-inset ring-primary-600"
              />
            </div>
            <label className="flex text-sm font-large leading-6 text-gray-900 ml-2">
              deg
            </label>
          </div>
          <div className="col-span-full flex items-center">
            <label
              htmlFor="zAxis"
              className="block text-sm font-medium leading-6 text-gray-900 mr-2"
            >
              Z
            </label>
            <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
              <input
                type="text"
                name="zAxis"
                value={zAxis}
                onChange={(e) => handleAxisChange(e.target.value, setZAxis)}
                id="zAxis"
                autoComplete="off"
                className="flex flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm rounded-md shadow-sm ring-1 ring-inset ring-primary-600"
              />
            </div>
            <label className="flex text-sm font-large leading-6 text-gray-900 ml-2">
              deg
            </label>
          </div>
          <div className="col-span-full">
            <div className="relative flex items-center">
              <div className="flex-grow border-t border-gray-400"></div>
              <div className="flex-grow border-t border-gray-400"></div>
            </div>
          </div>
          <div className="accent-primary-600 col-span-full flex items-center">
            <label
              htmlFor="shapeCenter"
              className="block text-sm font-medium leading-6 text-gray-900 mr-2"
            >
              Shape center
            </label>
            <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
              <input
                type="checkbox"
                name="shapeCenter"
                onChange={handleIsShapeCenterChanges}
                id="shapeCenter"
                autoComplete="off"
                className="flex flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm rounded-md shadow-sm ring-1 ring-inset ring-primary-600"
                checked={isShapeCenter}
              />
            </div>
          </div>

          <div
            className={`col-span-full flex items-center ${
              isShapeCenter ? "opacity-30" : ""
            }`}
          >
            <label
              htmlFor="xOrigin"
              className="block text-sm font-medium leading-6 text-gray-900 mr-2"
            >
              X Origin
            </label>
            <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
              <input
                type="text"
                name="xOrigin"
                value={xOrigin}
                onChange={(e) => handleOriginChange(e.target.value, setXOrigin)}
                id="xOrigin"
                autoComplete="off"
                className="flex flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm rounded-md shadow-sm ring-1 ring-inset ring-primary-600"
                disabled={isShapeCenter}
              />
            </div>
          </div>

          <div
            className={`col-span-full flex items-center ${
              isShapeCenter ? "opacity-30" : ""
            }`}
          >
            <label
              htmlFor="yOrigin"
              className="block text-sm font-medium leading-6 text-gray-900 mr-2"
            >
              Y Origin
            </label>
            <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
              <input
                type="text"
                name="yOrigin"
                value={yOrigin}
                onChange={(e) => handleOriginChange(e.target.value, setYOrigin)}
                id="yOrigin"
                autoComplete="off"
                className="flex flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm rounded-md shadow-sm ring-1 ring-inset ring-primary-600"
                disabled={isShapeCenter}
              />
            </div>
          </div>

          <div
            className={`col-span-full flex items-center ${
              isShapeCenter ? "opacity-30" : ""
            }`}
          >
            <label
              htmlFor="zOrigin"
              className="block text-sm font-medium leading-6 text-gray-900 mr-2"
            >
              Z Origin
            </label>
            <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
              <input
                type="text"
                name="zOrigin"
                value={zOrigin}
                onChange={(e) => handleOriginChange(e.target.value, setZOrigin)}
                id="zOrigin"
                autoComplete="off"
                className="flex flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm rounded-md shadow-sm ring-1 ring-inset ring-primary-600"
                disabled={isShapeCenter}
              />
            </div>
          </div>

          <div className="col-span-full">
            <div className="relative flex items-center">
              <div className="flex-grow border-t border-gray-400"></div>
              <div className="flex-grow border-t border-gray-400"></div>
            </div>
          </div>
          <div className="accent-primary-600 col-span-full flex items-center">
            <label
              htmlFor="copy"
              className="block text-sm font-medium leading-6 text-gray-900 mr-2"
            >
              Copy
            </label>
            <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
              <input
                type="checkbox"
                name="copy"
                onChange={handleIsCanCopyChanges}
                id="copy"
                autoComplete="off"
                className="flex flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm rounded-md shadow-sm ring-1 ring-inset ring-primary-600"
                checked={isCanCopy}
              />
            </div>
          </div>
          <div
            className={`col-span-full items-center ${
              isCanCopy ? "" : "opacity-30"
            }`}
          >
            <label
              htmlFor="factor"
              className="block text-sm font-medium leading-6 text-gray-900 mr-2"
            >
              Repetitions
            </label>
            <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
              <input
                type="text"
                name="factor"
                value={factor}
                onChange={handleFactorChange}
                disabled={!isCanCopy}
                id="factor"
                autoComplete="off"
                className="flex flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm rounded-md shadow-sm ring-1 ring-inset ring-primary-600"
              />
            </div>
          </div>
        </div>
      </form>
    </DraggableModal>
  );
}

export default RotateMenu;
