import { useEffect, useRef, useState } from "react";
import MyIcon from "assets/MyIcons";
import { Auth, Storage } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import {
  selectEmail,
  selectUserPlan,
  selectUsername,
} from "state/reducers/authSlice";
import { useAppSelector } from "state/hooks";
import api from "services/api";
import { Tooltip } from "react-tooltip";
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import moment from "moment";

interface Metrics {
  status: string;
  cpu: {
    cores: number;
    utilization: number;
  };
  memory: {
    memory: number;
    utilization: number;
  };
}

interface InstanceMetrics {
  [key: string]: Metrics;
}

interface Paper {
  title: string;
  url: string;
  category: string;
  publishedDate: string;
}

function Home() {
  const [isLoading, setIsLoading] = useState(false);
  const [isQuerying, setIsQuerying] = useState(true);
  const [projects, setProjects] = useState<Array<any>>([]);
  const [papers, setPapers] = useState<Paper[]>([]);
  const [isQueryingPapers, setIsQueryingPapers] = useState(true);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const navigate = useNavigate();
  const email = useAppSelector(selectEmail);
  const userPlan = useAppSelector(selectUserPlan);
  const userName = useAppSelector(selectUsername);

  const tableRef = useRef<HTMLTableElement>(null);
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);
  const tablePaginationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const categories = [
      { query: "cat:eess.SP AND abs:antenna", category: "Antennas" },
      { query: "cat:eess.SP AND abs:5G", category: "5G" },
      { query: "cat:eess.SP AND abs:MIMO", category: "MIMO" },
      { query: "cat:eess.SP AND abs:mmWave", category: "mmWave" },
      { query: "cat:eess.SP AND abs:RFID", category: "RFID" },
      { query: "cat:eess.SP AND abs:radar", category: "Radar" },
      { query: "cat:eess.SP AND abs:satellite", category: "Satellites" },
      { query: "cat:eess.SP AND abs:IoT", category: "IoT" },
      { query: "cat:eess.SP AND abs:NFC", category: "NFC" },
    ];

    const isRecent = (date: Date) => {
      const now = new Date();
      const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);

      const paperDate = new Date(date);

      return paperDate >= thirtyDaysAgo && paperDate <= now;
    };

    const fetchData = async () => {
      try {
        const fetchedPapers: Paper[] = [];
        for (const category of categories) {
          const req = await fetch(
            `https://export.arxiv.org/api/query?search_query=${encodeURIComponent(
              category.query
            )}&sortBy=submittedDate&sortOrder=descending&max_results=1`
          );
          const result = await req.text();
          const parser = new DOMParser();
          const xml = parser.parseFromString(result, "application/xml");
          const entries = xml.getElementsByTagName("entry");

          for (let i = 0; i < entries.length; i++) {
            const entry = entries[i];
            const published = new Date(
              entry.getElementsByTagName("published")[0].textContent || ""
            );

            if (isRecent(published)) {
              fetchedPapers.push({
                title:
                  entry.getElementsByTagName("title")[0].textContent ||
                  "No title",
                url: entry.getElementsByTagName("id")[0].textContent || "#",
                category: category.category,
                publishedDate: published.toISOString().split("T")[0],
              });
            }
          }
        }

        const uniquePapers = fetchedPapers
          .filter(
            (article, index, self) =>
              index === self.findIndex((t) => t.title === article.title)
          )
          .slice(0, 5);

        setPapers(uniquePapers);
        setIsQueryingPapers(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsQueryingPapers(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    try {
      Storage.list(`${userName}/projects/`, { pageSize: 1000 }).then(
        async (data) => {
          let projectData: any[] = [];
          const { results } = data;
          const projects: any[] = [];
          results.forEach((result) => {
            if (result.key?.includes("info.json")) {
              projects.push(result);
            }
          });
          const promises = projects.map(async (project) => {
            let info: any = {};
            const infoData = await Storage.get(project.key, {
              download: true,
              cacheControl: "no-cache",
            });
            if (infoData.Body) {
              const dataBody: any = infoData.Body;
              const dataString = await dataBody.text();
              const json = JSON.parse(dataString);
              info = json;
            }
            const projectData = {
              id: project.key.split("/").at(-2),
              lastModified: project.lastModified,
              ...info,
            };
            return projectData;
          });
          projectData = await Promise.all(promises);

          projectData.sort(
            (a, b) =>
              new Date(b.lastModified).getTime() -
              new Date(a.lastModified).getTime()
          );

          setProjects(projectData);
          setIsQuerying(false);
        }
      );
    } catch (e) {
      console.log(e);
    }
  }, [userName]);

  useEffect(() => {
    document.title = `Home | blit.ai`;
  });

  const openPaper = (url: string) => {
    window.open(url);
  };

  function getStatusColor(status: string, tailwind: boolean = false) {
    if (status.includes("Premium")) {
      return tailwind ? "primary" : "#027A48";
    } else {
      return tailwind ? "gray" : "#D0D5DD";
    }
  }

  const mostRecentActivity = projects.length
    ? moment(projects[0].lastModified).fromNow()
    : "No recent activity";

  return (
    <>
      <div className="bg-gray-100 bg-gray-500 text-gray-700 bg-primary-100 bg-primary-500 bg-primary-700 bg-success-100 bg-success-500 text-success-700 bg-error-100 bg-error-500 text-error-700 bg-warning-100 bg-warning-500 text-warning-700"></div>
      <div className="flex w-full overflow-hidden">
        <div
          className={
            openMobileMenu
              ? "fixed min-[1200px]:relative translate-x-0 min-[1200px]:translate-x-0 z-40 transition-all duration-300"
              : "fixed min-[1200px]:relative -translate-x-full min-[1200px]:translate-x-0 z-40 transition-all duration-300"
          }
        >
          {/* SIDEBAR */}
          <aside
            className="font-inter w-80 min-h-screen overflow-y-auto touch-auto border-r-2 border-gray-200 z-30 relative"
            aria-label="Sidebar"
          >
            <div className="overflow-y-auto bg-white flex flex-col h-screen">
              {/* LOGO */}
              <div className="pt-[27px] mx-auto">
                <MyIcon name="blit-logo" />
              </div>
              <div className="flex flex-col justify-between h-full">
                {/* LINKS LIST */}
                <ul className="pt-9 px-4 space-y-1">
                  {/* LINK */}
                  <li>
                    <a
                      href="home"
                      className="bg-gray-50 hover:bg-gray-100 active:bg-gray-200 px-3 py-2 flex items-center justify-between rounded-md"
                    >
                      <span className="flex items-center">
                        <div className="w-6 h-6">
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mx-auto"
                          >
                            <path
                              d="M6 16H14M9.0177 1.76403L2.23539 7.03916C1.78202 7.39178 1.55534 7.56809 1.39203 7.78889C1.24737 7.98447 1.1396 8.20481 1.07403 8.43908C1 8.70355 1 8.99073 1 9.56508V16.8C1 17.9201 1 18.4802 1.21799 18.908C1.40973 19.2843 1.71569 19.5903 2.09202 19.782C2.51984 20 3.07989 20 4.2 20H15.8C16.9201 20 17.4802 20 17.908 19.782C18.2843 19.5903 18.5903 19.2843 18.782 18.908C19 18.4802 19 17.9201 19 16.8V9.56508C19 8.99073 19 8.70355 18.926 8.43908C18.8604 8.20481 18.7526 7.98447 18.608 7.78889C18.4447 7.56809 18.218 7.39178 17.7646 7.03916L10.9823 1.76403C10.631 1.49078 10.4553 1.35415 10.2613 1.30163C10.0902 1.25529 9.9098 1.25529 9.73865 1.30163C9.54468 1.35415 9.36902 1.49078 9.0177 1.76403Z"
                              stroke="black"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>

                        <span className="ml-3 text-md font-semibold text-gray-900">
                          Home
                        </span>
                      </span>
                    </a>
                  </li>

                  {/* LINK */}
                  <li>
                    <a
                      href="overview"
                      className="hover:bg-gray-50 active:bg-gray-100 px-3 py-2 flex items-center justify-between rounded-md"
                    >
                      <span className="flex items-center">
                        <div className="w-6 h-5">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mx-auto"
                          >
                            <path
                              d="M6 13V15M10 9V15M14 5V15M5.8 19H14.2C15.8802 19 16.7202 19 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C19 16.7202 19 15.8802 19 14.2V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V14.2C1 15.8802 1 16.7202 1.32698 17.362C1.6146 17.9265 2.07354 18.3854 2.63803 18.673C3.27976 19 4.11984 19 5.8 19Z"
                              stroke="#667085"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>

                        <span className="ml-3 text-md font-semibold text-gray-700">
                          Overview
                        </span>
                      </span>
                    </a>
                  </li>

                  {/* LINK */}
                  <li>
                    <a
                      href="projects"
                      className="hover:bg-gray-50 active:bg-gray-100 px-3 py-2 flex items-center justify-between rounded-md"
                    >
                      <span className="flex items-center">
                        <div className="w-6 h-6">
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mx-auto"
                          >
                            <path
                              d="M1 11L10.6422 15.8211C10.7734 15.8867 10.839 15.9195 10.9078 15.9324C10.9687 15.9438 11.0313 15.9438 11.0922 15.9324C11.161 15.9195 11.2266 15.8867 11.3578 15.8211L21 11M1 16L10.6422 20.8211C10.7734 20.8867 10.839 20.9195 10.9078 20.9324C10.9687 20.9438 11.0313 20.9438 11.0922 20.9324C11.161 20.9195 11.2266 20.8867 11.3578 20.8211L21 16M1 6L10.6422 1.17889C10.7734 1.1133 10.839 1.0805 10.9078 1.0676C10.9687 1.05616 11.0313 1.05616 11.0922 1.0676C11.161 1.0805 11.2266 1.1133 11.3578 1.17889L21 6L11.3578 10.8211C11.2266 10.8867 11.161 10.9195 11.0922 10.9324C11.0313 10.9438 10.9687 10.9438 10.9078 10.9324C10.839 10.9195 10.7734 10.8867 10.6422 10.8211L1 6Z"
                              stroke="#667085"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>

                        <span className="ml-3 text-md font-semibold text-gray-700">
                          Projects
                        </span>
                      </span>

                      <div className=" w-6 h-6 flex items-center justify-center rounded-full bg-gray-100 text-gray-700 text-xs font-medium">
                        {isQuerying ? "-" : projects.length}
                      </div>
                    </a>
                  </li>
                </ul>

                {/* FOOTER */}
                <footer className="flex flex-col px-4 pb-8 space-y-6">
                  <ul className="space-y-1">
                    {/* LINK */}
                    <li>
                      <a
                        href="#abc"
                        className="hover:bg-gray-50 active:bg-gray-100 px-3 py-2 flex items-center justify-between rounded-md"
                      >
                        <span className="flex items-center">
                          <div className="w-6 h-6">
                            <svg
                              width="20"
                              height="22"
                              viewBox="0 0 20 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="mx-auto"
                            >
                              <path
                                d="M18.7778 21C18.7778 19.4494 18.7778 18.6741 18.5864 18.0432C18.1555 16.6227 17.0439 15.5112 15.6235 15.0803C14.9926 14.8889 14.2173 14.8889 12.6667 14.8889H7.11112C5.56049 14.8889 4.78517 14.8889 4.15429 15.0803C2.73384 15.5112 1.62227 16.6227 1.19138 18.0432C1 18.6741 1 19.4494 1 21M14.8889 6C14.8889 8.76142 12.6503 11 9.88889 11C7.12747 11 4.88889 8.76142 4.88889 6C4.88889 3.23858 7.12747 1 9.88889 1C12.6503 1 14.8889 3.23858 14.8889 6Z"
                                stroke="#667085"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>

                          <span className="ml-3 text-md font-semibold text-gray-700">
                            Account
                          </span>
                        </span>
                      </a>
                    </li>

                    {/* LINK */}
                    <li>
                      <a
                        href="#abc"
                        className="hover:bg-gray-50 active:bg-gray-100 px-3 py-2 flex items-center justify-between rounded-md"
                      >
                        <span className="flex items-center">
                          <div className="w-6 h-6">
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="mx-auto"
                            >
                              <path
                                d="M8.13626 8.13628L3.92893 3.92896M3.92893 18.0711L8.16797 13.8321M13.8611 13.8638L18.0684 18.0711M18.0684 3.92896L13.8287 8.16862M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11ZM15 11C15 13.2091 13.2091 15 11 15C8.79086 15 7 13.2091 7 11C7 8.79086 8.79086 7 11 7C13.2091 7 15 8.79086 15 11Z"
                                stroke="#667085"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>

                          <span className="ml-3 text-md font-semibold text-gray-700">
                            Support
                          </span>
                        </span>
                      </a>
                    </li>
                  </ul>
                  <div className="pt-6 border-t border-gray-200 pl-2 pr-2.5">
                    <div className="flex justify-between">
                      <div className="flex flex-col text-sm">
                        <h6 className="font-semibold text-gray-700">
                          {userPlan || "Unknown plan"}
                        </h6>
                        <h6 className="text-gray-600">{email}</h6>
                      </div>
                      <button
                        className="w-5 h-5"
                        onClick={() => {
                          setIsLoading(true);
                          Auth.signOut().then(() => {
                            localStorage.clear();
                            setIsLoading(false);
                            navigate("/", { replace: true });
                          });
                        }}
                      >
                        {isLoading ? (
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5 animate-spin fill-[#7f56d9] text-white"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        ) : (
                          <>
                            <span
                              data-tooltip-id="logout-tooltip"
                              data-tooltip-content="Log out"
                            >
                              <svg
                                className="w-4 h-4"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.3333 13.1667L16.5 9M16.5 9L12.3333 4.83333M16.5 9H6.5M6.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5H6.5"
                                  stroke="#667085"
                                  strokeWidth="1.66667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                            <Tooltip id="logout-tooltip" />
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </aside>

          <div
            style={{ display: openMobileMenu ? "flex" : "none" }}
            className="z-40 absolute top-0 -right-[30px] bg-white border-r-white border-2 border-[#EAECF0] w-8 h-8 -scale-100 flex min-[1586px]:hidden items-center justify-center rounded-tl-md rounded-bl-md"
            onClick={() => setOpenMobileMenu(false)}
          >
            <MyIcon name="right-arrow" color="#667085" />
          </div>

          <div
            style={{ display: openMobileMenu ? "block" : "none" }}
            className="absolute inset-0 w-[800vw] h-screen bg-black opacity-40"
            onClick={() => setOpenMobileMenu(false)}
          ></div>
        </div>

        <div className="bg-gray-25 w-full">
          {/* HEADER */}
          <div className="py-8">
            <div className="px-8 flex flex-col space-y-5">
              <div className="flex items-center justify-start gap-4">
                <div
                  className="min-[1200px]:hidden flex items-center justify-center cursor-pointer"
                  onClick={() => setOpenMobileMenu(true)}
                >
                  <MyIcon name="menu" />
                </div>
                {/* BREADCRUMB */}
                <div className="flex items-center space-x-2">
                  <div className="w-7 h-7 flex items-center justify-center">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mx-auto"
                    >
                      <path
                        d="M6 16H14M9.0177 1.76403L2.23539 7.03916C1.78202 7.39178 1.55534 7.56809 1.39203 7.78889C1.24737 7.98447 1.1396 8.20481 1.07403 8.43908C1 8.70355 1 8.99073 1 9.56508V16.8C1 17.9201 1 18.4802 1.21799 18.908C1.40973 19.2843 1.71569 19.5903 2.09202 19.782C2.51984 20 3.07989 20 4.2 20H15.8C16.9201 20 17.4802 20 17.908 19.782C18.2843 19.5903 18.5903 19.2843 18.782 18.908C19 18.4802 19 17.9201 19 16.8V9.56508C19 8.99073 19 8.70355 18.926 8.43908C18.8604 8.20481 18.7526 7.98447 18.608 7.78889C18.4447 7.56809 18.218 7.39178 17.7646 7.03916L10.9823 1.76403C10.631 1.49078 10.4553 1.35415 10.2613 1.30163C10.0902 1.25529 9.9098 1.25529 9.73865 1.30163C9.54468 1.35415 9.36902 1.49078 9.0177 1.76403Z"
                        stroke="#667085"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>

                  <div className="pr-1 w-4 h-4 flex items-center justify-center">
                    <svg
                      width="6"
                      height="10"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mx-auto"
                    >
                      <path
                        d="M1 9L5 5L1 1"
                        stroke="#D0D5DD"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>

                  <span className="my-auto px-2 py-1 flex text-sm items-center bg-grayIron-100 justify-center rounded focus:outline-none text-gray-600 font-semibold">
                    Home
                  </span>
                </div>
              </div>

              {/* CONTENT */}
              <div className="flex flex-col space-y-1">
                <h4 className=" text-tsm text-gray-900 font-semibold">Home</h4>
                <h6 className=" text-md text-gray-600">View your dashboard.</h6>
              </div>

              <div className="border-b border-gray-200"></div>
            </div>
          </div>

          <div className="px-4 md:px-8 pb-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="bg-gradient-to-bl from-teal-50 to-orangeDark-25 border border-gray-200 shadow-md rounded-xl p-6 transition-transform transform hover:scale-105 backdrop-blur-md">
              <p className="font-bold text-base text-gray-800 underline pb-3">
                Number of projects
              </p>
              <p className="text-teal-600 flex items-center bg-teal-100 py-0.5 max-w-fit pr-4 pl-1.5 mix-blend-multiply rounded-2xl">
                <svg
                  className="my-auto w-6 h-7 mr-1.5"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="21" height="21" rx="16" fill="#2ED3B7" />
                  <g transform="scale(0.65) translate(5, 5)">
                    <path
                      d="M1 11L10.6422 15.8211C10.7734 15.8867 10.839 15.9195 10.9078 15.9324C10.9687 15.9438 11.0313 15.9438 11.0922 15.9324C11.161 15.9195 11.2266 15.8867 11.3578 15.8211L21 11M1 16L10.6422 20.8211C10.7734 20.8867 10.839 20.9195 10.9078 20.9324C10.9687 20.9438 11.0313 20.9438 11.0922 20.9324C11.161 20.9195 11.2266 20.8867 11.3578 20.8211L21 16M1 6L10.6422 1.17889C10.7734 1.1133 10.839 1.0805 10.9078 1.0676C10.9687 1.05616 11.0313 1.05616 11.0922 1.0676C11.161 1.0805 11.2266 1.1133 11.3578 1.17889L21 6L11.3578 10.8211C11.2266 10.8867 11.161 10.9195 11.0922 10.9324C11.0313 10.9438 10.9687 10.9438 10.9078 10.9324C10.839 10.9195 10.7734 10.8867 10.6422 10.8211L1 6Z"
                      stroke="white"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
                {isQuerying ? "-" : projects.length}
              </p>
            </div>

            <div className="bg-gradient-to-bl from-blueLight-100 to-blue-50 border border-gray-200 shadow-md rounded-xl p-6 transition-transform transform hover:scale-105 backdrop-blur-md">
              <p className="font-bold text-base text-gray-800 underline pb-3">
                Last activity
              </p>
              <p className="text-blueLight-600 flex items-center bg-blueLight-100 py-0.5 max-w-fit pr-4 pl-1.5 mix-blend-multiply rounded-2xl">
                <svg
                  className="my-auto w-6 h-7 mr-1.5"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="21" height="21" rx="16" fill="#36BFFA" />
                  <g transform="scale(0.7) translate(4.15, 4.15)">
                    <path
                      d="M11 5V11L15 13M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                      stroke="white"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>

                {isQuerying ? "-" : mostRecentActivity}
              </p>
            </div>

            <div className="bg-gradient-to-bl from-orangeDark-50 to-rose-25 border border-gray-200 shadow-md rounded-xl p-6 transition-transform transform hover:scale-105 backdrop-blur-md">
              <p className="font-bold text-base text-gray-800 underline pb-3">
                License plan
              </p>
              <p
                className={`${
                  userPlan.includes("Premium")
                    ? "text-primary-600"
                    : "text-gray-600"
                } flex items-center bg-${getStatusColor(
                  userPlan,
                  true
                )}-100 py-0.5 max-w-fit pr-4 pl-1.5 mix-blend-multiply rounded-2xl`}
              >
                {userPlan.includes("Premium") ? (
                  <svg
                    className="my-auto w-6 h-7 mr-1.5"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="21" height="21" rx="16" fill="#B692F6" />
                    <g transform="scale(0.7) translate(5, 4)">
                      <path
                        d="M3.54373 20.275V15.6375M3.54373 6.3625V1.725M1.22498 4.04375H5.86248M1.22498 17.9562H5.86248M11.4275 2.6525L9.81903 6.83447C9.55746 7.51454 9.42668 7.85457 9.2233 8.1406C9.04305 8.39409 8.82157 8.61557 8.56807 8.79582C8.28205 8.9992 7.94202 9.12998 7.26194 9.39155L3.07998 11L7.26195 12.6084C7.94202 12.87 8.28205 13.0008 8.56807 13.2042C8.82157 13.3844 9.04305 13.6059 9.2233 13.8594C9.42668 14.1454 9.55746 14.4855 9.81903 15.1655L11.4275 19.3475L13.0359 15.1655C13.2975 14.4855 13.4283 14.1454 13.6317 13.8594C13.8119 13.6059 14.0334 13.3844 14.2869 13.2042C14.5729 13.0008 14.9129 12.87 15.593 12.6084L19.775 11L15.593 9.39155C14.9129 9.12998 14.5729 8.9992 14.2869 8.79582C14.0334 8.61557 13.8119 8.39409 13.6317 8.1406C13.4283 7.85457 13.2975 7.51454 13.0359 6.83447L11.4275 2.6525Z"
                        stroke="white"
                        strokeWidth="1.67"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                ) : (
                  <svg
                    className="my-auto w-6 h-7 mr-1.5"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="21" height="21" rx="16" fill="#98A2B3" />
                    <g transform="scale(0.75) translate(3, 4)">
                      <path
                        d="M7.98278 15.0936H2.88917M5.6675 10H1.5M7.98278 4.9064H3.35222M15.3917 1.66501L9.28264 10.2177C9.01224 10.5962 8.87705 10.7855 8.8829 10.9433C8.88799 11.0807 8.95388 11.2087 9.06271 11.2927C9.18772 11.3892 9.42032 11.3892 9.88552 11.3892H14.4656L13.5394 18.335L19.6485 9.78236C19.9189 9.40382 20.0541 9.21454 20.0482 9.05674C20.0431 8.91935 19.9772 8.79133 19.8684 8.70733C19.7434 8.61084 19.5108 8.61084 19.0456 8.61084H14.4656L15.3917 1.66501Z"
                        stroke="white"
                        strokeWidth="1.67"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                )}
                {userPlan}
              </p>
            </div>
          </div>

          <div className="px-4 md:px-8 pb-6 flex flex-col h-full">
            <div className="bg-white px-6 py-5 rounded-t-xl shadow-sm border border-b-0 border-gray-200">
              <div className="flex items-center justify-between">
                <h5 className=" text-lg text-gray-900 font-semibold">
                  Latest research papers
                </h5>
              </div>
            </div>

            <div ref={tableRef} style={{ minHeight: "100%" }}>
              <TableContainer className="bg-white border border-gray-200 shadow-sm rounded-xl rounded-t-none">
                <Table style={{ minHeight: "100%" }}>
                  <TableHead
                    ref={tableHeadRef}
                    className="bg-gray-50 border-b border-gray-200"
                  >
                    <TableRow className="cursor-default">
                      <TableCell
                        className="cursor-auto"
                        width={"50%"}
                        style={{
                          padding: "4px 23px",
                          fontSize: "13px",
                          fontFamily: "Inter",
                          fontWeight: "600",
                          height: "44px",
                          color: "#475467",
                        }}
                      >
                        <div style={{ inlineSize: "max-content" }}>
                          Paper title
                        </div>
                      </TableCell>
                      <TableCell
                        className="cursor-auto"
                        width={"12%"}
                        style={{
                          padding: "4px 16px",
                          fontSize: "13px",
                          fontFamily: "Inter",
                          fontWeight: "600",
                          height: "44px",
                          color: "#475467",
                        }}
                      >
                        <div style={{ inlineSize: "max-content" }}>
                          Category
                        </div>
                      </TableCell>
                      <TableCell
                        className="cursor-auto"
                        width={"17%"}
                        style={{
                          padding: "4px 16px",
                          fontSize: "13px",
                          fontFamily: "Inter",
                          fontWeight: "600",
                          height: "44px",
                          color: "#475467",
                        }}
                      >
                        <div style={{ inlineSize: "max-content" }}>
                          Publication date
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isQueryingPapers ? (
                      Array.from({ length: 5 }).map((_, index) => (
                        <TableRow key={index} className="cursor-default">
                          <TableCell
                            style={{
                              padding: "4px 24px",
                              fontSize: "0.875rem",
                              fontFamily: "Inter",
                              height: "44px",
                              color: "#475467",
                              borderBottom: index === 4 ? "transparent" : "",
                            }}
                          >
                            <div className="animate-pulse items-center">
                              <div className="my-0.5 h-4 bg-gray-300 rounded-md w-full"></div>
                            </div>
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.875rem",
                              fontFamily: "Inter",
                              height: "44px",
                              color: "#475467",
                              borderBottom: index === 4 ? "transparent" : "",
                            }}
                          >
                            <div className="animate-pulse items-center">
                              <div className="my-0.5 h-4 bg-gray-300 rounded-md w-20"></div>
                            </div>
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.875rem",
                              fontFamily: "Inter",
                              height: "44px",
                              color: "#475467",
                              borderBottom: index === 4 ? "transparent" : "",
                            }}
                          >
                            <div className="animate-pulse items-center">
                              <div className="my-0.5 h-4 bg-gray-300 rounded-md w-28"></div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : !isQueryingPapers && papers.length === 0 ? (
                      <TableRow>
                        <TableCell
                          className="cursor-auto"
                          style={{
                            padding: "4px 24px",
                            fontSize: "0.875rem",
                            fontFamily: "Inter",
                            height: "44px",
                            color: "#475467",
                            borderBottom: "transparent",
                          }}
                          colSpan={3}
                        >
                          <div className="flex items-center justify-center space-x-1">
                            <span className="text-gray-600">
                              No papers found.
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      papers.map((paper, index) => (
                        <TableRow
                          key={index}
                          onClick={() => openPaper(paper.url)}
                          className="bg-white hover:bg-gray-50 active:bg-gray-100 border-t border-[#e0e0e0] cursor-pointer"
                        >
                          <TableCell
                            style={{
                              padding: "4px 24px",
                              fontSize: "0.875rem",
                              fontFamily: "Inter",
                              height: "44px",
                              color: "#475467",
                              borderBottom: "transparent",
                            }}
                          >
                            {paper.title}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.875rem",
                              fontFamily: "Inter",
                              height: "44px",
                              color: "#475467",
                              borderBottom: "transparent",
                            }}
                          >
                            {paper.category}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.875rem",
                              fontFamily: "Inter",
                              height: "44px",
                              color: "#475467",
                              borderBottom: "transparent",
                            }}
                          >
                            {paper.publishedDate}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
