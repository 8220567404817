import { Storage } from "aws-amplify";
import * as BABYLONJS from "babylonjs";
import { STLExport } from "babylonjs-serializers";
import { ParameterSweepContextMenu } from "components/ContextMenus";
import Modal from "components/Modal";
import CustomisableTable, { ColumnProps } from "components/ParameterSweepTable";
import Mexp from "math-expression-evaluator";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "services/api";
import generateMeshService from "services/generateMesh.service";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { selectUsername } from "state/reducers/authSlice";
import {
  setMesh,
  setMeshXY,
  setMeshXZ,
  setMeshYZ,
} from "state/reducers/generatedMeshSlice";
import { addHistory } from "state/reducers/historySlice";
import { selectModels } from "state/reducers/modelSlice";
import { editParameter } from "state/reducers/parametersSlice";
import { selectProjectInfo } from "state/reducers/projectInfoSlice";
import { selectRefresh, setRefresh } from "state/reducers/refreshSlice";
import { selectSimulationProperties } from "state/reducers/simulationPropertiesSlice";
import { selectMaterials } from "state/reducers/userSlice";
import { calculate } from "utilities";
import { v4 as uuid } from "uuid";
import { hideMeshes, showMeshes } from "./tabbar/TabUtils";

export interface ParameterSweepProps {
  scene: BABYLON.Scene;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  parameterSweepParams: any[];
  parameterSweepCases: any[];
  setParameterSweepParams: (params: any[]) => void;
  setParameterSweepCases: (cases: any[]) => void;
}

function ParameterSweep({ scene, visible, setVisible }: ParameterSweepProps) {
  const dispatch = useAppDispatch();
  const currentUsername = useAppSelector(selectUsername);
  const isSaving = useAppSelector((state) => state.histories.saving);
  const parameters = useAppSelector((state) => state.parameters.parameters);
  const { projectId } = useParams();
  const parameterSweepCases = useAppSelector(
    (state) => state.parameters.parameterSweepCases
  );
  console.log("parameterSweepCases", parameterSweepCases);
  const parameterSweepParams = useAppSelector(
    (state) => state.parameters.parameterSweepParams
  );
  const [isCellEditingActive, setIsCellEditingActive] = useState(false);
  const [isSweeping, setIsSweeping] = useState(false);
  const refresh = useAppSelector(selectRefresh);
  const models = useAppSelector(selectModels);
  const modelsToDraw = Object.values(models);
  const arrayModel = modelsToDraw.flat() as any[];
  const materials = useAppSelector(selectMaterials);
  const simulationProperties = useAppSelector(selectSimulationProperties);
  const projectInfo = useAppSelector(selectProjectInfo).info;
  const mexp = new Mexp();

  const addCase = (e: any) => {
    const newRow: any = { id: uuid() };
    parameterSweepParams.forEach((param) => {
      newRow[param.id] = parameters.find(
        (parameter) => parameter.id === param.id
      )?.value;
    });

    console.log("adding case", newRow);

    return dispatch(
      addHistory({
        payloadData: {
          parameterSweep: {
            target: "case_add",
            ...newRow,
          },
        },
        currentUsername: currentUsername,
        projectId: projectId || "",
      })
    );
  };
  const editCase = (id: string, parameter: string, parameterValue: string) => {
    return dispatch(
      addHistory({
        payloadData: {
          parameterSweep: {
            target: "case_edit",
            id: id,
            parameter: parameter,
            parameterValue: parameterValue,
          },
        },
        currentUsername: currentUsername,
        projectId: projectId || "",
      })
    );
  };
  const removeCase = (id: string) => {
    return dispatch(
      addHistory({
        payloadData: {
          parameterSweep: {
            target: "case_remove",
            id: id,
          },
        },
        currentUsername: currentUsername,
        projectId: projectId || "",
      })
    );
  };

  const addParameter = (parameter: ColumnProps) => {
    console.log("adding param", parameter);
    dispatch(
      addHistory({
        payloadData: {
          parameterSweep: {
            target: "parameter_add",
            id: parameter.id,
            name: parameter.name,
          },
        },
        currentUsername: currentUsername,
        projectId: projectId || "",
      })
    );
  };
  const removeParameter = (parameter: ColumnProps) => {
    console.log("removing param", parameter);
    dispatch(
      addHistory({
        payloadData: {
          parameterSweep: {
            target: "parameter_remove",
            id: parameter.id,
            name: parameter.name,
          },
        },
        currentUsername: currentUsername,
        projectId: projectId || "",
      })
    );
  };

  const hasUniqueParams = (
    params: Array<{ [key: string]: string | number }>
  ): boolean => {
    const seenParams = new Set();

    for (const param of params) {
      const values = Object.keys(param)
        .filter((key) => key !== "id")
        .map((key) => parseFloat(param[key] as string));

      const valuesString = JSON.stringify(values);

      if (seenParams.has(valuesString)) {
        return false;
      }

      seenParams.add(valuesString);
    }

    return true;
  };

  const generateMesh = async (saveScreenshot?: boolean) => {
    const geometry: any = {};
    const ports: any = {};
    const elements: any = {};

    arrayModel.forEach((model) => {
      if (model.category === "Objects") {
        const mesh = scene.getMeshById(model.id);
        if (mesh !== null) {
          let meshString = STLExport.CreateSTL(
            [mesh as unknown as BABYLONJS.Mesh],
            false,
            mesh.id
          );

          const lines = meshString.split("\n");
          for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            if (line.startsWith("\t\t\tvertex")) {
              const components = line.trim().split(/\s+/);
              const y = components[2];
              const z = components[3];
              components[2] = z;
              components[3] = y;
              lines[i] = components.join(" ");
            }
          }

          meshString = lines.join("\n");

          if (materials) {
            geometry[model.id] = {
              shape: window.btoa(meshString),
              material: {
                epsilon: materials[model.material]?.epsilon,
                kappa: materials[model.material]?.kappa,
                mu: materials[model.material]?.mu,
              },
            };
          } else {
            geometry[model.id] = {
              shape: window.btoa(meshString),
              material: {
                epsilon: undefined,
                kappa: undefined,
                mu: undefined,
              },
            };
          }

          for (let i = 0; i < 100; i++) {
            scene.meshes.forEach(function (mesh) {
              if (
                mesh.name === "_meshLines" ||
                mesh.name === "_meshLinesSecondary"
              ) {
                if (mesh.parent) {
                  mesh.parent = null;
                }
                mesh.dispose();
              }
            });
          }
        } else {
          console.log("model", model);
          console.log("mesh is null so fuck it ig");
        }
      } else if (model.category === "Ports") {
        ports[model.name.split(" ")[1]] = {
          start: [
            parseFloat(calculate(model.object.x.min, parameters).toString()),
            parseFloat(calculate(model.object.y.min, parameters).toString()),
            parseFloat(calculate(model.object.z.min, parameters).toString()),
          ],
          stop: [
            parseFloat(calculate(model.object.x.max, parameters).toString()),
            parseFloat(calculate(model.object.y.max, parameters).toString()),
            parseFloat(calculate(model.object.z.max, parameters).toString()),
          ],
        };
      } else if (model.category === "Lumped Elements") {
        elements[model.name.split(" ")[1]] = {
          start: [
            parseFloat(calculate(model.object.x.min, parameters).toString()),
            parseFloat(calculate(model.object.y.min, parameters).toString()),
            parseFloat(calculate(model.object.z.min, parameters).toString()),
          ],
          stop: [
            parseFloat(calculate(model.object.x.max, parameters).toString()),
            parseFloat(calculate(model.object.y.max, parameters).toString()),
            parseFloat(calculate(model.object.z.max, parameters).toString()),
          ],
        };
      }
    });

    const meshSimulation = {
      mesh: {
        cpw_near: simulationProperties.cpw_near,
        cpw_far: simulationProperties.cpw_far,
        cpw_min: simulationProperties.cpw_min,
      },
      simulation: {
        frequencyUnit: simulationProperties.frequencyUnit,
        dimensionsUnit: simulationProperties.dimensionsUnit,
        f_min: simulationProperties.f_min,
        f_max: simulationProperties.f_max,
        pml_n: simulationProperties.pml_n,
        xMin: simulationProperties.xMin,
        xMax: simulationProperties.xMax,
        yMin: simulationProperties.yMin,
        yMax: simulationProperties.yMax,
        zMin: simulationProperties.zMin,
        zMax: simulationProperties.zMax,
        padding: simulationProperties.padding,
        ports: ports,
        elements: elements,
      },
    };

    const generationCall = await generateMeshService.generateMesh(
      currentUsername,
      projectId,
      geometry,
      meshSimulation,
      true
    );

    const responseData = generationCall.data;
    const x = responseData.mesh.x;
    const y = responseData.mesh.y;
    const z = responseData.mesh.z;
    dispatch(setMeshXY(z));
    dispatch(setMeshYZ(x));
    dispatch(setMeshXZ(y));
    dispatch(setMesh(responseData.mesh));

    const boundaries = {
      xMin: simulationProperties.xMin,
      xMax: simulationProperties.xMax,
      yMin: simulationProperties.yMin,
      yMax: simulationProperties.yMax,
      zMin: simulationProperties.zMin,
      zMax: simulationProperties.zMax,
    };

    let pml_n = simulationProperties.pml_n;
    const pml_opacity = 0;

    let lines;
    let pml_n_min;
    let pml_n_max;

    pml_n_min = boundaries.xMin === "PML" ? pml_n : 0;
    pml_n_max = boundaries.xMax === "PML" ? pml_n : 0;
    const xClean = x.slice(pml_n_min, x.length - pml_n_max);

    pml_n_min = boundaries.yMin === "PML" ? pml_n : 0;
    pml_n_max = boundaries.yMax === "PML" ? pml_n : 0;
    const yClean = y.slice(pml_n_min, y.length - pml_n_max);

    pml_n_min = boundaries.zMin === "PML" ? pml_n : 0;
    pml_n_max = boundaries.zMax === "PML" ? pml_n : 0;
    const zClean = z.slice(pml_n_min, z.length - pml_n_max);

    pml_n = 0;

    // Primary mesh lines

    // X (YZ plane)
    pml_n_min = boundaries.xMin === "PML" ? pml_n : 0;
    pml_n_max = boundaries.xMax === "PML" ? pml_n : 0;

    pml_n_min = 0;
    pml_n_max = 0;

    for (let i = 0; i < xClean.length; i++) {
      const isPML =
        (boundaries.xMin === "PML" && i < pml_n_min) ||
        (boundaries.xMax === "PML" && i >= xClean.length - pml_n_max);
      const colors = [
        new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
        new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
      ];

      const point1 = new BABYLON.Vector3(
        xClean[i],
        yClean[pml_n_min],
        zClean[pml_n_min]
      );
      const point2_max = new BABYLON.Vector3(
        xClean[i],
        yClean[pml_n_max],
        zClean[zClean.length - 1 - pml_n_max]
      );
      const point2_min = new BABYLON.Vector3(
        xClean[i],
        yClean[yClean.length - 1 - pml_n_max],
        zClean[pml_n_max]
      );

      lines = BABYLON.MeshBuilder.CreateLines(
        "_meshLines",
        { points: [point1, point2_max], colors: colors },
        scene
      );
      lines = BABYLON.MeshBuilder.CreateLines(
        "_meshLines",
        { points: [point1, point2_min], colors: colors },
        scene
      );
    }

    // Y (XZ plane)
    pml_n_min = boundaries.yMin === "PML" ? pml_n : 0;
    pml_n_max = boundaries.yMax === "PML" ? pml_n : 0;

    for (let i = 0; i < yClean.length; i++) {
      const isPML =
        (boundaries.yMin === "PML" && i < pml_n_min) ||
        (boundaries.yMax === "PML" && i >= yClean.length - pml_n_max);
      const colors = [
        new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
        new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
      ];

      const point1 = new BABYLON.Vector3(
        xClean[pml_n_min],
        yClean[i],
        zClean[pml_n_min]
      );
      const point2_max = new BABYLON.Vector3(
        xClean[pml_n_max],
        yClean[i],
        zClean[zClean.length - 1 - pml_n_max]
      );
      const point2_min = new BABYLON.Vector3(
        xClean[xClean.length - 1 - pml_n_max],
        yClean[i],
        zClean[pml_n_max]
      );

      lines = BABYLON.MeshBuilder.CreateLines(
        "_meshLines",
        { points: [point1, point2_max], colors: colors },
        scene
      );
      lines = BABYLON.MeshBuilder.CreateLines(
        "_meshLines",
        { points: [point1, point2_min], colors: colors },
        scene
      );
    }

    // Z (XY plane)
    pml_n_min = boundaries.zMin === "PML" ? pml_n : 0;
    pml_n_max = boundaries.zMax === "PML" ? pml_n : 0;

    for (let i = 0; i < zClean.length; i++) {
      const isPML =
        (boundaries.zMin === "PML" && i < pml_n_min) ||
        (boundaries.zMax === "PML" && i >= zClean.length - pml_n_max);
      const colors = [
        new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
        new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
      ];

      const point1 = new BABYLON.Vector3(
        xClean[pml_n_min],
        yClean[pml_n_min],
        zClean[i]
      );
      const point2_max = new BABYLON.Vector3(
        xClean[pml_n_max],
        yClean[yClean.length - 1 - pml_n_max],
        zClean[i]
      );
      const point2_min = new BABYLON.Vector3(
        xClean[xClean.length - 1 - pml_n_max],
        yClean[pml_n_max],
        zClean[i]
      );

      lines = BABYLON.MeshBuilder.CreateLines(
        "_meshLines",
        { points: [point1, point2_max], colors: colors },
        scene
      );
      lines = BABYLON.MeshBuilder.CreateLines(
        "_meshLines",
        { points: [point1, point2_min], colors: colors },
        scene
      );
    }

    // Secondary mesh lines

    // X (YZ plane)
    pml_n_min = boundaries.xMin === "PML" ? pml_n : 0;
    pml_n_max = boundaries.xMax === "PML" ? pml_n : 0;

    for (let i = 0; i < xClean.length; i++) {
      const isPML =
        (boundaries.xMin === "PML" && i < pml_n_min) ||
        (boundaries.xMax === "PML" && i >= xClean.length - pml_n_max);
      const colors = [
        new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
        new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
      ];

      const point2 = new BABYLON.Vector3(
        xClean[i],
        yClean[yClean.length - 1 - pml_n_max],
        zClean[zClean.length - 1 - pml_n_max]
      );
      const point1_max = new BABYLON.Vector3(
        xClean[i],
        yClean[yClean.length - 1 - pml_n_max],
        zClean[pml_n_min]
      );
      const point1_min = new BABYLON.Vector3(
        xClean[i],
        yClean[pml_n_min],
        zClean[zClean.length - 1 - pml_n_max]
      );

      lines = BABYLON.MeshBuilder.CreateLines(
        "_meshLinesSecondary",
        { points: [point1_max, point2], colors: colors },
        scene
      );
      lines = BABYLON.MeshBuilder.CreateLines(
        "_meshLinesSecondary",
        { points: [point1_min, point2], colors: colors },
        scene
      );
    }

    // Y (XZ plane)
    pml_n_min = boundaries.yMin === "PML" ? pml_n : 0;
    pml_n_max = boundaries.yMax === "PML" ? pml_n : 0;

    for (let i = 0; i < yClean.length; i++) {
      const isPML =
        (boundaries.yMin === "PML" && i < pml_n_min) ||
        (boundaries.yMax === "PML" && i >= yClean.length - pml_n_max);
      const colors = [
        new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
        new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
      ];

      const point2 = new BABYLON.Vector3(
        xClean[xClean.length - 1 - pml_n_max],
        yClean[i],
        zClean[zClean.length - 1 - pml_n_max]
      );
      const point1_max = new BABYLON.Vector3(
        xClean[xClean.length - 1 - pml_n_max],
        yClean[i],
        zClean[pml_n_min]
      );
      const point1_min = new BABYLON.Vector3(
        xClean[pml_n_min],
        yClean[i],
        zClean[zClean.length - 1 - pml_n_max]
      );

      lines = BABYLON.MeshBuilder.CreateLines(
        "_meshLinesSecondary",
        { points: [point1_max, point2], colors: colors },
        scene
      );
      lines = BABYLON.MeshBuilder.CreateLines(
        "_meshLinesSecondary",
        { points: [point1_min, point2], colors: colors },
        scene
      );
    }

    // Z (XY plane)
    pml_n_min = boundaries.zMin === "PML" ? pml_n : 0;
    pml_n_max = boundaries.zMax === "PML" ? pml_n : 0;

    for (let i = 0; i < zClean.length; i++) {
      const isPML =
        (boundaries.zMin === "PML" && i < pml_n_min) ||
        (boundaries.zMax === "PML" && i >= zClean.length - pml_n_max);
      const colors = [
        new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
        new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
      ];

      const point2 = new BABYLON.Vector3(
        xClean[xClean.length - 1 - pml_n_max],
        yClean[yClean.length - 1 - pml_n_max],
        zClean[i]
      );
      const point1_max = new BABYLON.Vector3(
        xClean[xClean.length - 1 - pml_n_max],
        yClean[pml_n_min],
        zClean[i]
      );
      const point1_min = new BABYLON.Vector3(
        xClean[pml_n_min],
        yClean[yClean.length - 1 - pml_n_max],
        zClean[i]
      );

      lines = BABYLON.MeshBuilder.CreateLines(
        "_meshLinesSecondary",
        { points: [point1_max, point2], colors: colors },
        scene
      );
      lines = BABYLON.MeshBuilder.CreateLines(
        "_meshLinesSecondary",
        { points: [point1_min, point2], colors: colors },
        scene
      );
    }

    // Hide secondary mesh lines
    scene.meshes.forEach((mesh: any) => {
      if (mesh.name === "_meshLinesSecondary") {
        mesh.visibility = 0;
      }
    });

    // Hide primary mesh lines
    scene.meshes.forEach((mesh: any) => {
      if (mesh.name === "_meshLines") {
        mesh.visibility = 0;
      }
    });

    return responseData.mesh;
  };

  const makeCaseData = async (
    simulationMesh: any,
    takeScreenshot?: boolean
  ) => {
    let geometry: any = {};
    let ports: any = {};
    let elements: any = {};

    arrayModel.forEach((model) => {
      if (model.category === "Objects") {
        const mesh = scene.getMeshById(model.id);
        if (mesh !== null) {
          let meshString = BABYLON.STLExport.CreateSTL(
            [mesh as unknown as BABYLON.Mesh],
            false,
            mesh.id
          );

          const lines = meshString.split("\n");
          for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            if (line.startsWith("\t\t\tvertex")) {
              const components = line.split(" ");
              const y = components[2];
              const z = components[3];
              components[2] = z;
              components[3] = y;
              lines[i] = components.join(" ");
            }
          }

          meshString = lines.join("\n");
          if (materials) {
            geometry[model.name + "_" + uuid()] = {
              shape: window.btoa(meshString),
              material: {
                epsilon: materials[model.material]?.epsilon,
                kappa: materials[model.material]?.kappa,
                mu: materials[model.material]?.mu,
              },
            };
          }
        }
      } else if (model.category === "Ports") {
        ports[model.name.split(" ")[1]] = {
          start: [
            parseFloat(calculate(model.object.x.min, parameters).toString()),
            parseFloat(calculate(model.object.y.min, parameters).toString()),
            parseFloat(calculate(model.object.z.min, parameters).toString()),
          ],
          stop: [
            parseFloat(calculate(model.object.x.max, parameters).toString()),
            parseFloat(calculate(model.object.y.max, parameters).toString()),
            parseFloat(calculate(model.object.z.max, parameters).toString()),
          ],
          impedance: parseFloat(
            calculate(model.object.impedance, parameters).toString()
          ),
          amplitude: parseFloat(
            calculate(model.object.amplitude, parameters).toString()
          ),
          phase_shift: parseFloat(
            calculate(model.object.phase_shift, parameters).toString()
          ),
          f_ref: parseFloat(
            calculate(model.object.f_ref, parameters).toString()
          ),
        };
      } else if (model.category === "Lumped Elements") {
        elements[model.name.split(" ")[1]] = {
          start: [
            parseFloat(calculate(model.object.x.min, parameters).toString()),
            parseFloat(calculate(model.object.y.min, parameters).toString()),
            parseFloat(calculate(model.object.z.min, parameters).toString()),
          ],
          stop: [
            parseFloat(calculate(model.object.x.max, parameters).toString()),
            parseFloat(calculate(model.object.y.max, parameters).toString()),
            parseFloat(calculate(model.object.z.max, parameters).toString()),
          ],
          element_type: model.object.element_type,
          resistance: parseFloat(
            calculate(model.object.resistance, parameters).toString()
          ),
          inductance: parseFloat(
            calculate(model.object.inductance, parameters).toString()
          ),
          capacitance: parseFloat(
            calculate(model.object.capacitance, parameters).toString()
          ),
        };
      }
    });

    let data: {
      geometry: any;
      mesh: any;
      simulation: any;
      screenshot?: string;
    } = {
      geometry: geometry,
      mesh: simulationMesh,
      simulation: {
        ...simulationProperties,
        ports: ports,
        elements: elements,
      },
    };

    if (takeScreenshot) {
      // Take screenshot
      const engine = scene.getEngine();

      let ground = scene.getMeshByName("_ground");
      let meshLines = scene.getMeshByName("_meshLines");
      let meshLinesSecondary = scene.getMeshByName("_meshLinesSecondary");
      let distance = scene.getMeshByName("Distance ");
      let textPlane = scene.getMeshByName("TextPlane");

      let groundVisibility = ground?.visibility;
      console.log(12345, ground, groundVisibility);
      let meshLinesVisibility = meshLines?.visibility;
      let meshLinesSecondaryVisibility = meshLinesSecondary?.visibility;
      let distanceVisibility = distance?.visibility;
      let textPlaneVisibility = textPlane?.visibility;

      hideMeshes("_ground", scene);
      hideMeshes("_meshLines", scene);
      hideMeshes("_meshLinesSecondary", scene);
      hideMeshes("Distance ", scene);
      hideMeshes("TextPlane", scene);

      let boundingBox = new BABYLON.BoundingBox(
        BABYLON.Vector3.Zero(),
        BABYLON.Vector3.Zero()
      );
      let maxDim = 0;
      if (scene.meshes.length > 0) {
        let minBounding =
          scene.meshes[0].getBoundingInfo().boundingBox.minimumWorld;
        let maxBounding =
          scene.meshes[0].getBoundingInfo().boundingBox.maximumWorld;
        scene.meshes.slice(1).forEach((mesh) => {
          const minBound = mesh.getBoundingInfo().boundingBox.minimumWorld;
          const maxBound = mesh.getBoundingInfo().boundingBox.maximumWorld;
          minBounding.minimizeInPlace(minBound);
          maxBounding.maximizeInPlace(maxBound);
        });
        boundingBox = new BABYLON.BoundingBox(minBounding, maxBounding);
        const xDim = boundingBox.maximumWorld.x - boundingBox.minimumWorld.x;
        const yDim = boundingBox.maximumWorld.y - boundingBox.minimumWorld.y;
        const zDim = boundingBox.maximumWorld.z - boundingBox.minimumWorld.z;
        maxDim = Math.max(xDim, yDim, zDim);
      }
      const camera = scene?.activeCamera as BABYLON.ArcRotateCamera;
      const screenshotCamera = camera.clone(
        "screenshotCamera"
      ) as BABYLON.ArcRotateCamera;
      screenshotCamera.target = new BABYLON.Vector3(
        (boundingBox.minimumWorld.x + boundingBox.maximumWorld.x) / 2,
        (boundingBox.minimumWorld.y + boundingBox.maximumWorld.y) / 2,
        (boundingBox.minimumWorld.z + boundingBox.maximumWorld.z) / 2
      );
      screenshotCamera.radius = maxDim * 1.2;
      screenshotCamera.alpha = Math.PI / 3.5;
      screenshotCamera.beta = Math.PI / 2.7;
      for (let i = 0; i < 100; i++) {
        scene.meshes.forEach(function (mesh) {
          if (
            mesh.name === "_meshLines" ||
            mesh.name === "_meshLinesSecondary"
          ) {
            if (mesh.parent) {
              mesh.parent = null;
            }
            mesh.dispose();
          }
        });
      }

      await new Promise((resolve) => {
        return BABYLON.Tools.CreateScreenshotUsingRenderTarget(
          engine,
          screenshotCamera,
          2000,
          async function (dataURI) {
            const x = simulationMesh.x;
            const y = simulationMesh.y;
            const z = simulationMesh.z;

            const boundaries = {
              xMin: simulationProperties.xMin,
              xMax: simulationProperties.xMax,
              yMin: simulationProperties.yMin,
              yMax: simulationProperties.yMax,
              zMin: simulationProperties.zMin,
              zMax: simulationProperties.zMax,
            };

            let pml_n = simulationProperties.pml_n;
            const pml_opacity = 0;

            let lines;
            let pml_n_min;
            let pml_n_max;

            pml_n_min = boundaries.xMin === "PML" ? pml_n : 0;
            pml_n_max = boundaries.xMax === "PML" ? pml_n : 0;
            const xClean = x.slice(pml_n_min, x.length - pml_n_max);

            pml_n_min = boundaries.yMin === "PML" ? pml_n : 0;
            pml_n_max = boundaries.yMax === "PML" ? pml_n : 0;
            const yClean = y.slice(pml_n_min, y.length - pml_n_max);

            pml_n_min = boundaries.zMin === "PML" ? pml_n : 0;
            pml_n_max = boundaries.zMax === "PML" ? pml_n : 0;
            const zClean = z.slice(pml_n_min, z.length - pml_n_max);

            pml_n = 0;

            // Primary mesh lines

            // X (YZ plane)
            pml_n_min = boundaries.xMin === "PML" ? pml_n : 0;
            pml_n_max = boundaries.xMax === "PML" ? pml_n : 0;

            pml_n_min = 0;
            pml_n_max = 0;

            for (let i = 0; i < xClean.length; i++) {
              const isPML =
                (boundaries.xMin === "PML" && i < pml_n_min) ||
                (boundaries.xMax === "PML" && i >= xClean.length - pml_n_max);
              const colors = [
                new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
                new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
              ];

              const point1 = new BABYLON.Vector3(
                xClean[i],
                yClean[pml_n_min],
                zClean[pml_n_min]
              );
              const point2_max = new BABYLON.Vector3(
                xClean[i],
                yClean[pml_n_max],
                zClean[zClean.length - 1 - pml_n_max]
              );
              const point2_min = new BABYLON.Vector3(
                xClean[i],
                yClean[yClean.length - 1 - pml_n_max],
                zClean[pml_n_max]
              );

              lines = BABYLON.MeshBuilder.CreateLines(
                "_meshLines",
                { points: [point1, point2_max], colors: colors },
                scene
              );
              lines = BABYLON.MeshBuilder.CreateLines(
                "_meshLines",
                { points: [point1, point2_min], colors: colors },
                scene
              );
            }

            // Y (XZ plane)
            pml_n_min = boundaries.yMin === "PML" ? pml_n : 0;
            pml_n_max = boundaries.yMax === "PML" ? pml_n : 0;

            for (let i = 0; i < yClean.length; i++) {
              const isPML =
                (boundaries.yMin === "PML" && i < pml_n_min) ||
                (boundaries.yMax === "PML" && i >= yClean.length - pml_n_max);
              const colors = [
                new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
                new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
              ];

              const point1 = new BABYLON.Vector3(
                xClean[pml_n_min],
                yClean[i],
                zClean[pml_n_min]
              );
              const point2_max = new BABYLON.Vector3(
                xClean[pml_n_max],
                yClean[i],
                zClean[zClean.length - 1 - pml_n_max]
              );
              const point2_min = new BABYLON.Vector3(
                xClean[xClean.length - 1 - pml_n_max],
                yClean[i],
                zClean[pml_n_max]
              );

              lines = BABYLON.MeshBuilder.CreateLines(
                "_meshLines",
                { points: [point1, point2_max], colors: colors },
                scene
              );
              lines = BABYLON.MeshBuilder.CreateLines(
                "_meshLines",
                { points: [point1, point2_min], colors: colors },
                scene
              );
            }

            // Z (XY plane)
            pml_n_min = boundaries.zMin === "PML" ? pml_n : 0;
            pml_n_max = boundaries.zMax === "PML" ? pml_n : 0;

            for (let i = 0; i < zClean.length; i++) {
              const isPML =
                (boundaries.zMin === "PML" && i < pml_n_min) ||
                (boundaries.zMax === "PML" && i >= zClean.length - pml_n_max);
              const colors = [
                new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
                new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
              ];

              const point1 = new BABYLON.Vector3(
                xClean[pml_n_min],
                yClean[pml_n_min],
                zClean[i]
              );
              const point2_max = new BABYLON.Vector3(
                xClean[pml_n_max],
                yClean[yClean.length - 1 - pml_n_max],
                zClean[i]
              );
              const point2_min = new BABYLON.Vector3(
                xClean[xClean.length - 1 - pml_n_max],
                yClean[pml_n_max],
                zClean[i]
              );

              lines = BABYLON.MeshBuilder.CreateLines(
                "_meshLines",
                { points: [point1, point2_max], colors: colors },
                scene
              );
              lines = BABYLON.MeshBuilder.CreateLines(
                "_meshLines",
                { points: [point1, point2_min], colors: colors },
                scene
              );
            }

            // Secondary mesh lines

            // X (YZ plane)
            pml_n_min = boundaries.xMin === "PML" ? pml_n : 0;
            pml_n_max = boundaries.xMax === "PML" ? pml_n : 0;

            for (let i = 0; i < xClean.length; i++) {
              const isPML =
                (boundaries.xMin === "PML" && i < pml_n_min) ||
                (boundaries.xMax === "PML" && i >= xClean.length - pml_n_max);
              const colors = [
                new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
                new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
              ];

              const point2 = new BABYLON.Vector3(
                xClean[i],
                yClean[yClean.length - 1 - pml_n_max],
                zClean[zClean.length - 1 - pml_n_max]
              );
              const point1_max = new BABYLON.Vector3(
                xClean[i],
                yClean[yClean.length - 1 - pml_n_max],
                zClean[pml_n_min]
              );
              const point1_min = new BABYLON.Vector3(
                xClean[i],
                yClean[pml_n_min],
                zClean[zClean.length - 1 - pml_n_max]
              );

              lines = BABYLON.MeshBuilder.CreateLines(
                "_meshLinesSecondary",
                { points: [point1_max, point2], colors: colors },
                scene
              );
              lines = BABYLON.MeshBuilder.CreateLines(
                "_meshLinesSecondary",
                { points: [point1_min, point2], colors: colors },
                scene
              );
            }

            // Y (XZ plane)
            pml_n_min = boundaries.yMin === "PML" ? pml_n : 0;
            pml_n_max = boundaries.yMax === "PML" ? pml_n : 0;

            for (let i = 0; i < yClean.length; i++) {
              const isPML =
                (boundaries.yMin === "PML" && i < pml_n_min) ||
                (boundaries.yMax === "PML" && i >= yClean.length - pml_n_max);
              const colors = [
                new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
                new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
              ];

              const point2 = new BABYLON.Vector3(
                xClean[xClean.length - 1 - pml_n_max],
                yClean[i],
                zClean[zClean.length - 1 - pml_n_max]
              );
              const point1_max = new BABYLON.Vector3(
                xClean[xClean.length - 1 - pml_n_max],
                yClean[i],
                zClean[pml_n_min]
              );
              const point1_min = new BABYLON.Vector3(
                xClean[pml_n_min],
                yClean[i],
                zClean[zClean.length - 1 - pml_n_max]
              );

              lines = BABYLON.MeshBuilder.CreateLines(
                "_meshLinesSecondary",
                { points: [point1_max, point2], colors: colors },
                scene
              );
              lines = BABYLON.MeshBuilder.CreateLines(
                "_meshLinesSecondary",
                { points: [point1_min, point2], colors: colors },
                scene
              );
            }

            // Z (XY plane)
            pml_n_min = boundaries.zMin === "PML" ? pml_n : 0;
            pml_n_max = boundaries.zMax === "PML" ? pml_n : 0;

            for (let i = 0; i < zClean.length; i++) {
              const isPML =
                (boundaries.zMin === "PML" && i < pml_n_min) ||
                (boundaries.zMax === "PML" && i >= zClean.length - pml_n_max);
              const colors = [
                new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
                new BABYLON.Color4(1, 0, 0, isPML ? pml_opacity : 1),
              ];

              const point2 = new BABYLON.Vector3(
                xClean[xClean.length - 1 - pml_n_max],
                yClean[yClean.length - 1 - pml_n_max],
                zClean[i]
              );
              const point1_max = new BABYLON.Vector3(
                xClean[xClean.length - 1 - pml_n_max],
                yClean[pml_n_min],
                zClean[i]
              );
              const point1_min = new BABYLON.Vector3(
                xClean[pml_n_min],
                yClean[yClean.length - 1 - pml_n_max],
                zClean[i]
              );

              lines = BABYLON.MeshBuilder.CreateLines(
                "_meshLinesSecondary",
                { points: [point1_max, point2], colors: colors },
                scene
              );
              lines = BABYLON.MeshBuilder.CreateLines(
                "_meshLinesSecondary",
                { points: [point1_min, point2], colors: colors },
                scene
              );
            }

            // Hide secondary mesh lines
            scene.meshes.forEach((mesh: any) => {
              if (mesh.name === "_meshLinesSecondary") {
                mesh.visibility = 0;
              }
            });

            // Hide primary mesh lines
            scene.meshes.forEach((mesh: any) => {
              if (mesh.name === "_meshLines") {
                mesh.visibility = 0;
              }
            });

            if (
              ground &&
              groundVisibility !== undefined &&
              groundVisibility === 1
            )
              showMeshes("_ground", scene);
            if (
              meshLines &&
              meshLinesVisibility !== undefined &&
              meshLinesVisibility === 1
            )
              showMeshes("_meshLines", scene);
            if (
              meshLinesSecondary &&
              meshLinesSecondaryVisibility !== undefined &&
              meshLinesSecondaryVisibility === 1
            )
              showMeshes("_meshLinesSecondary", scene);
            if (
              distance &&
              distanceVisibility !== undefined &&
              distanceVisibility === 1
            )
              showMeshes("Distance ", scene);
            if (
              textPlane &&
              textPlaneVisibility !== undefined &&
              textPlaneVisibility === 1
            )
              showMeshes("TextPlane", scene);

            data["screenshot"] = dataURI;

            resolve(dataURI);
          }
        );
      });
    }

    return data;
  };

  const updateScene = async (n = 0) => {
    // TODO: Improve this function to actually be dynamic!!!
    dispatch(setRefresh({ refresh: refresh + 1 + n }));
    await new Promise((resolve) => setTimeout(resolve, 2000));
  };

  // For cleanly hiding the menu
  useEffect(() => {
    if (
      (isSweeping && projectInfo.status?.includes("Initializing compute")) ||
      (projectInfo.status?.includes("#") &&
        !projectInfo.status?.includes("Completed"))
    ) {
      setVisible(false);
    }
  }, [isSweeping, projectInfo.status, setVisible]);

  const handleOk = async () => {
    setIsSweeping(true);

    const initialParameters = [...parameters];

    const merged: { [key: string]: any } = {};

    for (const row of parameterSweepCases) {
      const idx = parameterSweepCases.indexOf(row);

      const newParams = parameters
        .map((param) => {
          if (Object.keys(row).includes(param.id)) {
            return {
              ...param,
              expression: row[param.id].toString(),
              value: mexp.eval(row[param.id].toString()).toString(),
            };
          }

          return param;
        })
        .filter((param) => parameterSweepParams.find((p) => p.id === param.id)); // check that parameter is selected

      newParams.forEach((param) => {
        let value = mexp.eval(param.expression);
        dispatch(
          editParameter({
            ...param,
            value: value,
          })
        );
      });

      await dispatch(
        addHistory({
          payloadData: {
            edit_parameter: newParams,
          },
          currentUsername: currentUsername,
          projectId: projectId || "",
        })
      );

      updateScene(idx);

      const mesh = await generateMesh();
      const caseData = await makeCaseData(mesh, idx === 0);

      const paramsKey = Object.keys(row)
        .filter((k) => k !== "id")
        .map(
          (id) =>
            parameters.find((param) => param.id === id)?.name +
            "=" +
            row[id].toString()
        )
        .join(",");

      merged[paramsKey] = caseData;
    }

    await dispatch(
      addHistory({
        payloadData: {
          edit_parameter: initialParameters,
        },
        currentUsername: currentUsername,
        projectId: projectId || "",
      })
    );

    initialParameters.forEach((param) => {
      dispatch(editParameter(param));
    });

    await dispatch(
      setRefresh({ refresh: refresh + 1 + parameterSweepCases.length })
    );

    console.log("merged", merged);

    await Storage.put(
      `${currentUsername}/projects/${projectId}/case.json`,
      merged,
      {
        contentType: "application/json",
      }
    );

    await api.post("/run_simulation", { projectId });

    setVisible(false); // close the modal before the sim starts

    setIsSweeping(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      title={"Parameter Sweep"}
      topRight={
        <ParameterSweepContextMenu
          className="ml-auto"
          parameters={parameters.map((param) => ({
            id: param.id,
            name: param.name,
            isSelected: parameterSweepParams.some((p) => p.id === param.id),
          }))}
          addColumn={addParameter}
          removeColumn={removeParameter}
        />
      }
      buttons={
        <>
          <button
            type="button"
            id="element-ok-btn"
            className="relative w-28 h-9 flex items-center justify-center shadow-sm ml-2 my-auto font-medium text-sm rounded-md focus:outline-none text-white bg-primary-600 hover:bg-primary-700 active:bg-primary-800  enabled:hover:shadow-primary-600/50 disabled:bg-primary-300 hover:transition duration-150"
            onClick={handleOk}
            disabled={
              isSweeping ||
              isCellEditingActive ||
              isSaving ||
              !parameterSweepCases.length ||
              !hasUniqueParams(parameterSweepCases)
            }
          >
            {isSweeping || isSaving ? (
              <svg
                aria-hidden="true"
                className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            ) : (
              "Start Sweep"
            )}
          </button>
          <button
            type="button"
            className="relative h-9 items-center ml-2 my-auto mt-3 inline-flex w-[72px] justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 enabled:hover:bg-gray-50 enabled:active:bg-gray-100 sm:mt-0 disabled:opacity-50"
            onClick={handleCancel}
            disabled={isSweeping || isCellEditingActive || isSaving}
          >
            Close
          </button>
        </>
      }
    >
      <form className="pt-4 mx-2">
        <fieldset disabled={isSweeping}>
          <CustomisableTable
            data={parameterSweepCases.map((row) => {
              let newRow = { ...row };
              parameterSweepParams.forEach((param) => {
                if (!(param.id in row)) {
                  newRow[param.id] = parameters.find(
                    (parameter) => parameter.id === param.id
                  )?.value;
                }
              });

              return newRow;
            })}
            columns={parameterSweepParams}
            addCase={addCase}
            removeCase={removeCase}
            editCase={editCase}
            setIsEditing={setIsCellEditingActive}
            inputType={"number"}
            disabled={isSweeping}
          />
        </fieldset>
      </form>
    </Modal>
  );
}

export default ParameterSweep;
