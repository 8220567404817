import * as BABYLON from "babylonjs";
import { GizmoManager } from "babylonjs";
import classNames from "classnames";
import DraggableModal from "components/DraggableModal";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { selectUsername } from "state/reducers/authSlice";
import { setPopupOpen } from "state/reducers/generalSlice";
import { addHistory } from "state/reducers/historySlice";
import {
  modelAdded,
  modelAltered,
  modelRemoved,
  selectFirstSelected,
  selectModels,
} from "state/reducers/modelSlice";
import { selectParameters } from "state/reducers/parametersSlice";
import { selectRefresh } from "state/reducers/refreshSlice";
import {
  calculate,
  centralPos,
  isParameter,
  replaceParametersToIds,
  round,
} from "utilities";
import { v4 as uuid } from "uuid";

export interface TranslateProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  mainScene: BABYLON.Scene | any;
}

let gizmoManager: GizmoManager | null = null;

function TranslateMenu({ visible, setVisible, mainScene }: TranslateProps) {
  const [xAxis, setXAxis] = useState("0");
  const [yAxis, setYAxis] = useState("0");
  const [zAxis, setZAxis] = useState("0");
  const [factor, setFactor] = useState("1");
  const [isCanCopy, setIsCanCopy] = useState(false);
  const [isPositionChanged, setIsPositionChanged] = useState(false);

  const models = useAppSelector(selectModels);
  const selectedModels = models.filter((model) => model.selected);
  const refresh = useAppSelector(selectRefresh);
  const modelsToDraw = Object.values(models);
  const arrayModel = modelsToDraw.flat();
  const dispatch = useAppDispatch();
  const firstSelected: any = useAppSelector(selectFirstSelected);
  const parameters = useAppSelector(selectParameters);
  const username = useAppSelector(selectUsername);
  const { projectId } = useParams<{ projectId: string }>();

  const [isValid, setIsValid] = useState(true);
  const saving = useAppSelector((state) => state.histories.saving);
  const [previewModels, setPreviewModels] = useState<any[]>([]);

  useEffect(() => {
    if (visible) {
      dispatch(setPopupOpen(true));
    } else if (!visible) {
      dispatch(setPopupOpen(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, dispatch]);

  const validateFields = () => {
    try {
      if (
        isNaN(
          parseFloat(
            calculate(
              replaceParametersToIds(xAxis, parameters),
              parameters
            ).toString()
          )
        ) ||
        isNaN(
          parseFloat(
            calculate(
              replaceParametersToIds(yAxis, parameters),
              parameters
            ).toString()
          )
        ) ||
        isNaN(
          parseFloat(
            calculate(
              replaceParametersToIds(zAxis, parameters),
              parameters
            ).toString()
          )
        ) ||
        (isCanCopy &&
          (isNaN(
            parseFloat(
              calculate(
                replaceParametersToIds(factor, parameters),
                parameters
              ).toString()
            )
          ) ||
            !Number.isInteger(
              parseFloat(
                calculate(
                  replaceParametersToIds(factor, parameters),
                  parameters
                ).toString()
              )
            ) ||
            parseFloat(
              calculate(
                replaceParametersToIds(factor, parameters),
                parameters
              ).toString()
            ) < 1))
      ) {
        setIsValid(false);
        return;
      }

      setIsValid(true);
    } catch (error) {
      setIsValid(false);
    }
  };

  useEffect(() => {
    validateFields();
  }, [xAxis, yAxis, zAxis, isCanCopy, factor, parameters, isPositionChanged]);

  useEffect(() => {
    // Initialize GizmoManager here
    if (mainScene && !gizmoManager && visible) {
      gizmoManager = new GizmoManager(mainScene);
    }
  }, [mainScene, visible]);

  useEffect(() => {
    removePreviews();
    if (!visible) {
      // Clear the gizmos when the menu is hidden
      if (gizmoManager) {
        gizmoManager.positionGizmoEnabled = false;
        gizmoManager.rotationGizmoEnabled = false;
        gizmoManager.scaleGizmoEnabled = false;
        gizmoManager.attachedMesh?.dispose();
        gizmoManager.attachToMesh(null);
      }
      return;
    }

    const keyDownFunc = (event: any) => {
      if (event.key === "Escape") {
        document.getElementById("translate-cancel-btn")?.click();
        document.removeEventListener("keydown", keyDownFunc);
      } else if (event.key === "Enter") {
        document.getElementById("translate-ok-btn")?.click();
        document.removeEventListener("keydown", keyDownFunc);
      }
    };
    document.addEventListener("keydown", keyDownFunc);

    let previewModelsArray = selectedModels.map((model) => {
      const newModel: any = {
        ...model,
        category: "Preview",
        name: model.name,
        id: "Preview-" + uuid(),
        selected: false,
        status: "Added",
        position: {
          x: model.position.x,
          y: model.position.y,
          z: model.position.z,
        },
      };

      dispatch(modelAdded(newModel));

      return newModel;
    });

    setPreviewModels(previewModelsArray);

    if (gizmoManager) {
      gizmoManager.usePointerToAttachGizmos = false;
      gizmoManager.positionGizmoEnabled = true;

      // place a position gizmo at 0,0,0
      const parent = new BABYLON.Mesh("gizmoParent", mainScene);
      parent.setBoundingInfo(
        new BABYLON.BoundingInfo(
          new BABYLON.Vector3(0, 0, 0),
          new BABYLON.Vector3(0, 0, 0)
        )
      );
      parent.position = new BABYLON.Vector3(
        centralPos(selectedModels, mainScene).x,
        centralPos(selectedModels, mainScene).y,
        centralPos(selectedModels, mainScene).z
      );

      gizmoManager.attachToMesh(parent);

      const basePosition = parent.position.clone();

      gizmoManager.gizmos.positionGizmo?.xGizmo.dragBehavior.onDragObservable.add(
        (e) => {
          let m: any = gizmoManager?.gizmos.positionGizmo?.attachedMesh;
          setXAxis(round(m.position.x - basePosition.x).toString());
          setYAxis(round(m.position.y - basePosition.y).toString());
          setZAxis(round(m.position.z - basePosition.z).toString());
        }
      );
      gizmoManager.gizmos.positionGizmo?.yGizmo.dragBehavior.onDragObservable.add(
        (e) => {
          let m: any = gizmoManager?.gizmos.positionGizmo?.attachedMesh;
          setXAxis(round(m.position.x - basePosition.x).toString());
          setYAxis(round(m.position.y - basePosition.y).toString());
          setZAxis(round(m.position.z - basePosition.z).toString());
        }
      );
      gizmoManager.gizmos.positionGizmo?.zGizmo.dragBehavior.onDragObservable.add(
        (e) => {
          let m: any = gizmoManager?.gizmos.positionGizmo?.attachedMesh;
          setXAxis(round(m.position.x - basePosition.x).toString());
          setYAxis(round(m.position.y - basePosition.y).toString());
          setZAxis(round(m.position.z - basePosition.z).toString());
        }
      );
    }
  }, [visible]);

  const removePreviews = () => {
    const previewModels = models.filter(
      (m) => m.category.toLowerCase() === "preview"
    );
    previewModels.forEach((model) => {
      dispatch(modelRemoved(model.id));
    });
  };

  const handlePreview = () => {
    const factorValue = isCanCopy
      ? calculate(replaceParametersToIds(factor, parameters), parameters) || 1
      : 1;
    const xValue = calculate(
      replaceParametersToIds(xAxis, parameters),
      parameters
    );
    const yValue = calculate(
      replaceParametersToIds(yAxis, parameters),
      parameters
    );
    const zValue = calculate(
      replaceParametersToIds(zAxis, parameters),
      parameters
    );

    const previewMeshes = mainScene.meshes.filter((mesh: BABYLON.Mesh) =>
      mesh.id.startsWith("Preview")
    );
    previewMeshes.forEach((mesh: BABYLON.Mesh) => {
      if (
        !previewModels.find((m) => m.id === mesh.id) ||
        mesh.id.startsWith("PreviewTemp")
      ) {
        console.log("it happened");
        mesh.dispose();
      }
    });

    // clear temp previews
    models.forEach((model) => {
      if (model.id.startsWith("PreviewTemp")) {
        dispatch(modelRemoved(model.id));
      }
    });

    previewModels.forEach((model) => {
      const newModel: any = {
        ...model,
        position: {
          x: model.position.x + xValue,
          // calculate(replaceParametersToIds(xAxis, parameters), parameters),
          y: model.position.y + yValue,
          // calculate(replaceParametersToIds(yAxis, parameters), parameters),
          z: model.position.z + zValue,
          // calculate(replaceParametersToIds(zAxis, parameters), parameters),
        },
      };

      dispatch(modelAltered(newModel));
    });

    for (let i = 2; i < factorValue + 1; i++) {
      previewModels.forEach((model) => {
        const newModel: any = {
          ...model,
          id: "PreviewTemp-" + uuid(),
          position: {
            x: model.position.x + xValue * i,
            y: model.position.y + yValue * i,
            z: model.position.z + zValue * i,
          },
        };

        dispatch(modelAdded(newModel));
      });
    }
  };

  const handleCancel = () => {
    removePreviews();
    setVisible(false);
  };

  const handleOk = async () => {
    removePreviews();
    const factorValue = isCanCopy ? parseFloat(factor) || 1 : 1;

    const idArray: string[] = [];

    if (isCanCopy) {
      for (let i = 1; i < factorValue + 1; i++) {
        selectedModels.forEach(async (model) => {
          const mesh = mainScene.getMeshByID(model.id);
          const newModel = {
            ...model,
            name: model.name,
            id: uuid(),
            selected: false,
            status: "Added",
            position: {
              x:
                (model.position ? model.position.x : mesh.position.x) +
                calculate(
                  replaceParametersToIds(xAxis, parameters),
                  parameters
                ) *
                  i,
              y:
                (model.position ? model.position.y : mesh.position.y) +
                calculate(
                  replaceParametersToIds(yAxis, parameters),
                  parameters
                ) *
                  i,
              z:
                (model.position ? model.position.z : mesh.position.z) +
                calculate(
                  replaceParametersToIds(zAxis, parameters),
                  parameters
                ) *
                  i,
            },
          };
          console.log("copied model", newModel);
          dispatch(modelAdded(newModel));
          idArray.push(newModel.id);
        });
      }
    }

    selectedModels.forEach(async (model) => {
      await dispatch(
        addHistory({
          payloadData: {
            translate: {
              name: model.name,
              id: model.id,
              xAxis: replaceParametersToIds(xAxis, parameters),
              yAxis: replaceParametersToIds(yAxis, parameters),
              zAxis: replaceParametersToIds(zAxis, parameters),
              factor: replaceParametersToIds(factor, parameters),
              idArray: isCanCopy ? idArray : undefined,
              isCanCopy,
            },
          },
          currentUsername: username,
          projectId: projectId || "",
        })
      );
    });
    // dispatch(setRefresh({ refresh: refresh + 1 }));

    setVisible(false);
  };

  const [updateGizmoPosition, setUpdateGizmoPosition] = useState(0);

  const handleAxisChange = (value: string, setAxis: (val: string) => void) => {
    if (isParameter(value, parameters)) {
      setIsPositionChanged(true);
    }
    setAxis(value);
    setUpdateGizmoPosition(updateGizmoPosition + 1);
  };

  useEffect(() => {
    const parsedXAxis = calculate(
      replaceParametersToIds(xAxis, parameters),
      parameters
    );
    const parsedYAxis = calculate(
      replaceParametersToIds(yAxis, parameters),
      parameters
    );
    const parsedZAxis = calculate(
      replaceParametersToIds(zAxis, parameters),
      parameters
    );

    gizmoManager?.attachedMesh?.position.set(
      centralPos(selectedModels, mainScene).x + parsedXAxis,
      centralPos(selectedModels, mainScene).y + parsedYAxis,
      centralPos(selectedModels, mainScene).z + parsedZAxis
    );
  }, [updateGizmoPosition]);

  const handleFactorChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFactor(e.target.value);
  };

  const handleIsCanCopyChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCanCopy(e.target.checked);
  };

  useEffect(() => {
    if (visible) handlePreview();

    if (!visible) {
      setXAxis("0");
      setYAxis("0");
      setZAxis("0");
      setFactor("1");
      setIsCanCopy(false);
    }
  }, [visible, xAxis, yAxis, zAxis, factor, isCanCopy, previewModels]);

  return (
    <DraggableModal
      title={
        <div className="pointer-events-auto cursor-pointer bg-red-300 w-full text-xl font-semibold rounded-t-md py-2 text-center border-b-2 border-gray-800">
          Translate
        </div>
      }
      visible={visible}
      buttons={
        <div className="flex flex-row gap-1 w-full justify-between">
          <div />
          <div className="flex gap-2">
            <button
              onClick={handleCancel}
              id="translate-cancel-btn"
              disabled={saving}
              className="bg-red-300 enabled:hover:bg-red-400 enabled:active:bg-red-500 rounded text-center px-4 py-1 disable-drag disabled:opacity-50"
            >
              Cancel
            </button>
            <button
              onClick={handleOk}
              id="translate-ok-btn"
              disabled={!isValid || saving}
              className={`rounded text-center px-4 py-1 disable-drag bg-green-300 enabled:hover:bg-green-400 enabled:active:bg-green-500 disabled:opacity-50`}
            >
              {saving ? (
                <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-900 animate-spin dark:text-gray-600 fill-white"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "OK"
              )}
            </button>
          </div>
        </div>
      }
    >
      <form>
        <div className="mt-4 grid grid-cols-1 gap-x-3 gap-y-3">
          <div className="col-span-full flex items-center">
            <label
              htmlFor="xAxis"
              className="flex text-sm font-large leading-6 text-gray-900 mr-2"
            >
              X
            </label>
            <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
              <input
                type="text"
                name="xAxis"
                value={xAxis}
                onChange={(e) => {
                  handleAxisChange(e.target.value, setXAxis);
                }}
                id="xAxis"
                autoComplete="off"
                className="flex flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm rounded-md shadow-sm ring-1 ring-inset ring-primary-600"
              />
            </div>
          </div>
          <div className="col-span-full flex items-center">
            <label
              htmlFor="yAxis"
              className="block text-sm font-medium leading-6 text-gray-900 mr-2"
            >
              Y
            </label>
            <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
              <input
                type="text"
                name="yAxis"
                value={yAxis}
                onChange={(e) => {
                  handleAxisChange(e.target.value, setYAxis);
                }}
                id="yAxis"
                autoComplete="off"
                className="flex flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm rounded-md shadow-sm ring-1 ring-inset ring-primary-600"
              />
            </div>
          </div>
          <div className="col-span-full flex items-center">
            <label
              htmlFor="zAxis"
              className="block text-sm font-medium leading-6 text-gray-900 mr-2"
            >
              Z
            </label>
            <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
              <input
                type="text"
                name="zAxis"
                value={zAxis}
                onChange={(e) => {
                  handleAxisChange(e.target.value, setZAxis);
                }}
                id="zAxis"
                autoComplete="off"
                className="flex flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm rounded-md shadow-sm ring-1 ring-inset ring-primary-600"
              />
            </div>
          </div>
          <div className="col-span-full">
            <div className="relative flex items-center">
              <div className="flex-grow border-t border-gray-400"></div>
              <div className="flex-grow border-t border-gray-400"></div>
            </div>
          </div>
          <div className="accent-primary-600 col-span-full flex items-center">
            <label
              htmlFor="copy"
              className="block text-sm font-medium leading-6 text-gray-900 mr-2"
            >
              Copy
            </label>
            <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
              <input
                type="checkbox"
                name="copy"
                onChange={handleIsCanCopyChanges}
                id="copy"
                autoComplete="off"
                className="flex flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm rounded-md shadow-sm ring-1 ring-inset ring-primary-600"
                checked={isCanCopy}
              />
            </div>
          </div>
          <div
            className={classNames("col-span-full items-center", {
              "opacity-30": !isCanCopy,
            })}
          >
            <label
              htmlFor="factor"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Repetitions
            </label>
            <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
              <input
                type="text"
                name="factor"
                value={factor}
                onChange={handleFactorChanges}
                disabled={!isCanCopy}
                id="factor"
                autoComplete="off"
                className="flex flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm rounded-md shadow-sm ring-1 ring-inset ring-primary-600"
              />
            </div>
          </div>
        </div>
      </form>
    </DraggableModal>
  );
}

export default TranslateMenu;
