import { Popover, Transition } from "@headlessui/react";
import { ColumnProps } from "components/ParameterSweepTable";
import { Fragment } from "react";

interface ParameterSweepContextMenuProps {
  parameters: any[];
  addColumn: (parameter: ColumnProps) => void;
  removeColumn: (parameter: ColumnProps) => void;
  className: string;
}

const ParameterSweepContextMenu = (props: ParameterSweepContextMenuProps) => {
  const { parameters, addColumn, removeColumn, className } = props;
  return (
    <Popover className={`relative ${className}`}>
      <Popover.Button
        className={`inline-flex items-end gap-x-1 text-sm font-semibold leading-6 text-gray-900 px-3 py-1 rounded-md bg-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 active:bg-gray-100`}
      >
        Parameters
        <span
          className={`rotate-180 ml-0.5 mb-1 transition-transform duration-300 inline-block`}
        >
          <svg
            width="10"
            height="14"
            viewBox="0 0 24 21"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.39019 17.0983L10.6151 2.89171C11.0696 2.10655 11.2969 1.71396 11.5935 1.58211C11.8521 1.4671 12.1474 1.4671 12.4061 1.58211C12.7026 1.71396 12.9299 2.10654 13.3844 2.89171L21.6093 17.0983C22.0655 17.8863 22.2936 18.2803 22.2599 18.6037C22.2305 18.8857 22.0827 19.142 21.8534 19.3088C21.5904 19.5 21.1352 19.5 20.2246 19.5H3.77487C2.86435 19.5 2.40908 19.5 2.14613 19.3088C1.91677 19.142 1.769 18.8857 1.73959 18.6037C1.70588 18.2803 1.93398 17.8863 2.39019 17.0983Z"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute right-0 z-[10000] mt-1 flex w-screen max-w-max px-0">
          <div className="rounded-lg max-w-md overflow-auto flex-auto bg-white text-sm leading-4 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-1">
              {parameters?.length > 0 ? (
                parameters.map((parameter: any, index) => (
                  <div
                    key={index}
                    className="group relative flex gap-x-8 rounded-md p-2 hover:bg-gray-50 active:bg-gray-100 cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (parameter.isSelected) {
                        removeColumn(parameter);
                      } else {
                        addColumn(parameter);
                      }
                    }}
                  >
                    {parameter.isSelected && <span>✓</span>}
                    <span>
                      <code>{parameter.name}</code>
                    </span>
                  </div>
                ))
              ) : (
                <div
                  key={0}
                  className="group relative flex gap-x-6 rounded-md p-2 hover:bg-gray-50 cursor-not-allowed"
                >
                  <span>No parameters defined</span>
                </div>
              )}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default ParameterSweepContextMenu;
