import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

const initialState = {
  sParameters: {
    magnitudes: [
      {
        name: "",
        type: "scatter",
        x: [0],
        y: [0],
      },
    ],
  },
  pattern2D: {
    ports: {},
  },
};

const graphsSlice = createSlice({
  name: "graphsSlice",
  initialState,
  reducers: {
    setSParameters(state, action) {
      state.sParameters = { ...state.sParameters, ...action.payload };
    },
    setPattern2D(state, action) {
      state.pattern2D = { ...state.pattern2D, ...action.payload };
    },
  },
});

export const { setSParameters } = graphsSlice.actions;
export const { setPattern2D } = graphsSlice.actions;

export default graphsSlice.reducer;
