import MyIcon from "assets/MyIcons";
import { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";

export interface ColumnProps {
  name?: string;
  id: string;
}

interface CustomisableTableProps {
  data: any[];
  columns: ColumnProps[];
  addCase: (e: any) => Promise<any>;
  removeCase: (id: string) => Promise<any>;
  editCase: (
    id: string,
    parameter: string,
    parameterValue: string
  ) => Promise<any>;
  setIsEditing?: (isEditing: boolean) => void;
  inputType?: string;
  disabled?: boolean;
}

const CustomisableTable = (props: CustomisableTableProps) => {
  const { data, columns, addCase, removeCase, editCase } = props;
  const [isCellEditingActive, setIsCellEditingActive] = useState(false);
  const [activeCaseId, setActiveCaseId] = useState("");
  const [activeParameter, setActiveParameter] = useState("");
  const [activeParameterValue, setActiveParameterValue] = useState("");
  const editableCellRef = useRef<HTMLTableCellElement | null>(null);

  props.setIsEditing && props.setIsEditing(isCellEditingActive);

  const editCurrentCase = async () => {
    await editCase(activeCaseId, activeParameter, activeParameterValue);
    setIsCellEditingActive(false);
  };

  // Detect clicks outside of the editable cell
  useEffect(() => {
    function handleClickOutside(event: any) {
      const target = event.target as HTMLElement;
      let currentNode: HTMLElement | null = target;

      // Important Note: this is used to make sure shit happens 1 by one
      // Because editCase happens unconventionally (outside just a click) we need to work around it
      // Traverse up the DOM hierarchy until reaching the document root
      while (currentNode !== null && currentNode !== document.documentElement) {
        if (currentNode.classList.contains("prevent-edit-case")) {
          return; // If any parent node has the class "prevent-edit-case", return early
        }
        currentNode = currentNode.parentElement;
      }

      if (
        editableCellRef.current &&
        !editableCellRef.current.contains(event.target) &&
        !target.classList.contains("prevent-edit-case")
      ) {
        if (isCellEditingActive) {
          editCurrentCase();
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    isCellEditingActive,
    activeCaseId,
    activeParameter,
    activeParameterValue,
    editCase,
  ]);

  return (
    <div
      className="overflow-x-auto overflow-y-auto max-h-96 max-w-full shadow-lg rounded-lg disabled:opacity-50"
      aria-disabled={props.disabled}
    >
      {data.length <= 0 && columns.length <= 0 ? (
        <tr>
          <td
            colSpan={columns.length + 1}
            className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500 cursor-auto"
          >
            No sweep sequence defined. Select a parameter to get started.
          </td>
        </tr>
      ) : (
        <div className="relative">
          <table className="min-w-full divide-y divide-gray-200 shadow-sm bg-gray-100 rounded-lg">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-2 text-left text-sm font-medium text-gray-400 tracking-wider cursor-auto">
                  #
                </th>
                {columns.length > 0 &&
                  columns.map((column, columnIndex) => (
                    <th
                      className="px-6 py-2 text-left text-sm font-medium text-gray-700 tracking-wider cursor-auto"
                      key={columnIndex}
                    >
                      <code>{column.name}</code>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data.length > 0 ? (
                data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={`${
                      props.disabled ? "cursor-not-allowed" : "cursor-pointer"
                    } hover:bg-gray-50 active:bg-gray-200`}
                  >
                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-400">
                      {rowIndex + 1}
                    </td>
                    {columns.map((column, columnIndex) => (
                      <td
                        className="px-6 py-2 whitespace-nowrap text-sm text-gray-700"
                        key={columnIndex}
                        onDoubleClick={() => {
                          if (!isCellEditingActive) {
                            setIsCellEditingActive(true);
                            setActiveCaseId(row["id"]);
                            setActiveParameter(column.id);
                            setActiveParameterValue(row[column.id]);
                          }
                        }}
                        onChange={(e: any) => {
                          setActiveParameterValue(e.target.value);
                        }}
                        onClick={async () => {
                          if (editableCellRef.current && isCellEditingActive) {
                            setIsCellEditingActive(false);
                            await editCase(
                              activeCaseId,
                              activeParameter,
                              activeParameterValue
                            );
                          }
                        }}
                        ref={
                          isCellEditingActive &&
                          activeCaseId === row["id"] &&
                          activeParameter === column.id
                            ? editableCellRef
                            : null
                        }
                      >
                        {isCellEditingActive &&
                        activeCaseId === row["id"] &&
                        activeParameter === column.id ? (
                          <div className="flex items-center space-x-1 w-28">
                            <input
                              type={props.inputType || "text"}
                              className="flex-1 py-0.5 pl-1.5 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                              value={activeParameterValue}
                              onChange={(e) => {
                                setActiveParameterValue(e.currentTarget.value);
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                            />
                          </div>
                        ) : (
                          row[column.id]
                        )}
                      </td>
                    ))}
                    <td className="px-6 whitespace-nowrap text-right text-sm font-medium prevent-edit-case">
                      <span
                        data-tooltip-id="delete-set-tooltip"
                        data-tooltip-content="Remove from sweep"
                      >
                        <button
                          type="button"
                          className={`${
                            props.disabled
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          } text-red-600 hover:text-red-900`}
                          onClick={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (isCellEditingActive) {
                              await editCurrentCase();
                            }
                            await removeCase(row["id"]);
                          }}
                        >
                          <svg
                            width="18"
                            height="20"
                            viewBox="0 0 18 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.3333 5V4.33333C12.3333 3.39991 12.3333 2.9332 12.1517 2.57668C11.9919 2.26308 11.7369 2.00811 11.4233 1.84832C11.0668 1.66667 10.6001 1.66667 9.66667 1.66667H8.33333C7.39991 1.66667 6.9332 1.66667 6.57668 1.84832C6.26308 2.00811 6.00811 2.26308 5.84832 2.57668C5.66667 2.9332 5.66667 3.39991 5.66667 4.33333V5M7.33333 9.58333V13.75M10.6667 9.58333V13.75M1.5 5H16.5M14.8333 5V14.3333C14.8333 15.7335 14.8333 16.4335 14.5608 16.9683C14.3212 17.4387 13.9387 17.8212 13.4683 18.0609C12.9335 18.3333 12.2335 18.3333 10.8333 18.3333H7.16667C5.76654 18.3333 5.06647 18.3333 4.53169 18.0609C4.06129 17.8212 3.67883 17.4387 3.43915 16.9683C3.16667 16.4335 3.16667 15.7335 3.16667 14.3333V5"
                              stroke="#D92D20"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </span>
                      <Tooltip id="delete-set-tooltip" />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={columns.length + 1}
                    className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500 cursor-auto"
                  >
                    No sweep sequence defined.
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr className="prevent-edit-case">
                <td
                  colSpan={columns.length + 1}
                  className="group pl-5 pr-3 py-2 whitespace-nowrap text-sm font-medium text-center"
                  onClick={async () => {
                    if (isCellEditingActive) {
                      await editCurrentCase();
                    }
                    await addCase({});
                  }}
                >
                  <button
                    type="button"
                    className={`${
                      props.disabled
                        ? "cursor-not-allowed"
                        : "cursor-pointer group-active:text-gray-900 group-hover:underline"
                    } flex items-center pr-1 text-sm font-normal rounded-lg text-gray-700`}
                  >
                    <MyIcon name="add-item" />
                    <span className="flex-1 ml-3.5 text-base font-semibold whitespace-nowrap">
                      Add set to sequence...
                    </span>
                  </button>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      )}
    </div>
  );
};

export default CustomisableTable;
