import { ActiveContextMenuContext } from "contexts";
import { Item } from "models/Item";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { selectUsername } from "state/reducers/authSlice";
import { addHistory } from "state/reducers/historySlice";
import {
  modelAdded,
  selectModels,
  selectSavedModels,
} from "state/reducers/modelSlice";
import { selectSimulationProperties } from "state/reducers/simulationPropertiesSlice";
import { v4 as uuid } from "uuid";
import ContextMenu from "./babylonjs/ObjectComponent/ContextMenu";
import SidebarBody from "./sidebar/SidebarBody";
import SidebarFooter from "./sidebar/SidebarFooter";
import SidebarHeader from "./sidebar/SidebarHeader";

export interface SidebarProps {
  scene: BABYLON.Scene;
  items: Item[];
  selectedItems: Item[];
  setItems: React.Dispatch<React.SetStateAction<Item[]>>;
  setSelectedItems: React.Dispatch<React.SetStateAction<Item[]>>;
}

function Sidebar({
  scene,
  items,
  setItems,
  selectedItems,
  setSelectedItems,
}: SidebarProps) {
  const { projectId } = useParams();
  const [pastePos, setPastePos] = useState({ x: 0, y: 0 });
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [showMenu, setShowMenu] = useState(false);
  const [childContextMenuEnabled, setChildContextMenuEnabled] = useState(false);
  const { setActiveContextMenu, contextMenuLocation } = useContext(
    ActiveContextMenuContext
  ) as any;

  var cubePastedCnt = useRef(1);
  var spherePastedCnt = useRef(1);
  var cylinderPastedCnt = useRef(1);
  var analyticPastedCnt = useRef(1);

  const dispatch = useAppDispatch();
  const savedModels = useAppSelector(selectSavedModels);
  const models = useAppSelector(selectModels);
  const username = useAppSelector(selectUsername);

  const isPopupOpen = useAppSelector((state) => state.general.isPopupOpen);
  const simulationProperties = useAppSelector(selectSimulationProperties);

  const getElementNumber = () => {
    let nums = [];
    for (let i = 0; i < models.length; i++) {
      if (models[i].type === "element") {
        const nameSplit = models[i].name.split(" ");
        try {
          const num = parseInt(nameSplit[1]);
          nums.push(num);
        } catch {}
      }
    }
    nums = nums.sort((a, b) => a - b);
    let lowestNum = 0;

    for (let i = 0; i < nums.length; i++) {
      if (nums[i] === lowestNum + 1) {
        lowestNum = nums[i];
      }
    }

    return lowestNum + 1;
  };

  const getPortNumber = () => {
    let nums = [];
    for (let i = 0; i < models.length; i++) {
      if (models[i].type === "port") {
        const nameSplit = models[i].name.split(" ");
        try {
          const num = parseInt(nameSplit[1]);
          nums.push(num);
        } catch {}
      }
    }
    nums = nums.sort((a, b) => a - b);
    let lowestNum = 0;

    for (let i = 0; i < nums.length; i++) {
      if (nums[i] === lowestNum + 1) {
        lowestNum = nums[i];
      }
    }

    return lowestNum + 1;
  };

  const getDistanceNumber = () => {
    let nums = [];
    for (let i = 0; i < models.length; i++) {
      if (models[i].type === "distance") {
        const nameSplit = models[i].name.split(" ");
        try {
          const num = parseInt(nameSplit[1]);
          nums.push(num);
        } catch {}
      }
    }
    nums = nums.sort((a, b) => a - b);
    let lowestNum = 0;

    for (let i = 0; i < nums.length; i++) {
      if (nums[i] === lowestNum + 1) {
        lowestNum = nums[i];
      }
    }

    return lowestNum + 1;
  };

  useEffect(() => {
    cubePastedCnt.current = 1;
    spherePastedCnt.current = 1;
    cylinderPastedCnt.current = 1;
    analyticPastedCnt.current = 1;
  }, [savedModels]);

  useEffect(() => {
    setShowMenu(false);
  }, []);

  const showContextMenu = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMenu(false);
    if (savedModels.length > 0) {
      setActiveContextMenu(savedModels[0].category, 1);
      setMenuPosition({ x: e.pageX, y: e.pageY });
      setPastePos({ x: e.pageX, y: e.pageY });
      setShowMenu(true);
    }
  };

  const hideContextMenu = () => {
    setShowMenu(false);
    setActiveContextMenu("", 0);
  };

  const pasteSavedModel = async () => {
    if (isPopupOpen) return;
    console.log("saving", isPopupOpen);

    hideContextMenu();
    let objs: any = [];
    savedModels.map(async (savedModel: any, index) => {
      let savedModelObj: any = savedModel.object;
      console.log("SAVED MODEL", savedModel);
      if (savedModel.type === "element") {
        objs.push({
          ...savedModel,
          ...savedModel.object,
          number: getElementNumber() + index,
          name:
            "Element " +
            (getElementNumber() + index) +
            " (" +
            savedModelObj.resistance +
            " Ω, " +
            savedModelObj.inductance +
            " H, " +
            savedModelObj.capacitance +
            " F)",
          id: uuid(),
          selected: false,
          status: "Added",
        });
      } else if (savedModel.type === "port") {
        objs.push({
          ...savedModel,
          ...savedModel.object,
          number: getPortNumber() + index,
          name:
            "Port " +
            (getPortNumber() + index) +
            " (" +
            savedModelObj.impedance +
            " Ω)",
          id: uuid(),
          selected: false,
          status: "Added",
        });
      } else if (savedModel.type === "distance") {
        objs.push({
          ...savedModel,
          ...savedModel.object,
          number: getDistanceNumber() + index,
          name:
            "Distance " +
            (getDistanceNumber() + index) +
            " (" +
            Number(
              Math.sqrt(
                Math.pow(savedModelObj.x.max - savedModelObj.x.min, 2) +
                  Math.pow(savedModelObj.y.max - savedModelObj.y.min, 2) +
                  Math.pow(savedModelObj.z.max - savedModelObj.z.min, 2)
              ).toFixed(3)
            ).toString() +
            " " +
            simulationProperties.dimensionsUnit.replace("um", "μm") +
            ")",
          id: uuid(),
          selected: false,
          status: "Added",
        });
      } else {
        let cnt = 1;
        if (savedModel.type === "cube") {
          cnt = cubePastedCnt.current;
          cubePastedCnt.current++;
        } else if (savedModel.type === "sphere") {
          cnt = spherePastedCnt.current;
          spherePastedCnt.current++;
        } else if (savedModel.type === "cylinder") {
          cnt = cylinderPastedCnt.current;
          cylinderPastedCnt.current++;
        } else if (savedModel.type === "analytic") {
          cnt = analyticPastedCnt.current;
          analyticPastedCnt.current++;
        }
        const mesh = scene.getMeshById(savedModel.id);
        if (!mesh) return;
        let newObj = {
          ...savedModel,
          ...savedModel.object,
          position: {
            x: mesh.position.x,
            y: mesh.position.y,
            z: mesh.position.z,
          },
          rotation: {
            x: mesh.rotation.x,
            y: mesh.rotation.y,
            z: mesh.rotation.z,
          },
          name: savedModel.name + "_" + cnt,
          id: uuid(),
          selected: false,
          status: "Added",
        };

        objs.push(newObj);
      }
    });
    objs.map(async (obj: any) => {
      if (obj.parentId !== 0 && obj.category === "Objects") {
        savedModels.map((model: any, j: number) => {
          if (obj.parentId === model?.id && model.category === "Objects")
            obj.parentId = objs[j]?.id;
        });
      }
      console.log("paste obj", obj);
      dispatch(modelAdded(obj));
      await dispatch(
        addHistory({
          payloadData: {
            paste: {
              ...obj,
            },
          },
          currentUsername: username,
          projectId: projectId || "",
        })
      );
    });
  };

  return (
    <aside className="font-inter h-full touch-auto w-full" aria-label="Sidebar">
      <div
        className="flex flex-col justify-between h-screen py-4 px-3 bg-white z-50"
        onContextMenu={showContextMenu}
        onClick={hideContextMenu}
      >
        <div>
          <SidebarHeader />
          <SidebarBody
            setChildContextMenuEnabled={(value: boolean) =>
              setChildContextMenuEnabled(value)
            }
            scene={scene}
            items={items}
            selectedItems={selectedItems}
            setItems={setItems}
            setSelectedItems={setSelectedItems}
            pasteSavedModel={pasteSavedModel}
          />
        </div>
        <div>
          <SidebarFooter projectId={projectId ?? ""} />
        </div>
        <ContextMenu
          visible={
            showMenu &&
            !childContextMenuEnabled &&
            savedModels.length > 0 &&
            contextMenuLocation === 1 &&
            models.filter(
              (model) =>
                model.selected && model.category === savedModels[0].category
            ).length >= 0
          }
          menuPosition={menuPosition}
          models={models}
          isMultiSelect={models.filter((model) => model.selected).length > 1}
          clickedObject={{}}
          pasteSavedModel={pasteSavedModel}
          materialMenuVisible={false}
          setMaterialMenuVisible={() => {}}
          mainScene={scene}
        />
      </div>
    </aside>
  );
}

export default Sidebar;
