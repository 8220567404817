import { useAppSelector } from "state/hooks";
import { selectGeneratedMesh } from "state/reducers/generatedMeshSlice";
import { selectClassifiedMesh } from "state/reducers/classifiedMeshSlice";
import "./FooterBar.css";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectProjectInfo } from "state/reducers/projectInfoSlice";
import { selectSimulationProperties } from "state/reducers/simulationPropertiesSlice";

export default function FooterBar() {
  const simulationProperties = useAppSelector(selectSimulationProperties);
  const projectInfo = useSelector(selectProjectInfo).info;
  const generatedMesh = useAppSelector(selectGeneratedMesh);
  const classifiedMesh = useAppSelector(selectClassifiedMesh);

  const formatNumberWithCommas = (number: number) => {
    return number.toLocaleString();
  };

  function estimateTimestep(generatedMesh: {
    x: number[];
    y: number[];
    z: number[];
  }) {
    const dimensionsUnit = simulationProperties.dimensionsUnit;
    const dimensionsMapping: { [unit: string]: number } = {
      m: 1,
      cm: 1e-2,
      mm: 1e-3,
      um: 1e-6,
      nm: 1e-9,
    };
    const dimensionsMultiplier = dimensionsMapping[dimensionsUnit];

    const c = 299792458; // m/s

    let deltaX =
      dimensionsMultiplier *
      Math.min(...adjacentDifferences(generatedMesh.x.map((x) => x)));
    let deltaY =
      dimensionsMultiplier *
      Math.min(...adjacentDifferences(generatedMesh.y.map((y) => y)));
    let deltaZ =
      dimensionsMultiplier *
      Math.min(...adjacentDifferences(generatedMesh.z.map((z) => z)));

    let deltaT =
      1 / (c * Math.sqrt(1 / deltaX ** 2 + 1 / deltaY ** 2 + 1 / deltaZ ** 2));

    let timestep = `${Number(deltaT.toExponential().split("e")[0]).toFixed(
      2
    )}e${deltaT.toExponential().split("e")[1]} sec`;
    return timestep;
  }

  function adjacentDifferences(arr: number[]) {
    let differences = [];
    for (let i = 1; i < arr.length; i++) {
      differences.push(Math.abs(arr[i] - arr[i - 1]));
    }
    return differences;
  }

  useEffect(() => {
    if (projectInfo?.status.includes("discretization")) {
      if (classifiedMesh?.classification.includes("require")) {
        toast.error(
          <div
            dangerouslySetInnerHTML={{ __html: classifiedMesh.classification }}
          />,
          {
            toastId: "error",
            autoClose: 10000,
          }
        );
      } else if (classifiedMesh?.classification.includes("passed")) {
        toast.success(
          <div
            dangerouslySetInnerHTML={{ __html: classifiedMesh.classification }}
          />,
          {
            toastId: "success",
          }
        );
      } else {
        toast.dismiss();
      }
    }
  }, [classifiedMesh]);

  return (
    <div className="footer-bar MuiBox-root css-38zrbw flex justify-between items-center px-4 mt-1.5 pb-1 cursor-auto">
      <div>
        {generatedMesh ? (
          <p className="font-medium">
            Mesh size:{" "}
            <span className="font-normal">
              {formatNumberWithCommas(generatedMesh?.x.length)} ×{" "}
              {formatNumberWithCommas(generatedMesh?.y.length)} ×{" "}
              {formatNumberWithCommas(generatedMesh?.z.length)} ={" "}
              {formatNumberWithCommas(
                generatedMesh?.x.length *
                  generatedMesh?.y.length *
                  generatedMesh?.z.length
              )}
              {" cells"}
            </span>
          </p>
        ) : (
          <p className="font-medium">
            Mesh size: <span className="font-normal">-</span>
          </p>
        )}
      </div>
      <div className="mx-2 border-r border-gray-300 cursor-default">&nbsp;</div>
      <div>
        <p className="font-medium">
          Estimated timestep:{" "}
          <span className="font-normal">
            {generatedMesh ? estimateTimestep(generatedMesh) : "-"}
          </span>
        </p>
      </div>
      <div className="mx-2 border-r border-gray-300 cursor-default">&nbsp;</div>
      <div>
        {classifiedMesh?.classification.includes("require") ? (
          <p className="font-medium">
            Quality classification:{" "}
            <span
              className="font-normal text-error-700"
              style={{ display: "inline-flex", alignItems: "center" }}
            >
              <svg
                className="mx-1 mb-1 pt-0.5"
                width="15"
                height="15"
                viewBox="0 0 24 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.9998 7.99999V12M11.9998 16H12.0098M10.6151 2.89171L2.39019 17.0983C1.93398 17.8863 1.70588 18.2803 1.73959 18.6037C1.769 18.8857 1.91677 19.142 2.14613 19.3088C2.40908 19.5 2.86435 19.5 3.77487 19.5H20.2246C21.1352 19.5 21.5904 19.5 21.8534 19.3088C22.0827 19.142 22.2305 18.8857 22.2599 18.6037C22.2936 18.2803 22.0655 17.8863 21.6093 17.0983L13.3844 2.89171C12.9299 2.10654 12.7026 1.71396 12.4061 1.58211C12.1474 1.4671 11.8521 1.4671 11.5935 1.58211C11.2969 1.71396 11.0696 2.10655 10.6151 2.89171Z"
                  stroke="#b42318"
                  strokeWidth="2.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Mesh refinement recommended
            </span>
          </p>
        ) : classifiedMesh?.classification.includes("passed") ? (
          <p className="font-medium">
            Quality classification:{" "}
            <span
              className="font-normal text-success-700"
              style={{ display: "inline-flex", alignItems: "center" }}
            >
              <svg
                className="mx-1 mb-0.5"
                width="14"
                height="14"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.5 11L9.5 14L15.5 8M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                  stroke="#027a48"
                  strokeWidth="2.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Pass
            </span>
          </p>
        ) : classifiedMesh?.classification &&
          classifiedMesh?.classification !== "-" ? (
          <p className="font-medium">
            Quality classification:{" "}
            <span
              className="font-normal text-warning-600"
              dangerouslySetInnerHTML={{
                __html: classifiedMesh.classification,
              }}
            />
          </p>
        ) : (
          <p className="font-medium">
            Quality classification:{" "}
            <span className="font-normal text-black">-</span>
          </p>
        )}
      </div>
    </div>
  );
}
