import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Storage } from "aws-amplify";
import Mexp from "math-expression-evaluator";
import { replaceParameterToValue } from "utilities";
import { v4 as uuid } from "uuid";
import { RootState } from "..";
import {
  updateParameterSweepCases,
  updateParameterSweepParams,
} from "./parametersSlice";

let initialHistories: any[] = [];

const initialState = {
  username: "",
  projectId: "",
  cacheHistories: initialHistories,
  histories: initialHistories,
  currentHistoryStep: 0,
  saving: false,
};

export const addHistory = createAsyncThunk<
  void,
  { payloadData: any; currentUsername?: string; projectId: string }
>(
  "histories/addHistory",
  async (
    { payloadData, currentUsername, projectId },
    { dispatch, getState }
  ) => {
    const mexp = new Mexp();
    // const currentUsername = useAppSelector(selectUsername);
    const state: any = getState();
    // Finally, upload the updated array back to S3
    const data = await Storage.get(
      `${currentUsername}/projects/${projectId}/history.json`,
      {
        download: true,
        cacheControl: "no-cache",
      }
    );
    const dataBody = data.Body;

    if (dataBody) {
      const dataString = await dataBody.text();
      let jsonArray: any =
        JSON.parse(dataString).length !== 0 ? JSON.parse(dataString) : {};

      jsonArray.historyList = (jsonArray.historyList || []).slice(
        0,
        state.histories.currentHistoryStep
      );

      // if (
      //   state.histories.histories.length > state.histories.currentHistoryStep
      // ) {
      //   setHistory(
      //     state.histories.histories.slice(0, state.histories.currentHistoryStep)
      //   );
      //   jsonArray = jsonArray.slice(0, state.histories.currentHistoryStep);
      // }

      let key = Object.keys(payloadData)[0];

      if (!Array.isArray(payloadData[key])) {
        payloadData[key] = [payloadData[key]];
      }

      payloadData[key].forEach(
        (pdData: {
          target?: string;
          id: string;
          parameter: string | number;
          parameterValue: any;
        }) => {
          const target = pdData.target;
          delete pdData.target;

          if (key.includes("parameterSweep")) {
            if (jsonArray.parameterSweep === undefined) {
              jsonArray.parameterSweep = { parameters: [], cases: [] };
            }

            if (target === "parameter_add") {
              jsonArray.parameterSweep.parameters.push(pdData);
              dispatch(
                updateParameterSweepParams(jsonArray.parameterSweep.parameters)
              );
            } else if (target === "parameter_remove") {
              jsonArray.parameterSweep.parameters =
                jsonArray.parameterSweep.parameters.filter((param: any) => {
                  return param.id !== pdData.id;
                });
              dispatch(
                updateParameterSweepParams(jsonArray.parameterSweep.parameters)
              );
            }

            if (target === "case_add") {
              jsonArray.parameterSweep.cases.push(pdData);
              dispatch(
                updateParameterSweepCases(jsonArray.parameterSweep.cases)
              );
            } else if (target === "case_remove") {
              jsonArray.parameterSweep.cases =
                jsonArray.parameterSweep.cases.filter((sweepCase: any) => {
                  return sweepCase.id !== pdData.id;
                });
              dispatch(
                updateParameterSweepCases(jsonArray.parameterSweep.cases)
              );
            } else if (target === "case_edit") {
              console.log("case edit", pdData);
              const parameterSweepCases = jsonArray.parameterSweep.cases;
              const caseIndex = parameterSweepCases.findIndex(
                (row: any) => row.id === pdData.id
              );
              parameterSweepCases[caseIndex][pdData.parameter] =
                pdData.parameterValue;
              jsonArray.parameterSweep.cases = parameterSweepCases;

              dispatch(
                updateParameterSweepCases(jsonArray.parameterSweep.cases)
              );
            }
          } else if (key.indexOf("parameter") !== -1) {
            if (jsonArray.parameters === undefined) {
              jsonArray.parameters = [];
            }

            console.log("TARGET PARAMETER", key);

            if (key.indexOf("edit") !== -1) {
              console.log("editing parameter");
              jsonArray.parameters.map((history: any) => {
                if (history.id === pdData.id) {
                  return Object.assign(history, pdData);
                }
              });
              jsonArray.historyList.map((history: any) => {
                let k = Object.keys(history)[0];
                if (k === "translate" || k === "rotate" || k === "scale") {
                  if (history[k].factor.indexOf(pdData.id) !== -1) {
                    let str = history[k].factor;
                    str = str.toString().replace(/\s/g, "");
                    jsonArray.parameters.map((param: any) => {
                      str = str.replaceAll(
                        param.id.toString(),
                        param.expression.toString()
                      );
                    });

                    let idLeng = history[k].idArray.length;
                    let paramVal = mexp.eval(str);
                    if (idLeng < paramVal) {
                      for (let i = 0; i < paramVal - idLeng; i++)
                        history[k].idArray.push(uuid());
                    } else if (idLeng > paramVal) {
                      for (let i = 0; i < idLeng - paramVal; i++)
                        history[k].idArray.splice(
                          history[k].idArray.length - 1,
                          1
                        );
                    }
                  }
                }
              });
            } else if (key.indexOf("delete") !== -1) {
              let index = jsonArray.parameters.findIndex(
                (history: any) => history.id === pdData.id
              );

              jsonArray.historyList.map((history: any) => {
                const key = Object.keys(history)[0];
                const keys = Object.keys(history[key]);

                keys.map((k: any) => {
                  if (
                    k !== "id" &&
                    k !== "material" &&
                    k !== "name" &&
                    typeof history[key][k] === "string"
                  ) {
                    history[key][k] = replaceParameterToValue(
                      history[key][k],
                      pdData.id,
                      mexp
                        .eval(jsonArray.parameters[index].expression)
                        .toString()
                    ).toString();
                  }
                });
              });

              jsonArray.parameters.splice(index, 1);
            } else {
              if (jsonArray.parameters === undefined) {
                jsonArray.parameters = [];
              }
              jsonArray.parameters.push(pdData);
            }
          } else {
            if (
              key.indexOf("edit") !== -1 ||
              key.indexOf("change_name") !== -1
            ) {
              jsonArray.historyList.map((history: any) => {
                if (
                  history[Object.keys(history)[0]].id === pdData.id &&
                  Object.keys(history)[0].indexOf("create") !== -1
                ) {
                  Object.assign(history[Object.keys(history)[0]], pdData);
                  return;
                }
              });
            } else {
              if (jsonArray.historyList === undefined) {
                jsonArray.historyList = [];
              }
              // if (parameterIndex !== -1) {
              //   jsonArray.parameters[parameterIndex].name = pdData.name;
              // }
            }
          }

          if (!key.includes("parameter")) {
            dispatch(
              historySlice.actions.pushHistory({
                [key]: pdData,
              })
            );
          }
        }
      );

      try {
        console.log("saving to history", jsonArray);
        await Storage.put(
          `${currentUsername}/projects/${projectId}/history.json`,
          JSON.stringify(jsonArray),
          {
            cacheControl: "no-cache",
          }
        );
      } catch (e) {
        console.log("Error saving to history", e);
      }
    }
  }
);

// saves local history to the cloud
export const saveHistory = createAsyncThunk<void, { projectId: string }>(
  "histories/saveHistory",
  async ({ projectId }, { dispatch, getState }) => {
    const state: any = getState();
    const currentUsername = state.authSlice.username;

    const data = await Storage.get(
      `${currentUsername}/projects/${projectId}/history.json`,
      {
        download: true,
        cacheControl: "no-cache",
      }
    );
    const dataBody = data.Body;

    if (dataBody) {
      const dataString = await dataBody.text();
      let jsonArray: any =
        JSON.parse(dataString).length !== 0 ? JSON.parse(dataString) : {};

      jsonArray.historyList = state.histories.histories;
      try {
        await Storage.put(
          `${currentUsername}/projects/${projectId}/history.json`,
          JSON.stringify(jsonArray),
          {
            cacheControl: "no-cache",
          }
        );
      } catch (e) {
        console.log("Error saving to history", e);
      }
    }
  }
);

const historySlice = createSlice({
  name: "histories",
  initialState,
  reducers: {
    pushHistory(state, action) {
      const historyChunk = state.histories.slice(0, state.currentHistoryStep);
      historyChunk.push(action.payload);

      state.histories = historyChunk;
      state.currentHistoryStep = historyChunk.length;
    },
    moveToHistoryStep(state, action) {
      state.currentHistoryStep = action.payload;
    },
    moveHistoryBy(state, action) {
      if (state.currentHistoryStep + action.payload < 0) return;
      if (state.currentHistoryStep + action.payload > state.histories.length)
        return;

      state.currentHistoryStep += action.payload;
    },
    editHistory(state, action) {
      state.cacheHistories = Object.assign([], state.histories);
      if (action.payload.index < 0) {
        state.histories = action.payload.data;
        return;
      }
      state.histories[action.payload.index] = action.payload.data;
    },
    setHistory(state, action) {
      console.log("set history", action.payload);

      state.histories = Object.assign([], action.payload || []);
      state.currentHistoryStep = (action.payload || []).length;
    },
    setHistoryUserName(state, action) {
      state.username = action.payload;
    },
    setHistorySaving(state, action) {
      state.saving = action.payload;
    },
    updateHistoryCache(state) {
      state.cacheHistories = state.histories;
    },
  },
  // Handle async action
  extraReducers: (builder) => {
    builder
      .addCase(addHistory.pending, (state) => {
        state.saving = true;
      })
      .addCase(addHistory.fulfilled, (state) => {
        state.saving = false;
      })
      .addCase(addHistory.rejected, (state) => {
        state.saving = false;
        // handle any error if needed
      });
  },
});

export const selectHistories = (state: RootState) => state.histories.histories;

export const {
  setHistoryUserName,
  setHistorySaving,
  setHistory,
  pushHistory,
  moveToHistoryStep,
  moveHistoryBy,
  editHistory,
  updateHistoryCache,
} = historySlice.actions;

export default historySlice.reducer;
