import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPopupOpen: false,
};

const generalSlice = createSlice({
  name: "generalSlice",
  initialState,
  reducers: {
    setPopupOpen(state, action) {
      state.isPopupOpen = action.payload;
    },
  },
});

export const { setPopupOpen } = generalSlice.actions;

export default generalSlice.reducer;
