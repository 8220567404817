import DraggableModal from "components/DraggableModal";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { selectUsername } from "state/reducers/authSlice";
import { setPopupOpen } from "state/reducers/generalSlice";
import { addHistory } from "state/reducers/historySlice";
import {
  modelAdded,
  modelAltered,
  modelRemoved,
  selectModels,
} from "state/reducers/modelSlice";
import { selectParameters } from "state/reducers/parametersSlice";
import { selectRefresh, setRefresh } from "state/reducers/refreshSlice";
import {
  isSceneClickable,
  selectPickedPos,
  setSceneClickable,
} from "state/reducers/sceneSlice";
import {
  calculate,
  replaceIdsToParameters,
  replaceParametersToIds,
} from "utilities";
import { v4 as uuid } from "uuid";
import ParameterMenu from "../Transform/ParameterMenu";

export interface LumpedElementProps {
  elementLength: number;
  visible: boolean;
  setVisible: (value: boolean) => void;
  isEditableModal?: boolean;
  modelToBeAlter?: any;
}

function LumpedElementMenu({
  elementLength,
  visible,
  setVisible,
  isEditableModal,
  modelToBeAlter,
}: LumpedElementProps) {
  const [elementNumber, setElementNumber] = useState(elementLength + 1);
  const [elementPicks, setElementPicks] = useState("Points");
  const [element_type, setElementType] = useState("serial");
  const [resistance, setResistance] = useState("0");
  const [inductance, setInductance] = useState("0");
  const [capacitance, setCapacitance] = useState("0");

  const [x1, setX1] = useState("0");
  const [x2, setX2] = useState("0");
  const [x3, setX3] = useState("0");
  const [y1, setY1] = useState("0");
  const [y2, setY2] = useState("0");
  const [y3, setY3] = useState("0");
  const [z1, setZ1] = useState("0");
  const [z2, setZ2] = useState("0");
  const [z3, setZ3] = useState("0");
  const [clickedButton, setClickedButton] = useState("");

  const [parameterMenuVisible, setParameterMenuVisible] = useState(false);
  const [parameter, setParameter] = useState({});

  const dispatch = useAppDispatch();
  const models = useAppSelector(selectModels);
  const parameters = useAppSelector(selectParameters);
  const sceneClickalbe = useAppSelector(isSceneClickable);
  const pickedPos = useAppSelector(selectPickedPos);
  const [previewElementId, setPreviewElementId] = useState("");
  const username = useAppSelector(selectUsername);
  const refresh = useAppSelector(selectRefresh);
  const { projectId } = useParams<{ projectId: string }>();

  const [isValid, setIsValid] = useState(true);
  const saving = useAppSelector((state) => state.histories.saving);

  useEffect(() => {
    if (visible) {
      dispatch(setPopupOpen(true));
    } else if (!visible) {
      dispatch(setPopupOpen(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, dispatch]);

  const validateFields = () => {
    try {
      if (
        isNaN(
          parseFloat(
            calculate(
              replaceParametersToIds(resistance, parameters),
              parameters
            ).toString()
          )
        ) ||
        isNaN(
          parseFloat(
            calculate(
              replaceParametersToIds(inductance, parameters),
              parameters
            ).toString()
          )
        ) ||
        isNaN(
          parseFloat(
            calculate(
              replaceParametersToIds(capacitance, parameters),
              parameters
            ).toString()
          )
        ) ||
        parseFloat(
          calculate(
            replaceParametersToIds(resistance, parameters),
            parameters
          ).toString()
        ) < 0 ||
        parseFloat(
          calculate(
            replaceParametersToIds(inductance, parameters),
            parameters
          ).toString()
        ) < 0 ||
        parseFloat(
          calculate(
            replaceParametersToIds(capacitance, parameters),
            parameters
          ).toString()
        ) < 0 ||
        isNaN(
          parseFloat(
            calculate(
              replaceParametersToIds(x1, parameters),
              parameters
            ).toString()
          )
        ) ||
        isNaN(
          parseFloat(
            calculate(
              replaceParametersToIds(x2, parameters),
              parameters
            ).toString()
          )
        ) ||
        isNaN(
          parseFloat(
            calculate(
              replaceParametersToIds(y1, parameters),
              parameters
            ).toString()
          )
        ) ||
        isNaN(
          parseFloat(
            calculate(
              replaceParametersToIds(y2, parameters),
              parameters
            ).toString()
          )
        ) ||
        isNaN(
          parseFloat(
            calculate(
              replaceParametersToIds(z1, parameters),
              parameters
            ).toString()
          )
        ) ||
        isNaN(
          parseFloat(
            calculate(
              replaceParametersToIds(z2, parameters),
              parameters
            ).toString()
          )
        ) ||
        (elementPicks === "Edges" &&
          (isNaN(
            parseFloat(
              calculate(
                replaceParametersToIds(x3, parameters),
                parameters
              ).toString()
            )
          ) ||
            isNaN(
              parseFloat(
                calculate(
                  replaceParametersToIds(y3, parameters),
                  parameters
                ).toString()
              )
            ) ||
            isNaN(
              parseFloat(
                calculate(
                  replaceParametersToIds(z3, parameters),
                  parameters
                ).toString()
              )
            )))
      ) {
        setIsValid(false);
        return;
      }

      setIsValid(true);
      if (!elementNumber) setIsValid(false);
    } catch (error) {
      setIsValid(false);
    }
  };

  useEffect(() => {
    validateFields();
  }, [
    elementNumber,
    elementPicks,
    element_type,
    resistance,
    inductance,
    capacitance,
    x1,
    x2,
    x3,
    y1,
    y2,
    y3,
    z1,
    z2,
    z3,
    parameterMenuVisible,
  ]);

  const getElementNumber = () => {
    let nums = [];
    for (let i = 0; i < models.length; i++) {
      if (models[i].type === "element") {
        const nameSplit = models[i].name.split(" ");
        try {
          const num = parseInt(nameSplit[1]);
          nums.push(num);
        } catch {}
      }
    }
    nums = nums.sort((a, b) => a - b);
    let lowestNum = 0;

    for (let i = 0; i < nums.length; i++) {
      if (nums[i] === lowestNum + 1) {
        lowestNum = nums[i];
      }
    }

    return lowestNum + 1;
  };

  const isAvailableElementNumber = (pn: number) => {
    if (pn % 1 !== 0) return false;
    if (pn <= 0) return false;
    for (let i = 0; i < models.length; i++) {
      if (models[i].type === "element" && models[i].category !== "Preview") {
        if (models[i]?.number.toString() === pn.toString() && !isEditableModal)
          return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (sceneClickalbe) {
      if (clickedButton === "start") {
        setX1(
          pickedPos.x !== null
            ? (Math.round(pickedPos.x * 100000) / 100000).toString()
            : "NaN"
        );
        setY1(
          pickedPos.y !== null
            ? (Math.round(pickedPos.y * 100000) / 100000).toString()
            : "NaN"
        );
        setZ1(
          pickedPos.z !== null
            ? (Math.round(pickedPos.z * 100000) / 100000).toString()
            : "NaN"
        );
      } else if (clickedButton === "end") {
        setX2(
          pickedPos.x !== null
            ? (Math.round(pickedPos.x * 100000) / 100000).toString()
            : "NaN"
        );
        setY2(
          pickedPos.y !== null
            ? (Math.round(pickedPos.y * 100000) / 100000).toString()
            : "NaN"
        );
        setZ2(
          pickedPos.z !== null
            ? (Math.round(pickedPos.z * 100000) / 100000).toString()
            : "NaN"
        );
      } else if (clickedButton === "third") {
        setX3(
          pickedPos.x !== null
            ? (Math.round(pickedPos.x * 100000) / 100000).toString()
            : "NaN"
        );
        setY3(
          pickedPos.y !== null
            ? (Math.round(pickedPos.y * 100000) / 100000).toString()
            : "NaN"
        );
        setZ3(
          pickedPos.z !== null
            ? (Math.round(pickedPos.z * 100000) / 100000).toString()
            : "NaN"
        );
      }
      setClickedButton("");
      dispatch(setSceneClickable(false));
    }
  }, [pickedPos]);

  useEffect(() => {
    if (isEditableModal !== undefined && visible) {
      setElementNumber(modelToBeAlter.number);
      setElementType(modelToBeAlter.object.element_type);
      setResistance(
        replaceIdsToParameters(modelToBeAlter.object.resistance, parameters)
      );
      setInductance(
        replaceIdsToParameters(modelToBeAlter.object.inductance, parameters)
      );
      setCapacitance(
        replaceIdsToParameters(modelToBeAlter.object.capacitance, parameters)
      );
      setX1(replaceIdsToParameters(modelToBeAlter.object.x.min, parameters));
      setX2(replaceIdsToParameters(modelToBeAlter.object.x.max, parameters));
      setX3(replaceIdsToParameters(modelToBeAlter.object.x.third, parameters));
      setY1(replaceIdsToParameters(modelToBeAlter.object.y.min, parameters));
      setY2(replaceIdsToParameters(modelToBeAlter.object.y.max, parameters));
      setY3(replaceIdsToParameters(modelToBeAlter.object.y.third, parameters));
      setZ1(replaceIdsToParameters(modelToBeAlter.object.z.min, parameters));
      setZ2(replaceIdsToParameters(modelToBeAlter.object.z.max, parameters));
      setZ3(replaceIdsToParameters(modelToBeAlter.object.z.third, parameters));
      setElementPicks(
        modelToBeAlter.object.x.third !== "" ||
          modelToBeAlter.object.y.third !== "" ||
          modelToBeAlter.object.z.third !== ""
          ? "Edges"
          : "Points"
      );
    } else if (visible) {
      let elemNumber = getElementNumber();
      setElementNumber(elemNumber);
      setElementPicks("Points");
      setResistance("0");
      setInductance("0");
      setCapacitance("0");
      setX1("");
      setX2("");
      setX3("");
      setY1("");
      setY2("");
      setY3("");
      setZ1("");
      setZ2("");
      setZ3("");
    }
    const keyDownFunc = (event: any) => {
      if (visible) {
        if (event.key === "Escape") {
          document.getElementById("element-cancel-btn")?.click();
          document.removeEventListener("keydown", keyDownFunc);
        } else if (event.key === "Enter") {
          document.getElementById("element-ok-btn")?.click();
          document.removeEventListener("keydown", keyDownFunc);
        }
      }
    };
    document.addEventListener("keydown", keyDownFunc);
  }, [isEditableModal, visible]);

  const handleChanges = (e: any) => {
    switch (e.target.name) {
      case "elementNumber":
        setElementNumber(e.target.value);
        break;
      case "elementPicks":
        setElementPicks(e.target.value);
        setX1("");
        setX2("");
        setX3("");
        setY1("");
        setY2("");
        setY3("");
        setZ1("");
        setZ2("");
        setZ3("");
        break;
      case "element_type":
        setElementType(e.target.value);
        break;
      case "resistance":
        setResistance(e.target.value);
        break;
      case "inductance":
        setInductance(e.target.value);
        break;
      case "capacitance":
        setCapacitance(e.target.value);
        break;
      case "x1":
        setX1(e.target.value);
        break;
      case "x2":
        setX2(e.target.value);
        break;
      case "x3":
        setX3(e.target.value);
        break;
      case "y1":
        setY1(e.target.value);
        break;
      case "y2":
        setY2(e.target.value);
        break;
      case "y3":
        setY3(e.target.value);
        break;
      case "z1":
        setZ1(e.target.value);
        break;
      case "z2":
        setZ2(e.target.value);
        break;
      case "z3":
        setZ3(e.target.value);
        break;
    }
  };

  const handleOk = async (e: any) => {
    dispatch(modelRemoved(previewElementId));
    setPreviewElementId("");

    if (!elementNumber) {
      toast.error("Element number cannot be empty.", {
        toastId: "error",
      });
      return;
    }
    if (!isAvailableElementNumber(elementNumber)) {
      toast.error("Element number must be an available positive integer.", {
        toastId: "error",
      });
      return;
    }
    try {
      calculate(replaceParametersToIds(resistance, parameters), parameters);
      calculate(replaceParametersToIds(inductance, parameters), parameters);
      calculate(replaceParametersToIds(capacitance, parameters), parameters);
      calculate(replaceParametersToIds(x1, parameters), parameters);
      calculate(replaceParametersToIds(x2, parameters), parameters);
      calculate(replaceParametersToIds(x3, parameters), parameters);
      calculate(replaceParametersToIds(y1, parameters), parameters);
      calculate(replaceParametersToIds(y2, parameters), parameters);
      calculate(replaceParametersToIds(y3, parameters), parameters);
      calculate(replaceParametersToIds(z1, parameters), parameters);
      calculate(replaceParametersToIds(z2, parameters), parameters);
      calculate(replaceParametersToIds(z3, parameters), parameters);
    } catch (err) {
      toast.error("Invalid properties. Please try again.", {
        toastId: "error",
      });
      return;
    }

    if (
      // Not on X-axis or the points are the same in the x direction
      (!(
        calculate(replaceParametersToIds(y1, parameters), parameters) ===
          calculate(replaceParametersToIds(y2, parameters), parameters) &&
        calculate(replaceParametersToIds(z1, parameters), parameters) ===
          calculate(replaceParametersToIds(z2, parameters), parameters) &&
        calculate(replaceParametersToIds(x1, parameters), parameters) !==
          calculate(replaceParametersToIds(x2, parameters), parameters)
      ) &&
        // Not on Y-axis or the points are the same in the y direction
        !(
          calculate(replaceParametersToIds(x1, parameters), parameters) ===
            calculate(replaceParametersToIds(x2, parameters), parameters) &&
          calculate(replaceParametersToIds(z1, parameters), parameters) ===
            calculate(replaceParametersToIds(z2, parameters), parameters) &&
          calculate(replaceParametersToIds(y1, parameters), parameters) !==
            calculate(replaceParametersToIds(y2, parameters), parameters)
        ) &&
        // Not on Z-axis or the points are the same in the z direction
        !(
          calculate(replaceParametersToIds(x1, parameters), parameters) ===
            calculate(replaceParametersToIds(x2, parameters), parameters) &&
          calculate(replaceParametersToIds(y1, parameters), parameters) ===
            calculate(replaceParametersToIds(y2, parameters), parameters) &&
          calculate(replaceParametersToIds(z1, parameters), parameters) !==
            calculate(replaceParametersToIds(z2, parameters), parameters)
        )) ||
      (elementPicks === "Edges" &&
        (((calculate(replaceParametersToIds(y2, parameters), parameters) -
          calculate(replaceParametersToIds(y1, parameters), parameters)) *
          (calculate(replaceParametersToIds(z3, parameters), parameters) -
            calculate(replaceParametersToIds(z1, parameters), parameters)) -
          (calculate(replaceParametersToIds(y3, parameters), parameters) -
            calculate(replaceParametersToIds(y1, parameters), parameters)) *
            (calculate(replaceParametersToIds(z2, parameters), parameters) -
              calculate(replaceParametersToIds(z1, parameters), parameters)) ===
          0 &&
          -(
            calculate(replaceParametersToIds(x2, parameters), parameters) -
            calculate(replaceParametersToIds(x1, parameters), parameters)
          ) *
            (calculate(replaceParametersToIds(z3, parameters), parameters) -
              calculate(replaceParametersToIds(z1, parameters), parameters)) +
            (calculate(replaceParametersToIds(x3, parameters), parameters) -
              calculate(replaceParametersToIds(x1, parameters), parameters)) *
              (calculate(replaceParametersToIds(z2, parameters), parameters) -
                calculate(
                  replaceParametersToIds(z1, parameters),
                  parameters
                )) ===
            0 &&
          (calculate(replaceParametersToIds(x2, parameters), parameters) -
            calculate(replaceParametersToIds(x1, parameters), parameters)) *
            (calculate(replaceParametersToIds(y3, parameters), parameters) -
              calculate(replaceParametersToIds(y1, parameters), parameters)) -
            (calculate(replaceParametersToIds(x3, parameters), parameters) -
              calculate(replaceParametersToIds(x1, parameters), parameters)) *
              (calculate(replaceParametersToIds(y2, parameters), parameters) -
                calculate(
                  replaceParametersToIds(y1, parameters),
                  parameters
                )) ===
            0) ||
          ((calculate(replaceParametersToIds(y2, parameters), parameters) -
            calculate(replaceParametersToIds(y1, parameters), parameters)) *
            (calculate(replaceParametersToIds(z3, parameters), parameters) -
              calculate(replaceParametersToIds(z1, parameters), parameters)) -
            (calculate(replaceParametersToIds(y3, parameters), parameters) -
              calculate(replaceParametersToIds(y1, parameters), parameters)) *
              (calculate(replaceParametersToIds(z2, parameters), parameters) -
                calculate(
                  replaceParametersToIds(z1, parameters),
                  parameters
                )) !==
            0 &&
            -(
              calculate(replaceParametersToIds(x2, parameters), parameters) -
              calculate(replaceParametersToIds(x1, parameters), parameters)
            ) *
              (calculate(replaceParametersToIds(z3, parameters), parameters) -
                calculate(replaceParametersToIds(z1, parameters), parameters)) +
              (calculate(replaceParametersToIds(x3, parameters), parameters) -
                calculate(replaceParametersToIds(x1, parameters), parameters)) *
                (calculate(replaceParametersToIds(z2, parameters), parameters) -
                  calculate(
                    replaceParametersToIds(z1, parameters),
                    parameters
                  )) !==
              0 &&
            (calculate(replaceParametersToIds(x2, parameters), parameters) -
              calculate(replaceParametersToIds(x1, parameters), parameters)) *
              (calculate(replaceParametersToIds(y3, parameters), parameters) -
                calculate(replaceParametersToIds(y1, parameters), parameters)) -
              (calculate(replaceParametersToIds(x3, parameters), parameters) -
                calculate(replaceParametersToIds(x1, parameters), parameters)) *
                (calculate(replaceParametersToIds(y2, parameters), parameters) -
                  calculate(
                    replaceParametersToIds(y1, parameters),
                    parameters
                  )) !==
              0)))
    ) {
      toast.error("Element vector must be parallel to the X, Y or Z axis.", {
        toastId: "error",
      });
      return;
    }

    const model = {
      id: uuid(),
      number: elementNumber,
      name:
        "Element " +
        elementNumber +
        " (" +
        calculate(replaceParametersToIds(resistance, parameters), parameters) +
        " Ω, " +
        calculate(replaceParametersToIds(inductance, parameters), parameters) +
        " H, " +
        calculate(replaceParametersToIds(capacitance, parameters), parameters) +
        " F)",
      type: "element",
      object: {
        element_type: element_type,
        resistance: replaceParametersToIds(resistance, parameters),
        inductance: replaceParametersToIds(inductance, parameters),
        capacitance: replaceParametersToIds(capacitance, parameters),
        x: {
          min: replaceParametersToIds(x1, parameters),
          max: replaceParametersToIds(x2, parameters),
          third: replaceParametersToIds(x3, parameters),
        },
        y: {
          min: replaceParametersToIds(y1, parameters),
          max: replaceParametersToIds(y2, parameters),
          third: replaceParametersToIds(y3, parameters),
        },
        z: {
          min: replaceParametersToIds(z1, parameters),
          max: replaceParametersToIds(z2, parameters),
          third: replaceParametersToIds(z3, parameters),
        },
      },
      status: "Added",
      category: "Lumped Elements",
      parentId: 0,
      visible: true,
      selected: false,
      isEditProperty: false,
      material: "PEC",
      position: {
        x: 0,
        y: 0,
        z: 0,
      },
      rotation: {
        x: 0,
        y: 0,
        z: 0,
      },
      scaling: {
        x: undefined,
        y: undefined,
        z: undefined,
      },
    };

    if (isEditableModal === undefined) {
      dispatch(modelAdded(model));
      await dispatch(
        addHistory({
          payloadData: {
            create_element: {
              number: elementNumber,
              id: model.id,
              element_type: element_type,
              resistance: replaceParametersToIds(resistance, parameters),
              inductance: replaceParametersToIds(inductance, parameters),
              capacitance: replaceParametersToIds(capacitance, parameters),
              x1: replaceParametersToIds(x1, parameters),
              y1: replaceParametersToIds(y1, parameters),
              z1: replaceParametersToIds(z1, parameters),
              x2: replaceParametersToIds(x2, parameters),
              y2: replaceParametersToIds(y2, parameters),
              z2: replaceParametersToIds(z2, parameters),
              x3: replaceParametersToIds(x3, parameters),
              y3: replaceParametersToIds(y3, parameters),
              z3: replaceParametersToIds(z3, parameters),
              material: model.material,
              parentId: model.parentId,
            },
          },
          currentUsername: username,
          projectId: projectId || "",
        })
      );
    } else {
      model.id = modelToBeAlter.id;
      model.status = "Updated";
      model.isEditProperty = true;
      // dispatch(modelAltered(model));
      await dispatch(
        addHistory({
          payloadData: {
            edit_element: {
              number: elementNumber,
              id: model.id,
              element_type: element_type,
              resistance: replaceParametersToIds(resistance, parameters),
              inductance: replaceParametersToIds(inductance, parameters),
              capacitance: replaceParametersToIds(capacitance, parameters),
              x1: replaceParametersToIds(x1, parameters),
              y1: replaceParametersToIds(y1, parameters),
              z1: replaceParametersToIds(z1, parameters),
              x2: replaceParametersToIds(x2, parameters),
              y2: replaceParametersToIds(y2, parameters),
              z2: replaceParametersToIds(z2, parameters),
              x3: replaceParametersToIds(x3, parameters),
              y3: replaceParametersToIds(y3, parameters),
              z3: replaceParametersToIds(z3, parameters),
              material: model.material,
              parentId: model.parentId,
            },
          },
          currentUsername: username,
          projectId: projectId || "",
        })
      );
      await dispatch(
        setRefresh({
          refresh: refresh + 1,
        })
      );
    }
    setVisible(false);
    dispatch(setSceneClickable(false));
  };

  const handleCancel = () => {
    setClickedButton("");
    setVisible(false);
    dispatch(setSceneClickable(false));

    setVisible(false);
    dispatch(modelRemoved(previewElementId));
    setPreviewElementId("");
  };

  useEffect(() => {
    if (visible) {
      handlePreview();
    }
  }, [
    elementNumber,
    elementPicks,
    element_type,
    resistance,
    inductance,
    capacitance,
    x1,
    x2,
    x3,
    y1,
    y2,
    y3,
    z1,
    z2,
    z3,
    isValid,
  ]);

  const handlePreview = () => {
    if (isValid === false) {
      return;
    }
    let model;
    try {
      model = {
        id: "Preview-" + uuid(),
        number: elementNumber,
        name:
          "Preview Element " +
          elementNumber +
          " (" +
          calculate(
            replaceParametersToIds(resistance, parameters),
            parameters
          ) +
          " Ω, " +
          calculate(
            replaceParametersToIds(inductance, parameters),
            parameters
          ) +
          " H, " +
          calculate(
            replaceParametersToIds(capacitance, parameters),
            parameters
          ) +
          " F)",
        type: "element",
        object: {
          element_type: element_type,
          resistance: replaceParametersToIds(resistance, parameters),
          inductance: replaceParametersToIds(inductance, parameters),
          capacitance: replaceParametersToIds(capacitance, parameters),
          x: {
            min: replaceParametersToIds(x1, parameters),
            max: replaceParametersToIds(x2, parameters),
            third: replaceParametersToIds(x3, parameters),
          },
          y: {
            min: replaceParametersToIds(y1, parameters),
            max: replaceParametersToIds(y2, parameters),
            third: replaceParametersToIds(y3, parameters),
          },
          z: {
            min: replaceParametersToIds(z1, parameters),
            max: replaceParametersToIds(z2, parameters),
            third: replaceParametersToIds(z3, parameters),
          },
        },
        status: "Added",
        category: "Preview",
        parentId: 0,
        visible: true,
        selected: false,
        isEditProperty: false,
        material: "PEC",
        position: {
          x: 0,
          y: 0,
          z: 0,
        },
        rotation: {
          x: 0,
          y: 0,
          z: 0,
        },
        scaling: {
          x: undefined,
          y: undefined,
          z: undefined,
        },
      };
    } catch (err) {
      return;
    }
    if (previewElementId === "") {
      setPreviewElementId(model.id);
      dispatch(modelAdded(model));
    } else {
      model.id = previewElementId;
      model.status = "Updated";
      model.isEditProperty = true;
      dispatch(modelAltered(model));
    }
  };

  const handleStart = (e: any) => {
    e.preventDefault();
    setClickedButton("start");
    dispatch(setSceneClickable(true));
    toast.info("To pick the start point, double click on an object's vertex.", {
      toastId: "info",
    });
  };

  const handleEnd = (e: any) => {
    e.preventDefault();
    setClickedButton("end");
    dispatch(setSceneClickable(true));
    toast.info("To pick the end point, double click on an object's vertex.", {
      toastId: "info",
    });
  };

  const handleThird = (e: any) => {
    e.preventDefault();
    setClickedButton("third");
    dispatch(setSceneClickable(true));
    toast.info(
      "To pick the offset point, double click on an object's vertex.",
      {
        toastId: "info",
      }
    );
  };

  return (
    <>
      <DraggableModal
        className={
          parameterMenuVisible ? "pointer-events-none" : "pointer-events-auto"
        }
        title={
          <div className="bg-green-300 w-full text-xl font-semibold rounded-t-md py-2 text-center border-b-2 border-primary-600">
            Lumped element
          </div>
        }
        visible={visible}
        buttonsClassName="sm:px-4"
        buttons={
          <div className="flex flex-row gap-1 w-full justify-between">
            <button
              onClick={() => {
                setParameterMenuVisible(true);
              }}
              id="add-parameter-btn"
              disabled={parameterMenuVisible || saving}
              className={`rounded text-center px-4 py-1 disable-drag bg-blue-300 enabled:hover:bg-blue-400 enabled:active:bg-blue-500 disabled:opacity-50`}
            >
              Add parameter...
            </button>
            <div className="flex gap-2">
              <button
                onClick={handleCancel}
                id="element-cancel-btn"
                disabled={saving}
                className="bg-red-300 enabled:hover:bg-red-400 enabled:active:bg-red-500 rounded text-center px-4 py-1 disable-drag disabled:opacity-50"
              >
                Cancel
              </button>
              <button
                onClick={handleOk}
                id="element-ok-btn"
                disabled={!isValid || saving}
                className={`rounded text-center px-4 py-1 disable-drag bg-green-300 enabled:hover:bg-green-400 enabled:active:bg-green-500 disabled:opacity-50`}
              >
                {saving ? (
                  <svg
                    aria-hidden="true"
                    className="w-6 h-6 text-gray-900 animate-spin dark:text-gray-600 fill-white"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                ) : (
                  "OK"
                )}
              </button>
            </div>
          </div>
        }
      >
        <form>
          <div className="mt-4 grid grid-cols-12 gap-x-6 gap-y-4">
            <div className="col-span-6">
              <label
                htmlFor="elementNumber"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Element number
              </label>
              <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
                <input
                  type="number"
                  name="elementNumber"
                  value={elementNumber}
                  onWheel={(e) => (e.target as HTMLElement).blur()}
                  onChange={handleChanges}
                  id="elementNumber"
                  autoComplete="off"
                  className="flex flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600"
                />
              </div>
            </div>
            <div className="col-span-6 accent-primary-600">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Element picks
              </label>
              <div className="flex items-center">
                <input
                  type="radio"
                  name="elementPicks"
                  value="Points"
                  id="points"
                  checked={elementPicks === "Points"}
                  onChange={handleChanges}
                  className="mr-2"
                />
                <label htmlFor="points" className="mr-4">
                  Points
                </label>
                <input
                  type="radio"
                  name="elementPicks"
                  value="Edges"
                  id="edges"
                  checked={elementPicks === "Edges"}
                  onChange={handleChanges}
                  className="mr-2"
                />
                <label htmlFor="edges">Edges</label>
              </div>
            </div>

            <div className="accent-primary-600 col-span-6">
              <label
                htmlFor="element_type"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Type
              </label>
              <label>
                <input
                  type="radio"
                  name="element_type"
                  value="serial"
                  checked={element_type === "serial"}
                  onChange={handleChanges}
                  className="mr-2 disable-drag"
                />
                Serial
              </label>
              <label>
                <input
                  type="radio"
                  name="element_type"
                  value="parallel"
                  checked={element_type === "parallel"}
                  onChange={handleChanges}
                  className="ml-4 mr-2 disable-drag"
                />
                Parallel
              </label>
            </div>
            <div className="col-span-full">
              <label
                htmlFor="resistance"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Resistance
              </label>
              <div className="flex items-center rounded-md sm:max-w-lg">
                <input
                  type="text"
                  name="resistance"
                  value={resistance}
                  onChange={handleChanges}
                  id="resistance"
                  autoComplete="off"
                  className="w-full block flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag"
                />
                <label style={{ marginLeft: "0.5rem" }}>Ω</label>
              </div>
            </div>
            <div className="col-span-full">
              <label
                htmlFor="inductance"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Inductance
              </label>
              <div className="flex items-center rounded-md sm:max-w-lg">
                <input
                  type="text"
                  name="inductance"
                  value={inductance}
                  onChange={handleChanges}
                  id="inductance"
                  autoComplete="off"
                  className="w-full block flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag"
                />
                <label style={{ marginLeft: "0.5rem" }}>H</label>
              </div>
            </div>
            <div className="col-span-full">
              <label
                htmlFor="capacitance"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Capacitance
              </label>
              <div className="flex items-center rounded-md sm:max-w-lg">
                <input
                  type="text"
                  name="capacitance"
                  value={capacitance}
                  onChange={handleChanges}
                  id="capacitance"
                  autoComplete="off"
                  className="w-full block flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag"
                />
                <label style={{ marginLeft: "0.5rem" }}>F</label>
              </div>
            </div>
            {elementPicks === "Edges" ? (
              <div className="col-span-full mb-0 pb-0">
                <div className="relative flex items-center">
                  <div className="flex-grow border-t border-gray-400"></div>
                  <div className="flex-grow border-t border-gray-400"></div>
                  <div className="flex-grow border-t border-gray-400"></div>
                  <span className="flex-shrink mx-4 text-gray-400">Edge A</span>
                  <div className="flex-grow border-t border-gray-400"></div>
                  <div className="flex-grow border-t border-gray-400"></div>
                  <div className="flex-grow border-t border-gray-400"></div>
                  <div className="flex-grow border-t border-gray-400"></div>
                  <span className="flex-shrink mx-4 text-gray-400">Edge B</span>
                  <div className="flex-grow border-t border-gray-400"></div>
                </div>
              </div>
            ) : (
              <div className="col-span-full">
                <div className="relative flex items-center">
                  <div className="flex-grow border-t border-gray-400"></div>
                  <div className="flex-grow border-t border-gray-400"></div>
                </div>
              </div>
            )}
          </div>
          <div className="mt-4 grid grid-cols-12 gap-x-6 gap-y-4">
            <div className={`col-span-${elementPicks === "Points" ? 6 : 4}`}>
              <div className="flex rounded-md sm:max-w-lg ring-1 ring-inset disable-drag">
                <button
                  className={`block flex-1 py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 rounded-md sm:max-w-lg ring-1 ring-inset ring-primary-600
                  ${
                    clickedButton !== "start"
                      ? "bg-teal-400 hover:bg-teal-500 active:bg-teal-600 hover:transition duration-150"
                      : "bg-[#D9D9D9]"
                  }`}
                  onClick={handleStart}
                  disabled={clickedButton === "start"}
                  type="button"
                >
                  Pick start point
                </button>
              </div>
            </div>
            <div className={`col-span-${elementPicks === "Points" ? 6 : 4}`}>
              <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
                <button
                  className={`block flex-1 py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 rounded-md sm:max-w-lg ring-1 ring-inset ring-primary-600
                  ${
                    clickedButton !== "end"
                      ? "bg-teal-400 hover:bg-teal-500 active:bg-teal-600 hover:transition duration-150"
                      : "bg-[#D9D9D9]"
                  }`}
                  onClick={handleEnd}
                  disabled={clickedButton === "end"}
                  type="button"
                >
                  Pick end point
                </button>
              </div>
            </div>
            {elementPicks === "Edges" && (
              <>
                <div className="col-span-4">
                  <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
                    <button
                      className={`block flex-1 py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 rounded-md sm:max-w-lg ring-1 ring-inset ring-primary-600
                  ${
                    clickedButton !== "third"
                      ? "bg-teal-400 hover:bg-teal-500 active:bg-teal-600 hover:transition duration-150"
                      : "bg-[#D9D9D9]"
                  }`}
                      onClick={handleThird}
                      disabled={clickedButton === "third"}
                      type="button"
                    >
                      Pick offset point
                    </button>
                  </div>
                </div>
              </>
            )}
            <div className="col-span-full mb-0 pb-0">
              <div className="relative flex items-center">
                <div className="flex-grow border-t border-gray-400"></div>
                <span className="flex-shrink mx-4 text-gray-400">
                  Or set manually
                </span>
                <div className="flex-grow border-t border-gray-400"></div>
              </div>
            </div>
            <div className={`col-span-${elementPicks === "Points" ? 6 : 4}`}>
              <label
                htmlFor="x1"
                className="block text-sm font-medium text-gray-900"
              >
                X1
              </label>
              <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
                <input
                  type="text"
                  name="x1"
                  value={x1}
                  onChange={handleChanges}
                  disabled={clickedButton === "start"}
                  id="x1"
                  autoComplete="off"
                  className={`w-full block flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600
                  ${clickedButton !== "start" ? "opacity-100" : "opacity-50"}
                `}
                />
              </div>
            </div>
            <div className={`col-span-${elementPicks === "Points" ? 6 : 4}`}>
              <label
                htmlFor="x2"
                className="block text-sm font-medium text-gray-900"
              >
                X2
              </label>
              <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
                <input
                  type="text"
                  name="x2"
                  value={x2}
                  onChange={handleChanges}
                  disabled={clickedButton === "end"}
                  id="x2"
                  autoComplete="off"
                  className={`w-full block flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600
                  ${clickedButton !== "end" ? "opacity-100" : "opacity-50"}
                `}
                />
              </div>
            </div>
            {elementPicks === "Edges" && (
              <div className="col-span-4">
                <label
                  htmlFor="x3"
                  className="block text-sm font-medium text-gray-900"
                >
                  X3
                </label>
                <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
                  <input
                    type="text"
                    name="x3"
                    value={x3}
                    onChange={handleChanges}
                    disabled={clickedButton === "third"}
                    id="x3"
                    autoComplete="off"
                    className={`w-full block flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600
                  ${clickedButton !== "third" ? "opacity-100" : "opacity-50"}
                `}
                  />
                </div>
              </div>
            )}
            <div className={`col-span-${elementPicks === "Points" ? 6 : 4}`}>
              <label
                htmlFor="y1"
                className="block text-sm font-medium text-gray-900"
              >
                Y1
              </label>
              <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
                <input
                  type="text"
                  name="y1"
                  value={y1}
                  onChange={handleChanges}
                  disabled={clickedButton === "start"}
                  id="y1"
                  autoComplete="off"
                  className={`w-full block flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600
                  ${clickedButton !== "start" ? "opacity-100" : "opacity-50"}
                `}
                />
              </div>
            </div>
            <div className={`col-span-${elementPicks === "Points" ? 6 : 4}`}>
              <label
                htmlFor="y2"
                className="block text-sm font-medium text-gray-900"
              >
                Y2
              </label>
              <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
                <input
                  type="text"
                  name="y2"
                  value={y2}
                  onChange={handleChanges}
                  disabled={clickedButton === "end"}
                  id="y2"
                  autoComplete="off"
                  className={`w-full block flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600
                  ${clickedButton !== "end" ? "opacity-100" : "opacity-50"}
                `}
                />
              </div>
            </div>
            {elementPicks === "Edges" && (
              <div className="col-span-4">
                <label
                  htmlFor="y3"
                  className="block text-sm font-medium text-gray-900"
                >
                  Y3
                </label>
                <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
                  <input
                    type="text"
                    name="y3"
                    value={y3}
                    onChange={handleChanges}
                    disabled={clickedButton === "third"}
                    id="y3"
                    autoComplete="off"
                    className={`w-full block flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600
                  ${clickedButton !== "third" ? "opacity-100" : "opacity-50"}
                `}
                  />
                </div>
              </div>
            )}
            <div className={`col-span-${elementPicks === "Points" ? 6 : 4}`}>
              <label
                htmlFor="z1"
                className="block text-sm font-medium text-gray-900"
              >
                Z1
              </label>
              <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
                <input
                  type="text"
                  name="z1"
                  value={z1}
                  onChange={handleChanges}
                  disabled={clickedButton === "start"}
                  id="z1"
                  autoComplete="off"
                  className={`w-full block flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600
                  ${clickedButton !== "start" ? "opacity-100" : "opacity-50"}
                `}
                />
              </div>
            </div>
            <div className={`col-span-${elementPicks === "Points" ? 6 : 4}`}>
              <label
                htmlFor="z2"
                className="block text-sm font-medium text-gray-900"
              >
                Z2
              </label>
              <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
                <input
                  type="text"
                  name="z2"
                  value={z2}
                  onChange={handleChanges}
                  disabled={clickedButton === "end"}
                  id="z2"
                  autoComplete="off"
                  className={`w-full block flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600
                  ${clickedButton !== "end" ? "opacity-100" : "opacity-50"}
                `}
                />
              </div>
            </div>
            {elementPicks === "Edges" && (
              <div className="col-span-4">
                <label
                  htmlFor="z3"
                  className="block text-sm font-medium text-gray-900"
                >
                  Z3
                </label>
                <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
                  <input
                    type="text"
                    name="z3"
                    value={z3}
                    onChange={handleChanges}
                    disabled={clickedButton === "third"}
                    id="z3"
                    autoComplete="off"
                    className={`w-full block flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600
                  ${clickedButton !== "third" ? "opacity-100" : "opacity-50"}
                `}
                  />
                </div>
              </div>
            )}
          </div>
        </form>
      </DraggableModal>
      {parameterMenuVisible && (
        <ParameterMenu
          visible={parameterMenuVisible}
          setVisible={setParameterMenuVisible}
          isEditable={false}
          isNewParameter={true}
          obj={parameter}
        />
      )}
    </>
  );
}

export default LumpedElementMenu;
