import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MyIcon from "assets/MyIcons";
import { Auth, Storage } from "aws-amplify";
import DeleteProjectModal from "components/Modals/DeleteProject";
import NewProjectModal from "components/Modals/NewProject";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "react-tooltip";
import api from "services/api";
import { useAppSelector } from "state/hooks";
import {
  selectEmail,
  selectUserPlan,
  selectUsername,
} from "state/reducers/authSlice";
import { v4 as uuid } from "uuid";

function Projects() {
  const [newProjectModalVisible, setNewProjectModalVisible] = useState(false);
  const [deleteProjectModalVisible, setDeleteProjectModalVisible] =
    useState(false);
  const [projectIdToDelete, setProjectIdToDelete] = useState<any>(null);
  const [projectNameToDelete, setProjectNameToDelete] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isQuerying, setIsQuerying] = useState(true);
  const [projects, setProjects] = useState<Array<any>>([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState<Array<any>>([]);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [statusFilters, setStatusFilters] = useState<Array<string>>([
    "Idle",
    "Energy",
    "Completed",
    "Terminated",
  ]);

  const [tableRows, setTableRows] = useState(4);

  const tableRef = useRef<HTMLTableElement>(null);
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);
  const tablePaginationRef = useRef<HTMLDivElement>(null);

  const toggleFilter = (status: string) => {
    if (statusFilters.includes(status)) {
      setStatusFilters((prev) => prev.filter((s) => s !== status));
    } else {
      setStatusFilters((prev) => [...prev, status]);
    }
  };

  const createNewProject = () => {
    setNewProjectModalVisible(true);
  };

  const navigate = useNavigate();
  const email = useAppSelector(selectEmail);
  const userPlan = useAppSelector(selectUserPlan);
  const userName = useAppSelector(selectUsername);

  useEffect(() => {
    const tableHeight = tableRef.current?.clientHeight;
    const tableHeadHeight = tableHeadRef.current?.clientHeight;
    const tablePaginationHeight = tablePaginationRef.current?.clientHeight;
    const tableRowHeight =
      document.getElementById("table-row")?.clientHeight || 72;

    if (
      tableHeight &&
      tableHeadHeight &&
      tablePaginationHeight &&
      tableRowHeight
    ) {
      let rows = Math.floor(
        (tableHeight - tableHeadHeight - (tablePaginationHeight || 0)) /
          (tableRowHeight + 5) -
          1
      );

      if (rows > 10) {
        rows = 10;
      }

      setTableRows(rows > 4 ? rows : 4);
    }
  }, [filteredProjects, tableRef, tableHeadRef, tablePaginationRef, tableRows]);

  useEffect(() => {
    try {
      Storage.list(`${userName}/projects/`, { pageSize: 1000 }).then(
        async (data) => {
          let projectData: any[] = [];
          const { results } = data;
          const projects: any[] = [];
          results.forEach((result) => {
            if (result.key?.includes("info.json")) {
              projects.push(result);
            }
          });
          const promises = projects.map(async (project) => {
            let info: any = {};
            const infoData = await Storage.get(project.key, {
              download: true,
              cacheControl: "no-cache",
            });
            if (infoData.Body) {
              const dataBody: any = infoData.Body;
              const dataString = await dataBody.text();
              const json = JSON.parse(dataString);
              info = json;
            }
            const projectData = {
              id: project.key.split("/").at(-2),
              lastModified: project.lastModified,
              ...info,
            };
            return projectData;
          });
          projectData = await Promise.all(promises);

          projectData.sort(
            (a, b) =>
              new Date(b.lastModified).getTime() -
              new Date(a.lastModified).getTime()
          );

          setProjects(projectData);
          setIsQuerying(false);
        }
      );
    } catch (e) {
      console.log(e);
    }
  }, [userName]);

  useEffect(() => {
    const filterProjects = async () => {
      let filteredProjects = searchTerm
        ? projects.filter(
            (project) =>
              project.project_name &&
              project.project_name
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
          )
        : projects;

      // Further filter by status if any filters are selected
      if (statusFilters.length > 0) {
        filteredProjects = filteredProjects.filter((project) => {
          if (
            statusFilters.includes("Idle") &&
            project.status.includes("Idle")
          ) {
            return true;
          }
          if (
            statusFilters.includes("Completed") &&
            project.status.includes("Completed")
          ) {
            return true;
          }
          if (
            statusFilters.includes("Terminated") &&
            (project.status.includes("Terminated") ||
              project.status.includes("Failed"))
          ) {
            return true;
          }
          if (
            statusFilters.includes("Energy") &&
            !project.status.includes("Idle") &&
            !project.status.includes("Completed") &&
            !project.status.includes("Terminated") &&
            !project.status.includes("Failed")
          ) {
            return true;
          }
          return false;
        });
      }

      setFilteredProjects(filteredProjects);
    };
    filterProjects();
  }, [searchTerm, projects, statusFilters]);

  const openProject = (id: string) => {
    window.location.href = `/project/${id}`;
  };

  function downloadBlob(blob: any, filename: any) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  const downloadPdf = async (
    projectId: string,
    version: any,
    username: any
  ) => {
    try {
      const result: { [key: string]: any } = await Storage.list(
        `${username}/projects/${projectId}/${version}/results/`,
        { pageSize: 1000 }
      );
      const list = result.results || [];
      const pdfPattern = /\.pdf$/i; // Regular expression for .pdf
      let pdfExists = false;
      const files = await Promise.all(
        list.map(async (item: { key: string }, index: number) => {
          if (pdfPattern.test(item.key)) {
            const data = await Storage.get(item.key, {
              download: true,
              cacheControl: "no-cache",
            });
            const fileName = item.key.split("/").pop();
            downloadBlob(data.Body, fileName);
            pdfExists = true;
            return;
          }
        })
      );
      if (pdfExists) {
        toast.success("PDF Report downloaded successfully.");
      } else {
        toast.error("PDF Report not available for this project.");
      }
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const duplicateProject = async (projectId: string, username: string) => {
    try {
      // show toast loading
      toast.promise(new Promise((resolve, reject) => {}), {
        pending: "Duplicating project...",
        success: "Project duplicated successfully.",
        error: "Error duplicating project.",
      });
      const sourcePrefix = `${username}/projects/${projectId}/`;
      const targetPrefix = `${username}/projects/${uuid()}/`;
      console.log(sourcePrefix);

      // Recursively list all files in the source directory
      const listAllFiles = async (prefix: string) => {
        let files: { key: string }[] = [];
        let nextToken: string | undefined;

        do {
          const { results, nextToken: newNextToken } = await Storage.list(
            prefix,
            {
              pageSize: 1000,
              nextToken,
            }
          );

          // Add the listed files to the array
          files = files.concat(results.map((item: any) => ({ key: item.key })));
          nextToken = newNextToken;
        } while (nextToken);

        return files;
      };

      const filesToDuplicate = await listAllFiles(sourcePrefix);

      // Copy each file to the new directory
      const copyPromises = filesToDuplicate.map(async (file) => {
        const targetKey = file.key.replace(sourcePrefix, targetPrefix);
        return Storage.copy({ key: file.key }, { key: targetKey });
      });

      await Promise.all(copyPromises);

      window.location.reload();
    } catch (err) {
      toast.dismiss();
      toast.error("Error duplicating project: " + err);
    }
  };

  useEffect(() => {
    document.title = `Projects | blit.ai`;
  });

  // Warm up lambda functions and instances
  useEffect(() => {
    api.post("/step2stl", { warm_up: 1 }).catch(() => {});
    api.post("/generate_mesh", { warm_up: 1 }).catch(() => {});
    api.post("/classify_mesh", { warm_up: 1 }).catch(() => {});
    api.post("/boolean_operations", { warm_up: 1 }).catch(() => {});
    api.post("/run_simulation", { warm_up: 1 }).catch(() => {});
  }, []);

  function getStatusColor(
    status: string,
    percentage: number,
    tailwind: boolean = false
  ) {
    if (status.includes("Idle")) {
      return tailwind ? "gray" : "#344054";
    } else if (status.includes("Completed") && percentage === 100) {
      return tailwind ? "success" : "#027A48";
    } else if (
      (status.includes("Terminated") || status.includes("Failed")) &&
      percentage === 100
    ) {
      return tailwind ? "error" : "#B42318";
    } else {
      return tailwind ? "warning" : "#B54708";
    }
  }

  // CHANGE OF ROUTE FOR MOBILE
  const [isWideScreen, setIsWideScreen] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 1000);
    };

    handleResize(); // Initial check on component mount

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="bg-gray-100 bg-gray-500 bg-success-100 bg-success-500 bg-error-100 bg-error-500 bg-warning-100 bg-warning-500"></div>
      <div className="flex w-full h-full overflow-hidden">
        <div
          className={
            openMobileMenu
              ? "fixed min-[1200px]:relative translate-x-0 min-[1200px]:translate-x-0 z-40 transition-all duration-300"
              : "fixed min-[1200px]:relative -translate-x-full min-[1200px]:translate-x-0 z-40 transition-all duration-300"
          }
        >
          {/* SIDEBAR */}
          <aside
            className="font-inter w-80 min-h-screen overflow-y-auto touch-auto border-r-2 border-gray-200 z-30 relative"
            aria-label="Sidebar"
          >
            <div className="overflow-y-auto bg-white flex flex-col h-screen">
              {/* LOGO */}
              <div className="pt-[27px] mx-auto">
                <MyIcon name="blit-logo" />
              </div>

              <div className="flex flex-col justify-between h-full">
                {/* LINKS LIST */}
                <ul className="pt-9 px-4 space-y-1">
                  {/* LINK */}
                  <li>
                    <a
                      href="home"
                      className="hover:bg-gray-50 active:bg-gray-100 px-3 py-2 flex items-center justify-between rounded-md"
                    >
                      <span className="flex items-center">
                        <div className="w-6 h-6">
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mx-auto"
                          >
                            <path
                              d="M6 16H14M9.0177 1.76403L2.23539 7.03916C1.78202 7.39178 1.55534 7.56809 1.39203 7.78889C1.24737 7.98447 1.1396 8.20481 1.07403 8.43908C1 8.70355 1 8.99073 1 9.56508V16.8C1 17.9201 1 18.4802 1.21799 18.908C1.40973 19.2843 1.71569 19.5903 2.09202 19.782C2.51984 20 3.07989 20 4.2 20H15.8C16.9201 20 17.4802 20 17.908 19.782C18.2843 19.5903 18.5903 19.2843 18.782 18.908C19 18.4802 19 17.9201 19 16.8V9.56508C19 8.99073 19 8.70355 18.926 8.43908C18.8604 8.20481 18.7526 7.98447 18.608 7.78889C18.4447 7.56809 18.218 7.39178 17.7646 7.03916L10.9823 1.76403C10.631 1.49078 10.4553 1.35415 10.2613 1.30163C10.0902 1.25529 9.9098 1.25529 9.73865 1.30163C9.54468 1.35415 9.36902 1.49078 9.0177 1.76403Z"
                              stroke="#667085"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>

                        <span className="ml-3 text-md font-semibold text-gray-700">
                          Home
                        </span>
                      </span>
                    </a>
                  </li>

                  {/* LINK */}
                  <li>
                    <a
                      href="overview"
                      className="hover:bg-gray-50 active:bg-gray-100 px-3 py-2 flex items-center justify-between rounded-md"
                    >
                      <span className="flex items-center">
                        <div className="w-6 h-5">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mx-auto"
                          >
                            <path
                              d="M6 13V15M10 9V15M14 5V15M5.8 19H14.2C15.8802 19 16.7202 19 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C19 16.7202 19 15.8802 19 14.2V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V14.2C1 15.8802 1 16.7202 1.32698 17.362C1.6146 17.9265 2.07354 18.3854 2.63803 18.673C3.27976 19 4.11984 19 5.8 19Z"
                              stroke="#667085"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>

                        <span className="ml-3 text-md font-semibold text-gray-700">
                          Overview
                        </span>
                      </span>
                    </a>
                  </li>

                  {/* LINK */}
                  <li>
                    <a
                      href="projects"
                      className="bg-gray-50 hover:bg-gray-100 active:bg-gray-200 px-3 py-2 flex items-center justify-between rounded-md"
                    >
                      <span className="flex items-center">
                        <div className="w-6 h-6">
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mx-auto"
                          >
                            <path
                              d="M1 11L10.6422 15.8211C10.7734 15.8867 10.839 15.9195 10.9078 15.9324C10.9687 15.9438 11.0313 15.9438 11.0922 15.9324C11.161 15.9195 11.2266 15.8867 11.3578 15.8211L21 11M1 16L10.6422 20.8211C10.7734 20.8867 10.839 20.9195 10.9078 20.9324C10.9687 20.9438 11.0313 20.9438 11.0922 20.9324C11.161 20.9195 11.2266 20.8867 11.3578 20.8211L21 16M1 6L10.6422 1.17889C10.7734 1.1133 10.839 1.0805 10.9078 1.0676C10.9687 1.05616 11.0313 1.05616 11.0922 1.0676C11.161 1.0805 11.2266 1.1133 11.3578 1.17889L21 6L11.3578 10.8211C11.2266 10.8867 11.161 10.9195 11.0922 10.9324C11.0313 10.9438 10.9687 10.9438 10.9078 10.9324C10.839 10.9195 10.7734 10.8867 10.6422 10.8211L1 6Z"
                              stroke="black"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>

                        <span className="ml-3 text-md font-semibold text-gray-900">
                          Projects
                        </span>
                      </span>

                      <div className=" w-6 h-6 flex items-center justify-center rounded-full bg-gray-100 text-gray-700 text-xs font-medium">
                        {isQuerying ? "-" : projects.length}
                      </div>
                    </a>
                  </li>
                </ul>

                {/* FOOTER */}
                <footer className="flex flex-col px-4 pb-8 space-y-6">
                  <ul className="space-y-1">
                    {/* LINK */}
                    <li>
                      <a
                        href="#abc"
                        className="hover:bg-gray-50 active:bg-gray-100 px-3 py-2 flex items-center justify-between rounded-md"
                      >
                        <span className="flex items-center">
                          <div className="w-6 h-6">
                            <svg
                              width="20"
                              height="22"
                              viewBox="0 0 20 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="mx-auto"
                            >
                              <path
                                d="M18.7778 21C18.7778 19.4494 18.7778 18.6741 18.5864 18.0432C18.1555 16.6227 17.0439 15.5112 15.6235 15.0803C14.9926 14.8889 14.2173 14.8889 12.6667 14.8889H7.11112C5.56049 14.8889 4.78517 14.8889 4.15429 15.0803C2.73384 15.5112 1.62227 16.6227 1.19138 18.0432C1 18.6741 1 19.4494 1 21M14.8889 6C14.8889 8.76142 12.6503 11 9.88889 11C7.12747 11 4.88889 8.76142 4.88889 6C4.88889 3.23858 7.12747 1 9.88889 1C12.6503 1 14.8889 3.23858 14.8889 6Z"
                                stroke="#667085"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>

                          <span className="ml-3 text-md font-semibold text-gray-700">
                            Account
                          </span>
                        </span>
                      </a>
                    </li>

                    {/* LINK */}
                    <li>
                      <a
                        href="#abc"
                        className="hover:bg-gray-50 active:bg-gray-100 px-3 py-2 flex items-center justify-between rounded-md"
                      >
                        <span className="flex items-center">
                          <div className="w-6 h-6">
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="mx-auto"
                            >
                              <path
                                d="M8.13626 8.13628L3.92893 3.92896M3.92893 18.0711L8.16797 13.8321M13.8611 13.8638L18.0684 18.0711M18.0684 3.92896L13.8287 8.16862M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11ZM15 11C15 13.2091 13.2091 15 11 15C8.79086 15 7 13.2091 7 11C7 8.79086 8.79086 7 11 7C13.2091 7 15 8.79086 15 11Z"
                                stroke="#667085"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>

                          <span className="ml-3 text-md font-semibold text-gray-700">
                            Support
                          </span>
                        </span>
                      </a>
                    </li>
                  </ul>
                  <div className="pt-6 border-t border-gray-200 pl-2 pr-2.5">
                    <div className="flex justify-between">
                      <div className="flex flex-col text-sm">
                        <h6 className="font-semibold text-gray-700">
                          {userPlan || "Unknown plan"}
                        </h6>
                        <h6 className="text-gray-600">{email}</h6>
                      </div>
                      <button
                        className="w-5 h-5"
                        onClick={() => {
                          setIsLoading(true);
                          Auth.signOut().then(() => {
                            localStorage.clear();
                            setIsLoading(false);
                            navigate("/", { replace: true });
                          });
                        }}
                      >
                        {isLoading ? (
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5 animate-spin fill-[#7f56d9] text-white"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        ) : (
                          <>
                            <span
                              data-tooltip-id="logout-tooltip"
                              data-tooltip-content="Log out"
                            >
                              <svg
                                className="w-4 h-4"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.3333 13.1667L16.5 9M16.5 9L12.3333 4.83333M16.5 9H6.5M6.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5H6.5"
                                  stroke="#667085"
                                  strokeWidth="1.66667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                            <Tooltip id="logout-tooltip" />
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </aside>

          <div
            style={{ display: openMobileMenu ? "flex" : "none" }}
            className="z-40 absolute top-0 -right-[30px] bg-white border-r-white border-2 border-[#EAECF0] w-8 h-8 -scale-100 flex min-[1586px]:hidden items-center justify-center rounded-tl-md rounded-bl-md"
            onClick={() => setOpenMobileMenu(false)}
          >
            <MyIcon name="right-arrow" color="#667085" />
          </div>

          <div
            style={{ display: openMobileMenu ? "block" : "none" }}
            className="absolute inset-0 w-[800vw] h-screen bg-black opacity-40"
            onClick={() => setOpenMobileMenu(false)}
          ></div>
        </div>

        <div className="bg-gray-25 w-full flex flex-col min-h-full">
          {/* HEADER */}
          <div className="py-8">
            <div className="px-4 md:px-8 flex flex-col space-y-5">
              <div className="flex items-center justify-start gap-4">
                <div
                  className="min-[1200px]:hidden flex items-center justify-center cursor-pointer"
                  onClick={() => setOpenMobileMenu(true)}
                >
                  <MyIcon name="menu" />
                </div>
                {/* BREADCRUMB */}
                <div className="flex items-center space-x-2">
                  <div className="w-7 h-7 flex items-center justify-center">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mx-auto"
                    >
                      <path
                        d="M6 16H14M9.0177 1.76403L2.23539 7.03916C1.78202 7.39178 1.55534 7.56809 1.39203 7.78889C1.24737 7.98447 1.1396 8.20481 1.07403 8.43908C1 8.70355 1 8.99073 1 9.56508V16.8C1 17.9201 1 18.4802 1.21799 18.908C1.40973 19.2843 1.71569 19.5903 2.09202 19.782C2.51984 20 3.07989 20 4.2 20H15.8C16.9201 20 17.4802 20 17.908 19.782C18.2843 19.5903 18.5903 19.2843 18.782 18.908C19 18.4802 19 17.9201 19 16.8V9.56508C19 8.99073 19 8.70355 18.926 8.43908C18.8604 8.20481 18.7526 7.98447 18.608 7.78889C18.4447 7.56809 18.218 7.39178 17.7646 7.03916L10.9823 1.76403C10.631 1.49078 10.4553 1.35415 10.2613 1.30163C10.0902 1.25529 9.9098 1.25529 9.73865 1.30163C9.54468 1.35415 9.36902 1.49078 9.0177 1.76403Z"
                        stroke="#667085"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>

                  <div className="pr-1 w-4 h-4 flex items-center justify-center">
                    <svg
                      width="6"
                      height="10"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mx-auto"
                    >
                      <path
                        d="M1 9L5 5L1 1"
                        stroke="#D0D5DD"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>

                  <span className="my-auto px-2 py-1 flex text-sm items-center bg-grayIron-100 justify-center rounded focus:outline-none text-gray-600 font-semibold">
                    Projects
                  </span>
                </div>
              </div>

              {/* CONTENT */}
              <div className="flex flex-col space-y-1">
                <h4 className=" text-tsm text-gray-900 font-semibold">
                  Projects
                </h4>
                <h6 className=" text-md text-gray-600">
                  View your simulation projects.
                </h6>
              </div>

              <div className="border-b border-gray-200"></div>
            </div>
          </div>

          {/* SECTION */}
          <div className="px-4 md:px-8 pb-6 flex flex-col h-full space-y-6">
            {/* CONTENT */}
            <div className="flex items-center gap-3 justify-between">
              <div className="w-full max-w-[400px] h-11 relative bg-white border border-gray-300 shadow-xs rounded-lg">
                <div className="w-5 h-5 absolute top-[11px] left-[14px] pointer-events-none">
                  <svg
                    width="18"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 19L14.65 14.65M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
                      stroke="#667085"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <input
                  className="w-full h-full rounded-lg pl-[42px] pr-[14px] py-2.5 text-md text-gray-600 placeholder:text-gray-500 focus:ring-gray-300 focus:border-gray-300 focus-visible:ring-gray-300 focus-visible:outline-0"
                  placeholder="Search for a project"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onWaiting={(e) => setSearchTerm("")}
                />
              </div>
              <div className="relative inline-block text-left">
                <button
                  className="w-[103px] h-10 bg-white border border-gray-300 shadow-xs rounded-lg flex items-center justify-center hover:bg-gray-50 active:bg-gray-200"
                  onClick={() => setFiltersVisible(!filtersVisible)}
                >
                  <span className="w-5 h-5 flex items-center justify-center">
                    <svg
                      width="18"
                      height="12"
                      viewBox="0 0 18 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mx-auto"
                    >
                      <path
                        d="M4 6H14M1.5 1H16.5M6.5 11H11.5"
                        stroke="#344054"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="text-sm text-gray-700 font-semibold ml-2">
                    Filters
                  </span>
                </button>
                {filtersVisible && (
                  <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      {["Idle", "Energy", "Completed", "Terminated"].map(
                        (status) => (
                          <label className="cursor-pointer">
                            <div
                              key={status}
                              className="px-4 py-1 flex items-center hover:bg-gray-50 active:bg-gray-100"
                            >
                              <input
                                type="checkbox"
                                className={`mr-3 ${
                                  status === "Idle"
                                    ? "accent-gray-500"
                                    : status === "Energy"
                                    ? "accent-warning-600"
                                    : status === "Completed"
                                    ? "accent-success-600"
                                    : "accent-error-600"
                                }`}
                                checked={statusFilters.includes(status)}
                                onChange={() => toggleFilter(status)}
                              />
                              {status.replace("Energy", "Running")}
                            </div>
                          </label>
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* TABLE */}
            <div className="h-full">
              {/* CARD HEADER */}
              <div className="bg-white px-6 py-5 rounded-t-xl shadow-sm border border-b-0 border-gray-200">
                <div className="flex items-center justify-between">
                  <h5 className=" text-lg text-gray-900 font-semibold">
                    All projects
                  </h5>
                  <button
                    className="w-[142px] h-10 bg-primary-600 hover:bg-primary-700 active:bg-primary-800 hover:transition duration-150 shadow-sm hover:shadow-primary-600/50 rounded-lg flex items-center justify-center"
                    onClick={createNewProject}
                  >
                    <span className="w-5 h-5 flex items-center justify-center">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mx-auto"
                      >
                        <path
                          d="M7.00002 1.16667V12.8333M1.16669 7H12.8334"
                          stroke="white"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span className="text-sm text-white font-semibold ml-2">
                      New project
                    </span>
                  </button>
                </div>
              </div>

              <div ref={tableRef} style={{ minHeight: "100%" }}>
                <TableContainer className="bg-white border border-gray-200 shadow-sm rounded-xl rounded-t-none">
                  <Table style={{ minHeight: "100%" }}>
                    <TableHead
                      ref={tableHeadRef}
                      className="bg-gray-50 border-b border-gray-200"
                    >
                      <TableRow className="cursor-default">
                        <TableCell
                          className="cursor-auto"
                          width="17%"
                          style={{
                            padding: "4px 23px",
                            fontSize: "13px",
                            fontFamily: "Inter",
                            fontWeight: "600",
                            height: "44px",
                            color: "#475467",
                          }}
                        >
                          <div style={{ inlineSize: "max-content" }}>
                            Project name
                          </div>
                        </TableCell>
                        <TableCell
                          className="cursor-auto"
                          width="12%"
                          style={{
                            padding: "4px 16px",
                            fontSize: "13px",
                            fontFamily: "Inter",
                            fontWeight: "600",
                            height: "44px",
                            color: "#475467",
                          }}
                        >
                          <div style={{ inlineSize: "max-content" }}>Type</div>
                        </TableCell>
                        <TableCell
                          className="cursor-auto"
                          width="17%"
                          style={{
                            padding: "4px 16px",
                            fontSize: "13px",
                            fontFamily: "Inter",
                            fontWeight: "600",
                            height: "44px",
                            color: "#475467",
                          }}
                        >
                          <div style={{ inlineSize: "max-content" }}>
                            Date created
                          </div>
                        </TableCell>
                        <TableCell
                          className="cursor-auto"
                          width="12%"
                          style={{
                            padding: "4px 16px",
                            fontSize: "13px",
                            fontFamily: "Inter",
                            fontWeight: "600",
                            height: "44px",
                            color: "#475467",
                          }}
                        >
                          <div style={{ inlineSize: "max-content" }}>
                            Recent activity
                          </div>
                        </TableCell>
                        <TableCell
                          className="cursor-auto"
                          width="12%"
                          style={{
                            padding: "4px 16px",
                            fontSize: "13px",
                            fontFamily: "Inter",
                            fontWeight: "600",
                            height: "44px",
                            color: "#475467",
                          }}
                        >
                          <div style={{ inlineSize: "max-content" }}>
                            Status
                          </div>
                        </TableCell>
                        <TableCell
                          className="cursor-auto"
                          width="12%"
                          style={{
                            padding: "4px 38px",
                            fontSize: "13px",
                            fontFamily: "Inter",
                            fontWeight: "600",
                            height: "44px",
                            color: "#475467",
                          }}
                        >
                          <div style={{ inlineSize: "max-content" }}>
                            Actions
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ height: "100%" }}>
                      {isQuerying ? (
                        Array.from({ length: tableRows }).map((_, index) => (
                          <TableRow key={index} className="cursor-default">
                            <TableCell
                              style={{
                                padding: "4px 24px",
                                fontSize: "0.875rem",
                                fontFamily: "Inter",
                                height: "44px",
                                color: "#475467",
                              }}
                            >
                              <div className="animate-pulse items-center">
                                <div className="my-2 h-4 bg-gray-300 rounded-md w-20"></div>
                                <div className="mb-1 h-3 bg-gray-300 rounded-md w-28"></div>
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Inter",
                                height: "44px",
                                color: "#475467",
                              }}
                            >
                              <div className="animate-pulse items-center">
                                <div className="my-3 h-4 bg-gray-300 rounded-md w-24"></div>
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Inter",
                                height: "44px",
                                color: "#475467",
                              }}
                            >
                              <div className="animate-pulse items-center">
                                <div className="my-3 h-4 bg-gray-300 rounded-md w-40"></div>
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Inter",
                                height: "44px",
                                color: "#475467",
                              }}
                            >
                              <div className="animate-pulse items-center">
                                <div className="my-3 h-4 bg-gray-300 rounded-md w-20"></div>
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Inter",
                                height: "44px",
                                color: "#475467",
                              }}
                            >
                              <div className="animate-pulse items-center">
                                <div className="my-3 h-4 bg-gray-300 rounded-md w-24"></div>
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Inter",
                                height: "44px",
                                color: "#475467",
                              }}
                            >
                              <div className="flex animate-pulse items-center gap-2">
                                <div className="h-6 bg-gray-300 rounded-md w-6"></div>
                                <div className="h-6 bg-gray-300 rounded-md w-6"></div>
                                <div className="h-6 bg-gray-300 rounded-md w-6"></div>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : !isQuerying && filteredProjects.length === 0 ? (
                        <TableRow>
                          <TableCell
                            className="cursor-auto"
                            style={{
                              padding: "4px 24px",
                              fontSize: "0.875rem",
                              fontFamily: "Inter",
                              height: "44px",
                              color: "#475467",
                            }}
                            colSpan={6}
                          >
                            <div className="flex items-center justify-center space-x-1">
                              <span className="text-gray-600">
                                No projects found.
                              </span>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : null}
                      {filteredProjects
                        .slice(
                          (page - 1) * tableRows,
                          (page - 1) * tableRows + tableRows
                        )
                        .map((project, index) => (
                          <TableRow
                            id={"table-row"}
                            key={project.id}
                            onClick={() => openProject(project.id)}
                            className="bg-white hover:bg-gray-50 active:bg-gray-100 border-b border-[#e0e0e0] cursor-pointer"
                          >
                            <TableCell
                              style={{
                                padding: "4px 24px",
                                fontSize: "0.875rem",
                                fontFamily: "Inter",
                                height: "44px",
                                color: "#475467",
                                borderBottom: "transparent",
                              }}
                            >
                              <div
                                style={{
                                  inlineSize: isWideScreen ? "" : "max-content",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Inter",
                                    fontWeight: "500",
                                    color: "#101828",
                                  }}
                                >
                                  {project.project_name}
                                </span>
                                <br />
                                {project.frequency_range}
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Inter",
                                height: "44px",
                                color: "#475467",
                                borderBottom: "transparent",
                              }}
                            >
                              <div
                                style={{
                                  inlineSize: isWideScreen ? "" : "max-content",
                                }}
                              >
                                {project.type}
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Inter",
                                height: "44px",
                                color: "#475467",
                                borderBottom: "transparent",
                              }}
                            >
                              <div
                                style={{
                                  inlineSize: isWideScreen ? "" : "max-content",
                                }}
                              >
                                {project.createdAt
                                  ? new Date(project.createdAt).toLocaleString(
                                      "en-US",
                                      {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                      }
                                    )
                                  : "-"}
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Inter",
                                height: "44px",
                                color: "#475467",
                                borderBottom: "transparent",
                              }}
                            >
                              <div
                                style={{
                                  inlineSize: isWideScreen ? "" : "max-content",
                                }}
                              >
                                {moment(project.lastModified).fromNow()}
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Inter",
                                height: "44px",
                                color: getStatusColor(
                                  project.status,
                                  project.percentage,
                                  false
                                ),
                                borderBottom: "transparent",
                              }}
                            >
                              <div className="flex items-center">
                                <div
                                  className={`flex items-center bg-${getStatusColor(
                                    project.status,
                                    project.percentage,
                                    true
                                  )}-100 py-0.5 pr-2 pl-1.5 mix-blend-multiply rounded-2xl`}
                                >
                                  <div
                                    className={`w-1.5 h-1.5 rounded-full bg-${getStatusColor(
                                      project.status,
                                      project.percentage,
                                      true
                                    )}-500`}
                                  ></div>
                                  <div className="text-xs font-medium ml-1.5">
                                    {project.status.includes("Idle") ||
                                    project.status.includes("Terminated") ||
                                    project.status.includes("Failed")
                                      ? project.status
                                          .replace("<b>", "")
                                          .replace("</b>", "")
                                          .replace("<code>", "")
                                          .replace("</code>", "")
                                      : project.percentage === 100
                                      ? "Completed"
                                      : "Running (" +
                                        Math.round(project.percentage) +
                                        "%)"}
                                  </div>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell style={{ borderBottom: "transparent" }}>
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <button
                                  className="w-8 h-10 flex items-center justify-center"
                                  onClick={(e) => {
                                    downloadPdf(project.id, "v1", userName);
                                    e.stopPropagation();
                                  }}
                                >
                                  <span
                                    data-tooltip-id="downloadPdf-tooltip"
                                    data-tooltip-content="Download PDF Report"
                                  >
                                    <svg
                                      width="20"
                                      height="18"
                                      viewBox="0 0 20 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6.66669 13.1667L10 16.5M10 16.5L13.3334 13.1667M10 16.5V9M16.6667 12.9524C17.6846 12.1117 18.3334 10.8399 18.3334 9.41667C18.3334 6.88536 16.2813 4.83333 13.75 4.83333C13.5679 4.83333 13.3976 4.73833 13.3051 4.58145C12.2184 2.73736 10.212 1.5 7.91669 1.5C4.46491 1.5 1.66669 4.29822 1.66669 7.75C1.66669 9.47175 2.36289 11.0309 3.48914 12.1613"
                                        stroke="#475467"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </span>
                                </button>
                                <Tooltip id="downloadPdf-tooltip" />
                                <button
                                  className="w-8 h-10 flex items-center justify-center"
                                  onClick={(e) => {
                                    duplicateProject(project.id, userName);
                                    e.stopPropagation();
                                  }}
                                >
                                  <span
                                    data-tooltip-id="duplicate-tooltip"
                                    data-tooltip-content="Duplicate Project"
                                  >
                                    <svg
                                      width="16"
                                      height="20"
                                      viewBox="0 0 18 22"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M11 1.26953V5.40007C11 5.96012 11 6.24015 11.109 6.45406C11.2049 6.64222 11.3578 6.7952 11.546 6.89108C11.7599 7.00007 12.0399 7.00007 12.6 7.00007H16.7305M9 17V11M6 14H12M11 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21H12.2C13.8802 21 14.7202 21 15.362 20.673C15.9265 20.3854 16.3854 19.9265 16.673 19.362C17 18.7202 17 17.8802 17 16.2V7L11 1Z"
                                        stroke="#475467"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </span>
                                </button>
                                <Tooltip id="duplicate-tooltip" />
                                <button
                                  className="w-8 h-10 flex items-center justify-center"
                                  onClick={(e) => {
                                    setProjectIdToDelete(project.id);
                                    setProjectNameToDelete(
                                      project.project_name
                                    );
                                    setDeleteProjectModalVisible(true);
                                    e.stopPropagation();
                                  }}
                                >
                                  <span
                                    data-tooltip-id="delete-tooltip"
                                    data-tooltip-content="Delete Project"
                                  >
                                    <svg
                                      width="18"
                                      height="20"
                                      viewBox="0 0 18 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12.3333 5V4.33333C12.3333 3.39991 12.3333 2.9332 12.1517 2.57668C11.9919 2.26308 11.7369 2.00811 11.4233 1.84832C11.0668 1.66667 10.6001 1.66667 9.66667 1.66667H8.33333C7.39991 1.66667 6.9332 1.66667 6.57668 1.84832C6.26308 2.00811 6.00811 2.26308 5.84832 2.57668C5.66667 2.9332 5.66667 3.39991 5.66667 4.33333V5M7.33333 9.58333V13.75M10.6667 9.58333V13.75M1.5 5H16.5M14.8333 5V14.3333C14.8333 15.7335 14.8333 16.4335 14.5608 16.9683C14.3212 17.4387 13.9387 17.8212 13.4683 18.0609C12.9335 18.3333 12.2335 18.3333 10.8333 18.3333H7.16667C5.76654 18.3333 5.06647 18.3333 4.53169 18.0609C4.06129 17.8212 3.67883 17.4387 3.43915 16.9683C3.16667 16.4335 3.16667 15.7335 3.16667 14.3333V5"
                                        stroke="#D92D20"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </span>
                                </button>
                                <Tooltip id="delete-tooltip" />
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      <TableRow className="cursor-default">
                        <TableCell
                          className="cursor-default"
                          style={{ borderBottom: "none" }}
                          colSpan={6}
                        >
                          <Pagination
                            ref={tablePaginationRef}
                            className="cursor-pointer"
                            count={Math.ceil(
                              filteredProjects.length / tableRows
                            )}
                            page={page}
                            onChange={(evt, page) => setPage(page)}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {/* TABLE CONTENT */}
              <NewProjectModal
                visible={newProjectModalVisible}
                setVisible={(value: boolean) =>
                  setNewProjectModalVisible(value)
                }
              />
              <DeleteProjectModal
                projectId={projectIdToDelete}
                projectName={projectNameToDelete}
                visible={deleteProjectModalVisible}
                setVisible={(value: boolean) =>
                  setDeleteProjectModalVisible(value)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default Projects;
