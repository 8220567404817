import Mexp from "math-expression-evaluator";
import { toast } from "react-toastify";
import store from "state";
import { setHistory } from "state/reducers/historySlice";

const mexp = new Mexp();

export const parseToken = (token: string) => {
  if (token !== "") {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const payload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map((c) => {
          const base = `00${c.charCodeAt(0).toString(16)}`;
          return `%${base.slice(-2)}`;
        })
        .join("")
    );
    return JSON.parse(payload);
  }
  return "";
};

export const isExpiredToken = (tokenData: any) => {
  if (Math.floor(Date.now() / 1000) >= tokenData.exp) {
    return true;
  }
  return false;
};

interface Parameter {
  id: string;
  name: string;
  expression: string;
  value: string;
  description: string;
}
export const isParameter = (val: string, parameters: Array<Parameter>) => {
  let flag: boolean = false;
  parameters.map((parameter) => {
    if (parameter.name === val) flag = true;
  });

  if (flag) return true;
  flag = true;
  for (let i = 0; i < val.length; i++) {
    if (
      !(
        val[i] === "+" ||
        val[i] === "-" ||
        val[i] === "*" ||
        val[i] === "/" ||
        val[i] === "(" ||
        val[i] === ")" ||
        val[i] === "^" ||
        val[i] === "%" ||
        val[i] === "." ||
        (val[i] >= "0" && val[i] <= "9")
      )
    ) {
      return false;
    }
  }
  return flag;
};

export const replaceParameterToValue = (
  val: string,
  param: string,
  value: string
) => {
  let str = val.toString().replace(/\s/g, "");

  str = str.replaceAll(param, value);
  return str;
};

export const replaceParametersToIds = (
  val: string,
  params: Array<Parameter>
) => {
  let str = val.toString().replace(/\s/g, "");

  const breakPoint = /\+|-|\*|\/|\(|\)|\^|\%/;
  let mathmaticalSymbols: Array<string> = [];
  for (let i = 0; i < str.length; i++) {
    if (
      str[i] === "+" ||
      str[i] === "-" ||
      str[i] === "*" ||
      str[i] === "/" ||
      str[i] === "(" ||
      str[i] === ")" ||
      str[i] === "^" ||
      str[i] === "%"
    )
      mathmaticalSymbols.push(str[i]);
  }
  const words = str.toString().split(breakPoint);
  let resString: string = "";
  words.map((word: string, index: number) => {
    let cnt = 0;
    params.map((param: Parameter) => {
      if (word === param.name) {
        resString += param.id.toString();
        return;
      }
      cnt++;
    });
    if (cnt === params.length) resString += word;
    if (mathmaticalSymbols[index]) resString += mathmaticalSymbols[index];
  });
  return resString;
};

export const replaceIdsToParameters = (
  val: string,
  params: Array<Parameter>
) => {
  try {
    let str = val.toString().replace(/\s/g, "");

    params.map((param: Parameter) => {
      str = str.replaceAll(param.id.toString(), param.name.toString());
    });
    return str;
  } catch (err) {
    console.log("erred", err);
    return "NaN";
  }
};

export const calculate = (val: string, parameters: Array<Parameter>) => {
  try {
    let str = val.toString().replace(/\s/g, "");
    parameters.map((param: Parameter) => {
      str = str.replaceAll(param.id.toString(), param.value.toString());
    });

    if (str === "") return NaN;
    let resVal = mexp.eval(str);
    return resVal;
  } catch (err) {
    const state = store.getState();

    if (
      state.histories.cacheHistories.length !== 0 &&
      state.histories.cacheHistories !== state.histories.histories
    ) {
      store.dispatch(setHistory(state.histories.cacheHistories));

      toast.error("Invalid expression, history reverted. ", {
        toastId: "invalid-expression",
        autoClose: 4000,
      });
    }

    return NaN;
  }
};

const isEdge = (pos1: any, pos2: any) => {
  let cnt = 0;
  if (pos1.x === pos2.x) cnt++;
  if (pos1.y === pos2.y) cnt++;
  if (pos1.z === pos2.z) cnt++;
  if (cnt === 2) return true;
  return false;
};

export const getVertices = (mesh: any) => {
  if (!mesh) {
    return;
  }
  var piv = mesh.getPivotPoint();
  var positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  if (!positions) {
    return;
  }
  var numberOfPoints = positions.length / 3;

  var level = false;
  var map = [];
  var poLoc = [];
  var poGlob = [];
  for (var i = 0; i < numberOfPoints; i++) {
    var p = new BABYLON.Vector3(
      positions[i * 3],
      positions[i * 3 + 1],
      positions[i * 3 + 2]
    );
    var found = false;
    for (var index = 0; index < map.length && !found; index++) {
      var array: any = map[index];
      var p0 = array[0];
      if (p0.equals(p) || p0.subtract(p).lengthSquared() < 0.00001) {
        found = true;
      }
    }
    if (!found) {
      var array: any = [];
      poLoc.push(p.subtract(piv));
      poGlob.push(
        BABYLON.Vector3.TransformCoordinates(p, mesh.getWorldMatrix())
      );
      array.push(p);
      map.push(array);
    }
  }
  let tPoGlob: any = [];
  let tPoLoc: any = [];
  for (let i = 0; i < poGlob.length; i++) {
    for (let j = i + 1; j < poGlob.length; j++) {
      if (isEdge(poGlob[i], poGlob[j])) {
        let p1 = new BABYLON.Vector3(
          (poGlob[i].x + poGlob[j].x) / 2,
          (poGlob[i].y + poGlob[j].y) / 2,
          (poGlob[i].z + poGlob[j].z) / 2
        );
        let p2 = new BABYLON.Vector3(
          (poLoc[i].x + poLoc[j].x) / 2,
          (poLoc[i].y + poLoc[j].y) / 2,
          (poLoc[i].z + poLoc[j].z) / 2
        );
        tPoGlob.push(p1);
        tPoLoc.push(p2);
      }
    }
  }
  for (let i = 0; i < tPoGlob.length; i++) {
    poGlob.push(tPoGlob[i]);
    poLoc.push(tPoLoc[i]);
  }
  return { local: poLoc, global: poGlob, pivot: piv };
};

export const round = (val: any) => {
  return Math.round(val * 1e5) / 1e5;
};

export const wait = (miliSecs: any) => {
  return new Promise((res, rej) => {
    setTimeout(() => {
      res(true);
    }, miliSecs);
  });
};

export const centralPos = (models: any, mainScene: any) => {
  let pos = new BABYLON.Vector3(0, 0, 0);
  models.map((model: any) => {
    const mesh = mainScene.getMeshById(model.id);
    if (!mesh) return;

    const boundingInfo = mesh.getBoundingInfo();
    const boundingBox = boundingInfo.boundingBox;
    let medX = 0;
    let medY = 0;
    let medZ = 0;

    boundingBox.vectorsWorld.forEach((vec: any) => {
      medX += vec._x;
      medY += vec._y;
      medZ += vec._z;
    });

    pos.x += medX / boundingBox.vectorsWorld.length;
    pos.y += medY / boundingBox.vectorsWorld.length;
    pos.z += medZ / boundingBox.vectorsWorld.length;
  });
  pos.x = round(pos.x / models.length);
  pos.y = round(pos.y / models.length);
  pos.z = round(pos.z / models.length);
  return pos;
};
