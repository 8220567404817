import * as BABYLON from "babylonjs";

export function createBabylonScene(
  canvas: HTMLCanvasElement,
  engine: BABYLON.Engine
): BABYLON.Scene {
  let ctrlHeld = false; // To check if CTRL is held down

  canvas.addEventListener("wheel", (e) => {
    e.preventDefault();
  });
  const scene = new BABYLON.Scene(engine);
  scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);
  scene.autoClear = false;
  scene.autoClearDepthAndStencil = false;
  scene.blockMaterialDirtyMechanism = true;
  scene.useRightHandedSystem = true;
  //scene.debugLayer.show();

  const gizmoManager = new BABYLON.GizmoManager(scene);
  //gizmoManager.positionGizmoEnabled = true;
  gizmoManager.usePointerToAttachGizmos = false;

  const camera = new BABYLON.ArcRotateCamera(
    "camera",
    Math.PI / 3.5,
    Math.PI / 2.7,
    160,
    BABYLON.Vector3.Zero(),
    scene
  );

  camera.zoomToMouseLocation = true;
  camera.wheelDeltaPercentage = 0.05;
  camera.inertia = 0;
  camera.panningInertia = 0;
  camera.angularSensibilityX = 250;
  camera.angularSensibilityY = 250;
  camera.allowUpsideDown = true;
  camera.lowerBetaLimit = null; //0.01;
  camera.upperBetaLimit = null; //2 * Math.PI - 0.01;
  if (camera.mode === BABYLON.Camera.PERSPECTIVE_CAMERA) {
    camera.minZ = camera.radius ** 1.5 / 10000;
    camera.maxZ = camera.radius ** 5;
  } else {
    camera.minZ = 0.4;
    camera.maxZ = 10000;
  }
  camera.setTarget(BABYLON.Vector3.Zero());
  camera.attachControl(canvas, true);

  let forward = camera.getDirection(BABYLON.Axis.Z);
  let position = camera.position.clone();
  let currentUpVector = camera.upVector.clone();
  let controlsActive = true;

  scene.onKeyboardObservable.add((kbInfo) => {
    if (kbInfo.type === BABYLON.KeyboardEventTypes.KEYDOWN) {
      if (kbInfo.event.shiftKey) {
        camera._panningMouseButton = 0;
        camera.panningSensibility = 10;
        camera.updateUpVectorFromRotation = false;
        camera.noRotationConstraint = false;
      }
      if (kbInfo.event.ctrlKey) {
        ctrlHeld = true;
        camera.panningSensibility = 0;
        camera.updateUpVectorFromRotation = true;
        camera.noRotationConstraint = true;
      }

      if (
        kbInfo.event.key === "1" ||
        kbInfo.event.key === "2" ||
        kbInfo.event.key === "3" ||
        kbInfo.event.key === "4" ||
        kbInfo.event.key === "5" ||
        kbInfo.event.key === "6" ||
        kbInfo.event.key === "8"
      ) {
        // if (kbInfo.event.key !== "1") {
        camera.upVector = new BABYLON.Vector3(0, 1, 0);
        // }

        if (kbInfo.event.key === "1") {
          // // Get the absolute values of the vector components
          // const absX = Math.abs(camera.upVector._x);
          // const absY = Math.abs(camera.upVector._y);
          // const absZ = Math.abs(camera.upVector._z);

          // // Find the maximum value among the absolute values
          // const maxVal = Math.max(absX, absY, absZ);

          // // Reset all components to 0
          // camera.upVector._x = 0;
          // camera.upVector._y = 0;
          // camera.upVector._z = 0;

          // // Set the component with the maximum absolute value to 1 or -1 based on its original sign
          // if (maxVal === absX) {
          //   camera.upVector._x = Math.sign(camera.upVector._x);
          // } else if (maxVal === absY) {
          //   camera.upVector._y = Math.sign(camera.upVector._y);
          // } else if (maxVal === absZ) {
          //   camera.upVector._z = Math.sign(camera.upVector._z);
          // }
          camera.alpha =
            Math.round(camera.alpha / (Math.PI / 2)) * (Math.PI / 2);
          camera.beta = Math.round(camera.beta / (Math.PI / 2)) * (Math.PI / 2);
        } else if (kbInfo.event.key === "2") {
          // Bottom view
          camera.alpha = 0;
          camera.beta = Math.PI;
        } else if (kbInfo.event.key === "3") {
          // Back view
          camera.alpha = -Math.PI / 2;
          camera.beta = Math.PI / 2;
        } else if (kbInfo.event.key === "4") {
          // Left view
          camera.alpha = -Math.PI;
          camera.beta = Math.PI / 2;
        } else if (kbInfo.event.key === "5") {
          // Front view
          camera.alpha = Math.PI / 2;
          camera.beta = Math.PI / 2;
        } else if (kbInfo.event.key === "6") {
          // Right view
          camera.alpha = 0;
          camera.beta = Math.PI / 2;
        } else if (kbInfo.event.key === "8") {
          // Top view
          camera.alpha = 0;
          camera.beta = 0.0000001;
        }
      }
    } else if (kbInfo.type === BABYLON.KeyboardEventTypes.KEYUP) {
      if (!kbInfo.event.ctrlKey) {
        ctrlHeld = false;
      }
      if (!kbInfo.event.shiftKey) {
        camera._panningMouseButton = 2;
      }
      camera.panningSensibility = 10;
    }
  });

  scene.onPointerObservable.add((eventData) => {
    if (controlsActive && eventData.event.ctrlKey) {
      controlsActive = false;
      camera.detachControl();
      forward = camera.getDirection(BABYLON.Axis.Z);
      position = camera.position.clone();
    } else if (!controlsActive && !eventData.event.ctrlKey) {
      controlsActive = true;
      camera.attachControl(canvas, true);
    }
  });

  scene.onPointerMove = (evt) => {
    if (evt.buttons > 0 && evt.ctrlKey) {
      const rotateAmount = evt.movementX * 0.01;
      camera.upVector.rotateByQuaternionToRef(
        BABYLON.Quaternion.RotationAxis(forward, rotateAmount),
        currentUpVector
      );

      // These shouldn't need to be cloned but copyFrom isn't working for them.
      camera.upVector = currentUpVector.clone();
      camera.position = position.clone();
    }
  };

  setCamera(camera);

  // Key Light
  const keyLight = new BABYLON.DirectionalLight(
    "keyLight",
    new BABYLON.Vector3(0, -1, 1),
    scene
  );
  keyLight.intensity = 0.7;
  keyLight.shadowEnabled = false;

  // Fill Light
  const fillLight = new BABYLON.HemisphericLight(
    "fillLight",
    new BABYLON.Vector3(0, 1, 0),
    scene
  );
  fillLight.intensity = 0.5;
  fillLight.diffuse = BABYLON.Color3.White();
  fillLight.groundColor = new BABYLON.Color3(0.75, 0.75, 0.75);

  // Underneath Fill Light
  const fillLightUnderneath = new BABYLON.HemisphericLight(
    "fillLightUnderneath",
    new BABYLON.Vector3(0, -1, 0),
    scene
  );
  fillLightUnderneath.intensity = 0.5;
  fillLightUnderneath.diffuse = BABYLON.Color3.White();
  fillLightUnderneath.specular = BABYLON.Color3.White();

  // Back Light
  const backLight = new BABYLON.PointLight(
    "backLight",
    new BABYLON.Vector3(-100, 100, -100),
    scene
  );
  backLight.intensity = 0.5;

  // Set the position of the key light
  function setLightPositionByAngle(
    light: BABYLON.DirectionalLight,
    angle: any,
    distance: any,
    height: any
  ) {
    const x = Math.cos((angle * Math.PI) / 180) * distance;
    const z = Math.sin((angle * Math.PI) / 180) * distance;
    light.position = new BABYLON.Vector3(x, height, z);
    light.setDirectionToTarget(BABYLON.Vector3.Zero());
  }

  setLightPositionByAngle(keyLight, 120, 50, 100);

  return scene;
}

function setCamera(camera: BABYLON.ArcRotateCamera) {
  if (camera.framingBehavior) {
    camera.framingBehavior.framingTime = 1000;
    camera.framingBehavior.zoomStopsAnimation = true;
    camera.framingBehavior.radiusScale = 1.5;
    camera.framingBehavior.positionScale = 0.5;
    camera.framingBehavior.defaultElevation = 0.3;
    camera.framingBehavior.elevationReturnTime = 1500;
    camera.framingBehavior.elevationReturnWaitTime = 1000;
    camera.framingBehavior.framingTime = 1000;
  }
  camera.lowerRadiusLimit = 0;
  camera.upperRadiusLimit = 2000;
  camera.wheelPrecision = 10;
  camera.panningSensibility = 10;
  camera.pinchPrecision = 10;
}

export function frameCamera(
  radius: any = 1.5,
  mesh: BABYLON.Mesh,
  scene: BABYLON.Scene
) {
  if (!scene.activeCamera) return;
}
