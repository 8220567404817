import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MyIcon from "assets/MyIcons";
import classNames from "classnames";
import DraggableModal from "components/DraggableModal";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "react-tooltip";
import { useAppSelector } from "state/hooks";
import { moveToHistoryStep } from "state/reducers/historySlice";
import { HistoryEditMenu } from "./HistoryEditMenu";

export interface HistoryProps {
  setVisible: (value: boolean) => void;
  visible: boolean;
  isEditableModal?: boolean;
  modelToBeAlter?: any;
}

export const HistoryMenu: React.FC<HistoryProps> = ({
  visible,
  setVisible,
  isEditableModal,
  modelToBeAlter,
}) => {
  const dispatch = useDispatch();
  const history = useAppSelector((state) => state.histories.histories);
  const currentHistoryStep = useAppSelector(
    (state) => state.histories.currentHistoryStep
  );
  const [showEditModal, setShowEditModal] = useState(false);
  const [stepToEdit, setStepToEdit] = useState(0);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape" && !showEditModal) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentHistoryStep, visible, showEditModal]);

  return (
    <>
      <HistoryEditMenu
        stepToEdit={stepToEdit}
        visible={showEditModal}
        setVisible={setShowEditModal}
      />
      <DraggableModal
        // className={visible ? "pointer-events-none" : "pointer-events-auto"}
        title={
          <div className="bg-green-300 w-full text-xl font-semibold rounded-t-md py-2 text-center border-b-2 border-primary-600">
            History List
          </div>
        }
        visible={visible}
        buttonsClassName="sm:px-4"
        buttons={
          <>
            <button
              type="button"
              className="disable-drag relative h-9 items-center ml-2 my-auto mt-3 inline-flex w-[72px] justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 enabled:hover:bg-gray-50 enabled:active:bg-gray-100 sm:mt-0 disabled:opacity-50"
              onClick={() => {
                setVisible(false);
              }}
            >
              Close
            </button>
          </>
        }
      >
        <TableContainer
          style={{
            maxHeight: "300px",
            overflowY: "auto",
          }}
        >
          <Table stickyHeader className="disable-drag">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    maxWidth: "20px",
                  }}
                >
                  <span className="text-gray-900 font-semibold">#</span>
                </TableCell>
                <TableCell>
                  <span className="text-gray-900 font-semibold">Event</span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    setShowEditModal(true);
                    setStepToEdit(-1);
                  }}
                >
                  <span
                    data-tooltip-id="global-edit-tooltip"
                    data-tooltip-content="Edit full history list"
                  >
                    <MyIcon name="global-edit" />
                  </span>
                  <Tooltip id="global-edit-tooltip" className="z-50" />
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {history.map((entry, index) => {
                const event = Object.keys(entry)[0];

                return (
                  <TableRow
                    className={classNames(
                      "!h-4 hover:bg-gray-200 active:bg-gray-300",
                      {
                        "bg-greenLight-200 hover:bg-greenLight-200 active:bg-greenLight-300":
                          index + 1 === currentHistoryStep,
                      }
                    )}
                    onClick={() => {
                      dispatch(moveToHistoryStep(index + 1));
                    }}
                  >
                    <TableCell>
                      <span className="text-gray-500">{index + 1}</span>
                    </TableCell>
                    <TableCell>{event}</TableCell>
                    <TableCell
                      onClick={(e) => {
                        e.stopPropagation();
                        setStepToEdit(index);
                        setShowEditModal(true);
                      }}
                    >
                      <span
                        data-tooltip-id="local-edit-tooltip"
                        data-tooltip-content="Edit history step"
                      >
                        <MyIcon name="edit" />
                      </span>
                      <Tooltip id="local-edit-tooltip" />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DraggableModal>
    </>
  );
};
